import React from 'react';
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

class UseServiceWorker extends React.Component {

    state = {
        waitingWorker: null,
        showReload: false,
    }

    componentDidUpdate(){
        console.log("UseServiceWorker DidUpdate")
        serviceWorkerRegistration.register({
            onUpdate: this.onSWUpdate,
          });
    }

    componentDidMount(){
        serviceWorkerRegistration.register({
            onUpdate: this.onSWUpdate,
          });
    }

    getShowReload = () => {
        return this.state.showReload
    }

    getWaitingWorker = () => {
        return this.state.waitingWorker
    }

    onSWUpdate = (registration) => {
        console.log("onSWUpdate")
        this.setState({showReload: true})
        this.setState({waitingWorker: registration.waiting})
        if(registration.waiting){
            this.props.callRefresh()
        }
    }

    reloadPage = () => {
        console.log("reloadPage")
        this.state.waitingWorker?.postMessage({ type: "SKIP_WAITING" });
        this.setState({showReload: false})
        window.location.reload();
    }

    render (){
        return (
            <></>
        )
    }


}

export default UseServiceWorker;

// export const useServiceWorker = () => {

//   const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
//   const [showReload, setShowReload] = useState < boolean > false;

  // called when a service worker
  // updates. this function is a callback
  // to the actual service worker
  // registration onUpdate.
//   const onSWUpdate = useCallback((registration) => {
//       // setShowReload(true);
//     this.setState({showReload: true})
//     // setWaitingWorker(registration.waiting);
//     this.setState({waitingWorker: registration.waiting})
//   }, []);

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
//   const reloadPage = useCallback(() => {
//     waitingWorker?.postMessage({ type: "SKIP_WAITING" });
//     setShowReload(false);
//     window.location.reload();
//   }, [waitingWorker]);

  // register the service worker
//   useEffect(() => {
//     // If you want your app to work offline and load faster, you can change
//     // unregister() to register() below. Note this comes with some pitfalls.
//     // Learn more about service workers: https://cra.link/PWA
//     serviceWorkerRegistration.register({
//       onUpdate: onSWUpdate,
//     });
//   }, [onSWUpdate]);

//   return { showReload, waitingWorker, reloadPage };

// };


