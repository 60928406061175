import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil'
import { Button } from 'primereact/button'
import ConfirmationDialog from '../../components/confirmationDialog'
import FichaDeEstoqueService from '../../app/service/relatorios/fichaDeEstoqueService'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../../components/toastr'
import UpdateStockDialog from '../../components/product/updateStockDialog'
import { classNames } from 'primereact/utils'

class FichaDeEstoqueTable extends React.Component {

    state = {
        visibleConfirmDeleteDialog: false,
        fichaDeEstoqueToDelete: '',

        visibleEditDialog: false,
        fichaDeEstoqueToEdit: '',

        loading: false,
    }

    constructor(){
        super();
        this.fichaDeEstoqueService = new FichaDeEstoqueService();
    }

    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        var label = "Produto: "
        var descricao = this.props.fichaDeEstoqueOutputListDTO && this.props.fichaDeEstoqueOutputListDTO.product && this.props.fichaDeEstoqueOutputListDTO.product.descricao ? this.props.fichaDeEstoqueOutputListDTO.product.descricao : ""

        const productLabel =  label + descricao
        
        const periodo = this.props.fichaDeEstoqueOutputListDTO && this.props.fichaDeEstoqueOutputListDTO.initialDate ? this.props.fichaDeEstoqueOutputListDTO.initialDate + " - " + this.props.fichaDeEstoqueOutputListDTO.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {product: productLabel, periodo: periodoLabel}
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.ajusteFichaDeEstoqueSobraClass] : rowData.tipoAtualizacao.includes("AJUSTE_SOBRA"),
            [ConstantsUtil.ajusteFichaDeEstoquePerdaClass] : rowData.tipoAtualizacao.includes("AJUSTE_PERDA")
        }
        return obj
    }

    callEdit = (rowData) => {
        // console.log("rowData: ", rowData)
        this.setState({visibleEditDialog: true})
        this.setState({fichaDeEstoqueToEdit: rowData})
    }

    confirmEdit = (stockInfo) => {

        this.setState({loading: true})
        
        const fichaDeEstoqueToEdit = JSON.parse(JSON.stringify(this.state.fichaDeEstoqueToEdit))

        this.hideEditDialog()

        this.fichaDeEstoqueService.update(
            {
                originalFichaDeEstoqueToBeEditedId: fichaDeEstoqueToEdit.id,
                newStockInfo: stockInfo
            }
        )
        .then(response => {
            popUp.successPopUp("Lançamento Manual alterado com sucesso!")
            this.setState({fichaDeEstoqueToEdit: ''})
            this.props.search(true)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    hideEditDialog = () => {
        this.setState({visibleEditDialog: false})
        this.setState({fichaDeEstoqueToEdit: ''})
    }

    callDelete = (rowData) => {
        this.setState({visibleConfirmDeleteDialog: true})
        this.setState({fichaDeEstoqueToDelete: rowData})
    }

    hideConfirmDeleteDialog = () => {
        this.setState({visibleConfirmDeleteDialog: false})
        this.setState({fichaDeEstoqueToDelete: ''})
    }

    confirmDelete = () => {
        this.hideConfirmDeleteDialog()
        this.setState({loading: true})
        this.fichaDeEstoqueService.delete(this.state.fichaDeEstoqueToDelete)
        .then(response => {
            popUp.successPopUp("Lançamento Manual deletado com sucesso!")
            this.props.search(true)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    render(){

        const renderProductHeader = () => {
            return (
            <div className='col-md-6'>
                <h5 className='medium-font-size'>{this.getTableHeaders().product}</h5>            
            </div>
            )
        }

        let headerGroup =
        <ColumnGroup>
            <Row>
                <Column header="Data" rowSpan={2} field="data"/>
                <Column header="____________Entrada____________" colSpan={2}/>
                <Column header="_____________Saída_____________" colSpan={2} />
                <Column header="_______________________Saldo_______________________" colSpan={3} />
                <Column header="Tipo de Atualização" rowSpan={2} />
                <Column header="Ações" rowSpan={2} />
            </Row>
            <Row>
                <Column header="Qtd" field="quantidadeEntrada" />
                <Column header="Vr Unit" field="valUnitEntrada"/>
                <Column header="Qtd" field="quantidadeSaida" />
                <Column header="Vr Unit" field="valUnitSaida"/>
                <Column header="Qtd" field="quantidadeSaldo" />
                <Column header="Vr Total" field="valorTotalSaldo"/>
                <Column header="Dif Vr Total" field="diferencaValorTotalSaldoAjuste"/>
            </Row>
        </ColumnGroup>

        
        const renderStyledTipo = (tipo) => {

            const classes = classNames({
                // 'superavitValue': tipo >= 0,
                'deficitValue': tipo === ConstantsUtil.tipoFichaDeEstoqueDevolucaoLabel
            });

            return (
                <div className={classes}>
                    {tipo}
                </div>
            );
        }


        const actionBody = (rowData) => {
            if(rowData.lastLancamentoMaual){
                return (
                    <React.Fragment>
                        <Button
                            tooltip="Editar lançamento manual"
                            tooltipOptions={{position: 'top'}}
                            className={ "p-button-rounded p-mr-2 " }
                            style={ {maxHeight: '25px', maxWidth: '25px'} }
                            icon={<i className="pi pi-pencil" style={{fontSize: '0.9em'}}></i>}
                            onClick={() => this.callEdit(rowData)}
                        />
                        <Button
                            tooltip="Deletar lançamento manual"
                            tooltipOptions={{position: 'top'}}
                            className={ "p-button-rounded p-mr-2 p-button-danger" }
                            style={ {maxHeight: '25px', maxWidth: '25px', marginLeft: '5px'} }
                            icon={<i className="pi pi-trash" style={{fontSize: '0.9em'}}></i>}
                            onClick={() => this.callDelete(rowData)}
                        />
                    </React.Fragment>
                );
            }
        }        

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>

                    {renderProductHeader()}

                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>

                    {/* {renderExport()} */}

                </div>
                
            </div>
            </div>
            <DataTable 
                ref={this.dt}
                // value={GeneralServices.calculateBalance(product.stockSheet)}
                value={this.props.fichaDeEstoqueOutputListDTO ? this.props.fichaDeEstoqueOutputListDTO.fichaDeEstoqueList : []}
                headerColumnGroup={headerGroup}
                className="p-datatable-sm small-font"
                rowClassName={this.rowClass}
                rowHover
                showGridlines
                scrollable
                scrollHeight="500px"
                emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.fichaDeEstoque ? this.props.fichaDeEstoque.length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

            <Column field="data"
                body={rowData => GeneralServices.formatDateFromFichaDeEstoque(rowData.data)}
                sortable style ={ {width: '140px'} }
            // filter filterElement={codeFilterElement}
             />

            <Column field="quantidadeEntrada" 
                    body={rowData => GeneralServices.showFormattedIfNonZero(rowData.quantidadeEntrada, false)}
                    sortable style ={ {width: '80px'} }
            />

            <Column field="valUnitEntrada" 
                    body={rowData => GeneralServices.showFormattedIfNonZero(rowData.valorUnitarioEntrada, true)} 
                    sortable style ={ {width: '100px'} }
            />

            <Column field="quantidadeSaida" 
                    body={rowData => GeneralServices.showFormattedIfNonZero(rowData.quantidadeSaida, false)}
                    sortable style ={ {width: '80px'} }
            />

            <Column field="valUnitSaida" 
                    body={rowData => GeneralServices.showFormattedIfNonZero(rowData.valorUnitarioSaida, true)}
                    sortable style ={ {width: '100px'} }
            />

            <Column field="quantidadeSaldo" 
                    body={rowData => GeneralServices.showFormattedIfNotNull(rowData.quantidadeSaldo, false)}
                    sortable style ={ {width: '80px'} }
            />

            <Column field="valorTotalSaldo"
                    body={rowData => GeneralServices.showFormattedIfNotNull(rowData.valorTotalSaldo, true)}
                    sortable style ={ {width: '100px'} }
            />

            <Column field="diferencaValorTotalSaldoAjuste"
                    body={rowData => GeneralServices.showFormattedIfNotNull(rowData.diferencaValorTotalSaldoAjuste, true)}
                    sortable style ={ {width: '100px'} }
            />

            <Column field="tipoAtualizacao" header="Tipo de Atualização" sortable style ={ {width: '450px'} } 
            // filter filterElement={cfopFilterElement}
            body = {rowData => renderStyledTipo(rowData.tipoAtualizacao)}
            />

            <Column body={actionBody} style ={ {width: '80px'} }
            />

        </DataTable>

        <ConfirmationDialog
            header={"Deletar Lançamento Manual"}
            confimationMessage={ "Confirmar exclusão?"}
            visible={this.state.visibleConfirmDeleteDialog}
            confirm={this.confirmDelete}
            hide={this.hideConfirmDeleteDialog}
        />

        <UpdateStockDialog
            header="Atualizar Estoque"
            visible={this.state.visibleEditDialog}
            productId={this.state.fichaDeEstoqueToEdit ? this.state.fichaDeEstoqueToEdit.product.id : ''}
            fichaDeEstoqueToEdit={this.state.fichaDeEstoqueToEdit}
            save={this.confirmEdit}
            hideDialog={this.hideEditDialog}
        />      

        </>
        )
    }

}


export default FichaDeEstoqueTable