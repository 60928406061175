import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import { Button } from 'primereact/button'

import { AuthContext } from '../../main/authProvider'
import TransactionService from '../../app/service/estoque/transactionService'
import FormGroup from '../../components/form-group'
import SelectMenu from '../../components/selectMenu'
import * as popUp from '../../components/toastr'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import TransactionByProductTable from './transactionByProductTable'
import TransactionByNFTable from './transactionByNFTable'
import ProgressService from '../../app/service/progressService'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import NFService from '../../app/service/estoque/NFService'
import DialogFooter from '../../components/dialogFooter'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'

class ConferenciaNF extends React.Component {

    constructor(){
        super();
        this.transactionService = new TransactionService();
        this.generalServices = new GeneralServices();
        this.progressService = new ProgressService();
        this.NFService = new NFService();
    }

    state = {

        selectedNFFilterType: '',
        inputNFFilterTypeErrorClass: '',

        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',

        selectedFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,

        productLabel: 'Por produto',
        NFLabel: 'Por nota',
        currentLabel: '',
        transactionList: [],
        tipo: '',
        situacao: '',
        errorTypeMessage: null,
        inputTypeErrorClass: null,
        errorSituationMessage: null,
        inputSituationErrorClass: null,
        
        loadingNFTable: false,
        loadingProductTable: false,

        totalValue: GeneralServices.valueBodyTemplate(0),
        quantidadeItems: 0,

        habilitaBusca: true,
        isFinishedNFSearch: false,
        isFinishedProductSearch: false,

        infoData: {},
        NFData: {},
        productTransactionData: {},

        pesquisaNumeroDialogVisible: false,
        numeroNFToSearch: null,

        isSearchByNumero: false,

    }

    componentDidMount(){
        this.setState({currentLabel: this.state.NFLabel})
        this.setState({situacao: this.transactionService.getSituationList()[1].value})
        this.setState({tipo: this.transactionService.getTypeList()[1].value})
    }

    changeLabel = (label) => {
        this.setState({currentLabel: label})

        switch(label){
            case this.state.NFLabel:
                this.setInfo(this.state.NFData)
                break
            case this.state.productLabel:
                this.setInfo(this.state.productTransactionData)
                break
        }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
    }

    handleNFFilterTypeChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        if(value === ConstantsUtil.filtroDataEntradaLabel){
            popUp.infoPopUp("Ao filtrar por Data de Entrada, as NFs não validadas não serão listadas.")
        }

        if(value){
            var select = document.getElementById(ConstantsUtil.componenteEscolherPeriodoId)
            select.focus();
        }

    }

    handleDateFilterChange = (selectedFilterType) => {
        this.setState({selectedFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }    

    resetView = () => {

        this.setState({inputNFFilterTypeErrorClass: ''})

        if(this.state.tipo) {
            this.setState({inputTypeErrorClass: ""})
            this.setState({errorTypeMessage:""})
        }
        if(this.state.situacao){
            this.setState({inputSituationErrorClass: ""})
            this.setState({errorSituationMessage:""})
        }

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

    }

    checkData = () => {
        var check = true

        if(!this.state.selectedNFFilterType){
            this.setState({inputNFFilterTypeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }
        
        if(!this.state.tipo){
            this.setState({inputTypeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorTypeMessage:"Selecione o tipo da Nota Fiscal"})
            check = false
        }
        if(!this.state.situacao){
            this.setState({inputSituationErrorClass:  ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorSituationMessage:"Selecione a situação"})
            check = false
        }

        if(!this.state.selectedFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }        

        return check
    }

    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            this.search()
        }
    }

    search = () => {

        if(this.state.isSearchByNumero){
            /*
            Após o fluxo de deletar ou reativar NF pela tabela, é chamado este método.
            Caso isSearchByNumero seja verdadeiro, refaz então a busca pelo número.
            Correção de "Corrigir exclusão/reativação de NF (https://trello.com/c/CuHG1vNK/531-corrigir-exlcus%C3%A3o-reativa%C3%A7%C3%A3o-de-nf)"
            */
            this.callSearchPesquisaNumero()
            return
        }

        this.setState({habilitaBusca: false})
        this.setState({currentLabel: this.state.NFLabel})
        this.searchByNF()
        // this.searchByProduct()
    }

    searchByNF = () => {
        this.setState({loadingNFTable: true})
        this.setState({isFinishedNFSearch: false})
        this.setInfo({})
        this.setState({NFData: {}})
        this.NFService.commomSearch(
            {
                filtroTipoData: this.state.selectedNFFilterType,
                dataInicial: this.state.initialDate,
                dataFinal: this.state.finalDate,
                tipoNF: this.state.tipo,
            }
        )
        .then(response => {
            this.trataDadosNF(response.data)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingNFTable: false})
            this.setState({isFinishedNFSearch: true})
        })        
    }

    searchByProduct = () => {
        this.setState({loadingProductTable: true})
        this.setState({isFinishedProductSearch: false})
        this.setInfo({})
        this.setState({productTransactionData: {}})
        this.transactionService.search(
            {
                filtroTipoData: this.state.selectedNFFilterType,
                dataInicial: this.state.initialDate,
                dataFinal: this.state.finalDate,
                tipoNF: this.state.tipo,
                situacaoNF: this.state.situacao,
            }
        )
        .then(response => {
            // console.log("response: ", response.data)
            this.trataDadosProdutos(response.data)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingProductTable: false})
            this.setState({isFinishedProductSearch: true})
        })        
    }

    trataDadosProdutos(dados){
        let valorTotalByProdutos = 0
        GeneralServices.dealProductData(dados.productTransactionList)
        dados.productTransactionList.forEach(item => {
            valorTotalByProdutos += item.transaction.valor
            this.trataSituacaoNF(item.nf)
        })
        this.setState({productTransactionData: JSON.parse(JSON.stringify(dados))})
        
        //PARA DEBUG
        // let transactionsIdsByProducts = []
        // dados.productTransactionList.forEach(item => {
        //     transactionsIdsByProducts.push(item.transaction.id)
        // })
        // transactionsIdsByProducts = transactionsIdsByProducts.sort( (a, b) => parseInt(a) - parseInt(b) )
        // console.log("transactionsIdsByProducts: ", transactionsIdsByProducts)
        // console.log("trataDadosProdutos valor total: ", valorTotalByProdutos)
    }

    toggleButton = () => {
        if(!this.state.habilitaBusca && this.state.isSearchByNumero){
            this.setState({isSearchByNumero: false})
            this.setState({numeroNFToSearch: ''})
        }
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    trataSituacaoNF = (notaFiscal) => {
        switch(notaFiscal.situacao){
            case ConstantsUtil.situacaoRegular:
                notaFiscal.situacaoLabel = notaFiscal.pendente ? ConstantsUtil.situacaoRegularAguardandoValidacaoLabel : ConstantsUtil.situacaoRegularValidadaLabel
                break;
            case ConstantsUtil.situacaoCancelada:
                notaFiscal.situacaoLabel = ConstantsUtil.situacaoCanceladaLabel
                break;
            case ConstantsUtil.situacaoExcluida:
                notaFiscal.situacaoLabel = ConstantsUtil.situacaoExcluidaLabel
                break;
            default:
                notaFiscal.situacaoLabel = notaFiscal.situacao
                break;
        }        
    }

    trataDadosNF(dados){
        // console.log("trataDadosNF: " , dados)
        dados.notasFiciaisList.forEach(notaFiscal => {
            this.trataSituacaoNF(notaFiscal)
            // switch(notaFiscal.situacao){
            //     case ConstantsUtil.situacaoRegular:
            //         notaFiscal.situacaoLabel = notaFiscal.pendente ? ConstantsUtil.situacaoRegularAguardandoValidacaoLabel : ConstantsUtil.situacaoRegularValidadaLabel
            //         break;
            //     case ConstantsUtil.situacaoCancelada:
            //         notaFiscal.situacaoLabel = ConstantsUtil.situacaoCanceladaLabel
            //         break;
            //     case ConstantsUtil.situacaoExcluida:
            //         notaFiscal.situacaoLabel = ConstantsUtil.situacaoExcluidaLabel
            //         break;
            //     default:
            //         notaFiscal.situacaoLabel = notaFiscal.situacao
            //         break;
            // }

        })

        GeneralServices.dealNFListDates(dados.notasFiciaisList)
        this.setInfo(dados)
        this.setState({NFData: JSON.parse(JSON.stringify(dados))})
        

        //PARA DEBUG
        // let transactionsIdsByNF = []
        // let totalValorTotalByNF = 0
        // dados.notasFiciaisList.forEach(notaFiscal => {
        //     let valorTotalByNF = 0
        //     notaFiscal.transactions.forEach(transaction => {
        //         transactionsIdsByNF.push(transaction.id)
        //         // console.log("transaction: ", transaction)
        //         valorTotalByNF += transaction.valor
        //     })
        //     totalValorTotalByNF += notaFiscal.valorTotal
        //     let diff = valorTotalByNF - notaFiscal.valorTotal
        //     // console.log("diff: ", diff)
        //     if( Math.abs(diff) > 1 ){
        //         console.log("NF com Valor Total Divergente: ", notaFiscal.id, notaFiscal.valorTotal, valorTotalByNF, diff)
        //     }
        // })
        // transactionsIdsByNF = transactionsIdsByNF.sort( (a, b) => parseInt(a) - parseInt(b) )
        // console.log("transactionsIdsByNF: ", transactionsIdsByNF)
        // console.log("valor total by NF: ", totalValorTotalByNF)
    }

    setInfo = (info) => {
        this.setState({totalValue: GeneralServices.valueBodyTemplate(info.valorTotal)})
        this.setState({quantidadeItems: info && info.quantidade ? info.quantidade : 0})
    }

    getQuantidadeLabel = () => {
        switch(this.state.currentLabel){
            case this.state.NFLabel:
                return 'Quantidade de Notas:'
            case this.state.productLabel:
                return 'Quantidade de Lançamentos:'
        }        
    }

    searchByNumero = () => {
        this.setState({pesquisaNumeroDialogVisible: true})
    }

    handleNumeroChange = async (event) => {
        let value = event.target.value
        const name = event.target.name

        if(value && value < 0){
            value*=-1
        }

        this.setState({[name]: value})
    }

    handlePesquisaNumeroKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.callSearchPesquisaNumero()
          }
    }    

    hidePesquisaNumeroDialog = () => {
        this.setState({pesquisaNumeroDialogVisible: false})
        this.setState({inputSearchNumeroErrorClass: ''})
        // this.setState({numeroNFToSearch: ''})
    }

    checkPesquisaNumeroData = () => {
        let check = true
        if(!this.state.numeroNFToSearch){
            check = false
            this.setState({inputSearchNumeroErrorClass: ConstantsUtil.bootstrapInputErrorClass})
        }

        return check
    }

    callSearchPesquisaNumero = () => {
        this.setState({inputSearchNumeroErrorClass: ''})
        if(this.checkPesquisaNumeroData()){
            this.searchPesquisaByNumero()
        }
    }

    searchPesquisaByNumero(){
        this.setState({habilitaBusca: false})
        this.setState({loadingNFTable: true})
        this.setState({isFinishedNFSearch: false})
        this.setState({isSearchByNumero: true})
        this.NFService.searchByNumero(this.state.numeroNFToSearch)
        .then(response => {
            this.trataDadosNF(response.data)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingNFTable: false})
            this.setState({isFinishedNFSearch: true})
            this.hidePesquisaNumeroDialog()
        })
    }

    render() {

        const typeList = this.transactionService.getTypeList()
        const situationList = this.transactionService.getSituationList()

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                <div className = "col-md-12">
                    <Button 
                        label="Buscar"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                    />
                </div>
                )
            }
            return (
                <>
                <div className = "col-md-12">
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                    />
                </div>
                </>
            )            
        }

        const renderViewType = () => {
            if(!this.state.habilitaBusca){
                return (
                    <div
                        className="p-col-12 p-md-3 "
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}
                    >
                    
                    <Button
                        label={this.state.NFLabel}
                        className={this.state.currentLabel === this.state.NFLabel ? " " : "p-button-text"}
                        onClick={ () => this.changeLabel(this.state.NFLabel)}
                        disabled={!this.state.isFinishedNFSearch}
                    />
    
                    <Button
                        label={this.state.productLabel}
                        className={this.state.currentLabel === this.state.productLabel ? " " : "p-button-text"}
                        onClick={ () => this.changeLabel(this.state.productLabel)}
                        // disabled={!this.state.isFinishedProductSearch || this.state.isSearchByNumero}
                        disabled
                    />                    
    
                    </div>
                )
            }
        }

        const renderNFTable = () => {
            return (
                <TransactionByNFTable
                    list = {this.state.NFData.notasFiciaisList}
                    search={this.search}
                    startLoadingTable={() => this.setState({loadingNFTable: true})}
                    finishLoadingTable={() => this.setState({loadingNFTable: false})}
                    tipoNF={this.state.tipo}
                    isSearchByNumero={this.state.isSearchByNumero}
                    setInfo={this.setInfo}
                    loading={this.state.loadingNFTable}
                    conferenciaNF
                    situacao={this.state.situacao}
                />               
            )
        }

        const renderProductTable = () => {
            return (
                <TransactionByProductTable
                    list = {this.state.productTransactionData.productTransactionList}
                    tipoNF={this.state.tipo}
                    setInfo={this.setInfo}
                    loading = {this.state.loadingProductTable}
                />                
            )
        }

        const renderTables = () => {
            if(this.state.currentLabel === this.state.NFLabel){
                return renderNFTable()
            }
            else{
                return renderProductTable()
            }
        }

        const renderResult = () => {
            if(!this.state.habilitaBusca){
                return (
                    <>
                    <div className = "card mb-3">
                    <div className = "card-header d-flex justify-content-between align-items-center">
                        <Button
                            label={ `${this.getQuantidadeLabel()} ${this.state.quantidadeItems}` }
                        />
                        <Button
                            label={'Valor Total: ' + this.state.totalValue }
                            className="p-button-success ml-auto"
                        />
                    </div>
                    </div>
                    {renderTables()}
                    </>               
                )
            }

        }

        const renderPesquisaPorNumeroButton = () => {
            return (
            <Button 
                label="Pesquisar Por Número"
                icon="pi pi-search"
                onClick = {this.searchByNumero}
                disabled={!this.state.habilitaBusca}
            />
            )
        }

        const dialogFooter = (
            <DialogFooter save = {this.callSearchPesquisaNumero} hideDialog = {this.hidePesquisaNumeroDialog}/>
        )

        const renderPesquisaNumeroDialog = () => {
            return (
                <Dialog
                    visible={this.state.pesquisaNumeroDialogVisible}
                    header={"Pesquisar Por Número"}
                    modal = {true}
                    style={{ width: '350px' }}
                    footer={dialogFooter}
                    onHide={this.hidePesquisaNumeroDialog}
                >
                    <div className="p-field">
                        {renderValueInput(true)}                     
                    </div>
                </Dialog>
            )
        }

        const renderValueInput = () => {
            return (
                <>
                <div className="row">
                <div className="col-md-8">
                <label htmlFor="numeroNF">Número da Nota Fiscal</label>
                <input
                    type="number"
                    className={"form-control " + this.state.inputSearchNumeroErrorClass}
                    autoFocus
                    value = {this.state.numeroNFToSearch}
                    name="numeroNFToSearch"
                    onChange={this.handleNumeroChange}
                    onKeyPress={this.handlePesquisaNumeroKeyPress}
                    id="numeroNFToSearch"
                />
                <div className="invalid-feedback">Preencha</div>                
                </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                <small id="valorError" className="p-error">{this.state.errorSearchValueMessage}</small>
                </div>
                </div>
                </>
            )
        }

        const renderCommonSearch = () => {
            if(!this.state.isSearchByNumero){
                return (
                    <>
                    <div className="row">

                        <div className = "col-md-2">
                            <label htmlFor="filtro">Filtrar Por</label>
                            <SelectMenu
                                className={"form-control " + this.state.inputNFFilterTypeErrorClass }
                                name="selectedNFFilterType"
                                list= {ConstantsUtil.filtroDataNFOptionsList} 
                                value={this.state.selectedNFFilterType}
                                onChange={this.handleNFFilterTypeChange}
                                disabled={!this.state.habilitaBusca}
                            />
                            <div className="invalid-feedback">{ConstantsUtil.preecnhaLabel}</div>
                        </div>                        
    
                        <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            label="Escolher período"
                            handleFilterChange={this.handleDateFilterChange}
                            handleDateChange={this.handleDateChange}
                            missingFilterType={this.state.missingFilterType}
                            missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                            // tooltip={"O período selecionado neste filtro será em relação à Data de Emissão da Nota Fiscal. \n Caso queira filtrar por outras datas (como entrada ou importação), utilize os filtros da tabela que será gerada abaixo."}
                        />                    
    
                        <div className = "col-md-2">
                        <FormGroup label = "Tipo da Nota Fiscal" htmlFor = "InputType">
                            <SelectMenu
                                className={"form-control " + this.state.inputTypeErrorClass}
                                name="tipo"
                                list= {typeList}
                                value={this.state.tipo}
                                onChange={this.handleChange}
                                disabled={!this.state.habilitaBusca}
                                />
                            <div className="invalid-feedback">{this.state.errorTypeMessage}</div>
                        </FormGroup>
                        </div>
                    </div>
                    </>
                )
            }
        }

        const renderSearchByNumero = () => {
            if(this.state.isSearchByNumero){
                return (
                    <div className="row">
                        <div className="col-md-12">
                        <label htmlFor="valor">Pesquisa pela NF de Número:</label>
                        <input
                            style={{marginLeft: '0.5rem'}}
                            type="text"
                            name="numeroNFToSearch"
                            inputId="numeroNFToSearch"
                            autoFocus
                            value={this.state.numeroNFToSearch}
                            disabled
                        />
                        </div>
                        <br />
                    </div>                    
                )
            }
        }

        return (
                   
            <div className="bs-docs-section" >
                
                <Card
                    title = "Conferência de NFs"
                    rightComponent={renderPesquisaPorNumeroButton()}
                > 
                
                <div className = "col-md-12">
                    {renderCommonSearch()}
                    {renderSearchByNumero()}
                </div>
                    
                {renderButtons()}

                {renderViewType()}

                {renderResult()}

                {renderPesquisaNumeroDialog()}

                </Card>
                <div className="d-flex "/>
            </div>
              
        )
    }


}

ConferenciaNF.contextType = AuthContext

export default withRouter(ConferenciaNF)