import React from 'react'
import Login from '../views/login'
import ChangePassword from '../views/changePassword'
import RecoverPassword from '../views/recoverPassword/recoverPassword'

import { Route, Switch, Redirect, BrowserRouter, HashRouter } from 'react-router-dom'
import { AuthConsumer } from './authProvider'
import SearchProduct from '../views/product/searchProducts'
import InventoryLaunch from '../views/inventory/inventoryLaunch'
import ResgisterProduct from '../views/product/resgisterProducts'
import MenuHome from '../views/menuHome'
import Parametrize from '../views/parametrize/parametrize'
import CadastrarTiposDeRecebimento from '../views/tiposDeRecebimento/cadastrarTiposDeRecebimento'
import LancarFechamentoDeCaixa from '../views/contasAReceber/fehcamentoDeCaixa/lancarFechamentoDeCaixa'
import FichaDeRecebimento from '../views/contasAReceber/fichaDeRecebimento/fichaDeRecebimento'
import CadastrarContas from '../views/conciliacaoBancaria/cadastrarContas'
import PlanoDeContas from '../views/planoDeContas/planoDeContas'
import cadastrarPdvCaixa from '../views/contasAReceber/pdvCaixa/cadastrarPdvCaixa'
import ReprocessarLancamentoFechamentoDeCaixa from '../views/contasAReceber/fehcamentoDeCaixa/reprocessarLancamentoFechamentoDeCaixa'
import LancarFechamentoBancario from '../views/conciliacaoBancaria/lancarFechamentoBancario'
import FichaBancaria from '../views/conciliacaoBancaria/fichaBancaria'
import Fornecedores from '../views/fornecedores'
import GrupoDePagamento from '../views/contasAPagar/grupoDePagamento'
import TipoDePagamento from '../views/contasAPagar/tiposDePagamento'
import FichaDeRecebimentoConsolidada from '../views/relatorios/fichaDeRecebimentoConsolidada'
import LivroRazao from '../views/relatorios/livroRazao'
import ReprocessarLivroDiario from '../views/relatorios/reprocessarLivroDiario'
import DREMensal from '../views/relatorios/DREMensal'
import ImplantacaoContaBancaria from '../views/implantacao/implantacaoContaBancaria'
import AplicacaoFinanceira from '../views/conciliacaoBancaria/aplicacaoFinanceira'
import DREConsolidado from '../views/relatorios/DREConsolidado'
import RelatorioFechamentoCaixa from '../views/relatorios/relatorioFechamentoCaixa'
import * as popUp from '../components/toastr'
import ConstantsUtil from '../context/constantsUtil'
import CadastroUsuarios from '../views/cadastroUsuarios'
import Empresas from '../views/empresas'
import FichaDeEstoque from '../views/relatorios/estoque/fichaDeEstoque'
import ConferenciaNF from '../views/transactions/conferenciaNF'
import ValidacaoNFsEntrada from '../views/transactions/validacaoNFsEntrada'
import LancarProvisoesAPagar from '../views/contasAPagar/provisoes/lancarProvisoesAPagar'
import FlxuoCaixaPrevisto from '../views/relatorios/flxuoCaixaPrevisto'
import CheckProvisoesAPagar from '../views/contasAPagar/provisoes/checkProvisoesAPagar'
import HistoricoImportacaoNFs from '../views/relatorios/estoque/historicoImportacaoNFs'
import GruposDeEmpresas from '../views/gruposDeEmpresas'
import DRECompetenciaMensal from '../views/relatorios/DRECompetencia/DRECompetenciaMensal'
import LancamentoManualNF from '../views/transactions/lancamentoManualNF'
import FichaDePagamento from '../views/contasAPagar/relatorios/fichaDePagamento'
import SyncNFsVenda from '../views/relatorios/estoque/syncNFsVendas'


function AuthRoute( {component: Component, hasPermission, checkSession, ...props} ) {

    return(
        <Route {...props} render={(componentProps) => {
            // chama o método checkSessionExpirationTime() de authProvider.js
            checkSession()
            if(props.path === '/'){
                if(hasPermission){
                    return (
                        <Redirect to={ {pathname: ConstantsUtil.homePathname, state: {from: componentProps.location} } } />
                    )
                }
                else {
                    return (
                        <Redirect to={ {pathname: '/login', state: {from: componentProps.location} } } />
                    )
                }
            }
            else if(hasPermission) {
                return (
                    <Component {...componentProps} />
                ) 
            } else{
                popUp.errorPopUp(ConstantsUtil.accessDeniedMessage)
                const newPath = `/login${props.path}/true`
                return(
                    <Redirect to={ {pathname: newPath, state: {from: componentProps.location} } } />
                )
            }
        } } />
    )
    
}

function Routes(props){
    return (
        <BrowserRouter>
        {/* // <HashRouter> */}
            <Switch>
                <Route path = "/login/:redirectPath?/:expired?" component = {Login} />
                <Route exact path = "/recoverPassword/:email?/:hash?" component = {RecoverPassword} />

                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/home/:name?/:email?" component = {MenuHome} />
                {/* <AuthRoute hasPermission={props.perfil.isAdmin} checkSession={props.checkSession} path = "/signUp" component = {SignUp} /> */}
                <AuthRoute hasPermission={props.perfil.isAdmin} checkSession={props.checkSession} path = "/usuarios" component = {CadastroUsuarios} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/empresas" component = {Empresas} />
                <AuthRoute hasPermission={props.perfil.isAdmin} checkSession={props.checkSession} path = "/gruposDeEmpresas" component = {GruposDeEmpresas} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/importacao" component = {ResgisterProduct} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = {ConstantsUtil.historicoImportacaoNFsPathname} component = {HistoricoImportacaoNFs} />
                <AuthRoute hasPermission={props.perfil.isAdmin} checkSession={props.checkSession} path = {ConstantsUtil.syncImportacaoNFsVendaPathname} component = {SyncNFsVenda} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/searchProducts" component = {SearchProduct} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/inventoryLaunch" component = {InventoryLaunch} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/validacaoNFEntrada" component = {ValidacaoNFsEntrada} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/parameterize" component = {Parametrize} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/conferenciaNF" component = {ConferenciaNF} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/lancamentoManualNF" component = {LancamentoManualNF} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/changePassword" component = {ChangePassword} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/cadastrarTiposDeRecebimento" component = {CadastrarTiposDeRecebimento} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/lancarFechamentoDeCaixa" component = {LancarFechamentoDeCaixa} />
                <AuthRoute hasPermission={props.perfil.isAdmin} checkSession={props.checkSession} path = "/reprocessarLancamentosFechamentoDeCaixa" component = {ReprocessarLancamentoFechamentoDeCaixa} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fichaDeRecebimento" component = {FichaDeRecebimento} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/planoDeContas" component = {PlanoDeContas} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/cadastrarPDVCaixa" component = {cadastrarPdvCaixa} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/cadastrarContaBancaria" component = {CadastrarContas} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/aplicacaoFinanceira" component = {AplicacaoFinanceira} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/lancarFechamentoBancario" component = {LancarFechamentoBancario} />
                <AuthRoute hasPermission={props.perfil.isAdmin} checkSession={props.checkSession} path = "/implantacaoSaldo" component = {ImplantacaoContaBancaria} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fichaBancaria" component = {FichaBancaria} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fichaDeEstoque" component = {FichaDeEstoque} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/fornecedores" component = {Fornecedores} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/gruposDePagamento" component = {GrupoDePagamento} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/tiposDePagamento" component = {TipoDePagamento} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fichaDeRecebimentoConsolidada" component = {FichaDeRecebimentoConsolidada} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/livroRazao" component = {LivroRazao} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/reprocessarLivroDiario" component = {ReprocessarLivroDiario} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fluxoDeCaixa" component = {DREMensal} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fluxoDeCaixaConsolidado" component = {DREConsolidado} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/relatorioFechamentoCaixa" component = {RelatorioFechamentoCaixa} />
                <AuthRoute hasPermission={props.perfil.isAdmin || props.perfil.isOperador} checkSession={props.checkSession} path = "/lancamentoProvisoesAPagar" component = {LancarProvisoesAPagar} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/checkProvisoesAPagar" component = {CheckProvisoesAPagar} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fichaDePagamento" component = {FichaDePagamento} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/fluxoDeCaixaPrevisto" component = {FlxuoCaixaPrevisto} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/DRECompetenciaMensal" component = {DRECompetenciaMensal} />
                <AuthRoute hasPermission={props.isAuth} checkSession={props.checkSession} path = "/" />

            </Switch>
        {/* </HashRouter> */}
        </BrowserRouter>
    )
}

export default () => (
    <AuthConsumer>
        { (context) => ( <Routes isAuth={context.isAuth} perfil={context.perfil} checkSession={context.checkSessionExpirationTime}/> ) }
    </AuthConsumer>
)