import WorkerApiService from "../../workerApiServices";

class FichaDeEstoqueService extends WorkerApiService {
 
    constructor() {
        super('/api/fichaDeEstoque')
    }

    getFichaDeEstoqueByDateInterval(obj){
        return this.post("/searchByDateInterval", obj)
    }

    delete(obj){
        return this.post("/delete", obj)
    }

    update(obj){
        return this.put("/update", obj)
    }
    
}

export default FichaDeEstoqueService