import React from 'react'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../main/authProvider'
import { Card } from 'primereact/card';
import logo from './../images/logo4.png'
import BottomBar from '../components/bottomBar';

class MenuHome extends React.Component{

    render() {

        const header = (
            <img alt="logo" src={logo} />
        );

        return(
            <div className="bs-docs-section">
            <div className = "col-md-3 d-flex " 
                style ={{
                    margin: 'auto',

                    //para centralizar verticalmente:
                    position: 'absolute',
                    top: '50%',
                    left:'50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign:'center',
                }}
                >
            {/* <div className="card text-white bg-dark mb-3  " style={{maxWidth: '20em;'}} >
            <div className="card-header">VisãoRJ</div>
            <div className="card-body">
                <h4 className="card-title">Seja Bem-Vindo!</h4>
                <p className="card-text">Este é o sistema de controle financeiro da VisãoRJ.</p>
            </div>
            <div className="d-flex" />
            </div> */}

             <Card title="VisãoRJ" subTitle="Seja Bem-Vindo!" style={{ width: '25em' }}  header={header}>
                    <p className="m-0" style={{lineHeight: '1.5'}}>
                        Este é o sistema de controle financeiro da VisãoRJ.
                    </p>
                </Card>
            </div>

            {/* <BottomBar
                loggedInfo
                push={this.props.history.push}
            /> */}
            
            </div>
        )
    }

}

MenuHome.contextType = AuthContext

export default withRouter(MenuHome)