import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import TransferenciaBancaraiService from '../../app/service/conciliacaoBancaria/transferenciaBancaraiService'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import * as popUp from '../toastr'
import * as xlsx from 'xlsx'
import CancelLancamentoBancarioButton from './cancelLancamentoBancarioButton'
import EditLancamentoBancarioButton from './editLancamentoBancarioButton'
import EditLancamentoBancarioDialog from './editLancamentoBancarioDialog'
import TableFilters from '../tableFilters'
import AuthService from '../../app/service/authService'

class FichaBancariaTable extends React.Component {

    constructor(){
        super()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.transferenciaBancaraiService = new TransferenciaBancaraiService();
        // this.contaBancariaService = new ContaBancariaService()
    }

    state = {

        editLancamentoBancarioDialog: false,
        editLancamentoBancarioDialogHeader: "Editar Lançamento",

        displayDeleteConfirmation: false,

        lancamentoToEdit: null,
        lancamentoToDelete: null,

        filteredList: '',

        loading: false,

    }

    hideDialog = () => {
        this.setState({editLancamentoBancarioDialog: false})
    }

    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        var contaBancaria = ""
        var label = ""
        if(this.props.fichaBancaria && this.props.fichaBancaria.contaBancaria){
            contaBancaria = this.props.fichaBancaria.contaBancaria.formattedName ? this.props.fichaBancaria.contaBancaria.formattedName : ""
            label = "Conta Bancária: "
        }
        else if(this.props.fichaBancaria && this.props.fichaBancaria.aplicacaoFinanceira){
            contaBancaria = this.props.fichaBancaria.aplicacaoFinanceira
            label = "Aplicação Financeira: "
        }

        const contaBancariaLabel =  label + contaBancaria
        
        const periodo = this.props.fichaBancaria && this.props.fichaBancaria.initialDate ? this.props.fichaBancaria.initialDate + " - " + this.props.fichaBancaria.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {contaBancaria: contaBancariaLabel, periodo: periodoLabel}
    }

    callDelete = (lancamento) => {
        if(this.verificaPossuiContaBancaria(lancamento)){
            this.setState({lancamentoToDelete: lancamento})
            this.setState({displayDeleteConfirmation: true})
        }
    }

    cancelDelete = () => {
        this.setState({lancamentoToDelete: null})
        this.setState({displayDeleteConfirmation: false})
    }

    deleteLancamentoOuTransferencia = () => {
        if(this.state.lancamentoToDelete.tipoFichaBancaria === ConstantsUtil.tipoFichaBancariaTransferenciaLabel){
            this.deleteTransferenciaBancaria()
        }
        else{
            this.deleteLancamentoFechamentoBancario()
        }
    }

    deleteTransferenciaBancaria = () => {
        this.setState({displayDeleteConfirmation: false})
        if(this.props.fromOFX){
            this.props.deleteLancamentoOFX(this.state.lancamentoToDelete)
        }
        else{
            this.transferenciaBancaraiService.delete(
                {
                    idLancamento: this.state.lancamentoToDelete.idLancamentoFechamentoBancario 
                }
            ).then(response => {
                popUp.successPopUp("Transferência deletada com sucesso!")
                this.props.search()
            })
            .catch(error => {
                HandleErrorService.handleError(error)
            })
        }
    }

    deleteLancamentoFechamentoBancario = () => {
        if(this.props.fromOFX){
            this.props.deleteLancamentoOFX(this.state.lancamentoToDelete)
            this.setState({displayDeleteConfirmation: false})
        }
        else{
            this.deleteRealLancamentoFechamentoBancario()
        }
    }

    deleteRealLancamentoFechamentoBancario = () => {
        this.setState({displayDeleteConfirmation: false})
        this.lancarFechamentoBancarioService.delete(
            {
                idLancamento: 
                    this.state.lancamentoToDelete.tipoLancamentoBancario !== ConstantsUtil.lancamentoBancarioRecebimentoAplicacaoFinanceiraLabel ?
                        this.state.lancamentoToDelete.idLancamentoFechamentoBancario : 
                        this.state.lancamentoToDelete.idLivroDiario,
                    
                tipoDeLancamentoBancario: this.state.lancamentoToDelete.tipoLancamentoBancario
            }
        ).then(response => {
            popUp.successPopUp("Lançamento deletado com sucesso!")
            this.props.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    editLancamentoBancario = (lancamento) => {
        if(this.verificaPossuiContaBancaria(lancamento)) {
            this.setState({lancamentoToEdit: JSON.parse(JSON.stringify(lancamento))})
            this.setState({editLancamentoBancarioDialog: true})
        }
    }

    verificaPossuiContaBancaria = (lancamento) => {

        if(this.props.fromOFX && (!lancamento.contaBancaria || !lancamento.contaBancaria.id)){
            popUp.warningPopUp(ConstantsUtil.errorSelecioneContaBancariaMessage)
            return false;
        }

        return true

    }

    beginLoadingFichaBancariaTable = () => {
        this.setState({loading: true})
    }

    endLoadingFichaBancariaTable = () => {
        this.setState({loading: false})
    }

    rowClass(rowData) {
        return {
            [ConstantsUtil.totaisTableLabel]: rowData.tipoLancamentoBancario === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }
    }

    exportExcel = () => {
        const periodo = this.getTableHeaders().periodo.split("Período: ")[1]
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        
        
        if(this.props.DRE){
        //Ficha Bancária Dentro do DRE
            let tipo = ""

            if(this.props.diferencaDRE){
                tipo = 'Diferença'
            }
            
            else{
                tipo = this.props.DRE_RECEBIMENTO ? "Recebimento" : "Pagamento"
            }

            this.props.saveAsExcelFile(workbook, `Lançamentos DRE Mensal - ${tipo} - ${periodo} - VisãoRJ`);
        }
        else{
        //Ficha Bancária em sua tela padrão
            const contaBancaria = this.getTableHeaders().contaBancaria.split("Conta Bancária: ")[1]
            this.saveAsExcelFile(workbook, `Ficha Bancária - ${contaBancaria} - ${periodo} - VisãoRJ`);
        }

    }

    saveAsExcelFile(workbook, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([excelBuffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREFichaBancariaToExportByExcel());
        return worksheet
    }

    formattedDREFichaBancariaToExportByExcel = () => {
        var formattedList = []

        this.props.fichaBancaria.fichaBancariaList.forEach(item =>{
            var obj = {
                Data: item.data,
                Descrição: item.descricao,
                Histórico: item.historico
            }

            const objEntrada = {Entrada: item.entrada}
            const objSaida = {Saída: item.saida}
            const objSaldo = {Saldo: item.saldo}

            if(this.props.DRE){
                //Ficha Bancária Dentro do DRE

                if(this.props.diferencaDRE){
                    Object.assign(obj, objEntrada);
                    Object.assign(obj, objSaida);
                }

                else if(this.props.DRE_RECEBIMENTO){
                    Object.assign(obj, objEntrada);
                }
                
                else{
                    Object.assign(obj, objSaida);
                }
            }
            else{
                //Ficha Bancária em sua tela padrão
                Object.assign(obj, objEntrada);
                Object.assign(obj, objSaida);
                Object.assign(obj, objSaldo);
            }

            if(this.props.DRE){
                const contaBancariaColumn = 'Conta Bancária'
                Object.assign(obj, {[contaBancariaColumn]: item.contaBancaria.formattedName});
            }

            formattedList.push(obj)
        })

        return formattedList
    }

    getContaBancariaOrAplicacaoFinanceira = rowData => {
        let contaBancariaOuAplicacaoFinanceiraLabel = ''
        if(rowData.tipoLancamentoBancario === ConstantsUtil.lancamentoBancarioRecebimentoAplicacaoFinanceiraLabel){
            contaBancariaOuAplicacaoFinanceiraLabel = rowData.aplicacaoFinanceira.nome
        }
        else if(rowData.contaBancaria){
            contaBancariaOuAplicacaoFinanceiraLabel = rowData.contaBancaria.formattedName
        }
        else{
            /*
            Caso de transferência bancária.
            */
            contaBancariaOuAplicacaoFinanceiraLabel = rowData.descricao
        }
        rowData.contaBancariaOuAplicacaoFinanceiraLabel = contaBancariaOuAplicacaoFinanceiraLabel
    }

    getList(){
        if(this.props.fichaBancaria && this.props.fichaBancaria.fichaBancariaList){
            var list = this.props.fichaBancaria.fichaBancariaList
            list.forEach(item => this.getContaBancariaOrAplicacaoFinanceira(item))
            return list
        }
        return []
    }

    getTotalValue(){
        let totalValueEntrada = 0;
        let totalValueSaida = 0;
        let listToIterate = []

        if(this.state.filteredList){
            listToIterate = JSON.parse(JSON.stringify(this.state.filteredList))
        }
        else if(this.props.fichaBancaria && this.props.fichaBancaria.fichaBancariaList){
            listToIterate = JSON.parse(JSON.stringify(this.props.fichaBancaria.fichaBancariaList))
        }

        if(listToIterate.length !== 0){
            listToIterate.forEach(item => {
                // console.log("item: ", item)
                if(item.tipoFichaBancaria !== ConstantsUtil.tipoTotaisFichaBancariaLabel && item.tipoFichaBancaria !== ConstantsUtil.tipoSaldoAnteriorFichaBancariaLabel){
                    if(item.entrada){
                        totalValueEntrada+=item.entrada
                    }
                    else if(item.saida){
                        totalValueSaida+=item.saida
                    }
                }
            })
        }

        return {
            entrada: GeneralServices.valueBodyTemplate(totalValueEntrada),
            saida: GeneralServices.valueBodyTemplate(totalValueSaida)
        };
    }

    onTableListChange = (currentTableList) => {
        // console.log("onTableListChange")
        // console.log("currentTableList: ", currentTableList)
        // console.log("this.getList(): ", this.getList())
        if(currentTableList.length === this.getList().length){
            /*
            Usuário desselecionou todos os filtros.
            */
            this.setState({filteredList: ''})
        }
        else{
            this.setState({filteredList: currentTableList})
        }
    } 

    render(){

        const renderDescricaoOFXColumn = () => {
            if(this.props.fromOFX){
                return (
                    <Column
                        header="Descrição no Extrato"
                        field='descricaoOFX'
                        filter filterElement={descricaoOFXFilterElement}
                        style ={ {width: '90px'} }
                    />
                )                
            }
        }

        const renderActionColumn = () => {
            if(!this.props.disabled && !AuthService.getPerfil().isDiretor && !this.props.fromDRECompetencia && !this.props.disabled){
                return (
                    <Column header="Ações" body={actionBody} style ={ {width: '90px'} }
                    />
                )
            }

        }

        const renderEditLancamentoBancarioButton = (lancamento) => {
            return(
                <EditLancamentoBancarioButton 
                    editLancamentoBancario = {this.editLancamentoBancario}
                    lancamento = {lancamento}
                />
            )
        }

        const renderCancelLancamentoBancarioButton = (lancamento) => {
            return(
                <CancelLancamentoBancarioButton
                    callDelete = {this.callDelete}
                    lancamento = {lancamento}
                />
            )
        }

        const actionBody = (lancamento) => {

            if(lancamento.fromOFX){
                return [renderEditLancamentoBancarioButton(lancamento), renderCancelLancamentoBancarioButton(lancamento)]
            }

            if(lancamento.idLancamentoFechamentoBancario) {
               
                if(
                    (this.props.fichaBancaria && this.props.fichaBancaria.fundoFixo)
                    && (lancamento.tipoLancamentoBancario === ConstantsUtil.lancamentoBancarioRecebimentoLabel)
                    && (lancamento.natureza === ConstantsUtil.naturezaTipoDeRecebimentoDinheiroLabel)
                    ){
                    /*
                    Significa recebimento de conta bancária de dinheiro (fundo fixo) de um Tipo de Recebimento com natureza DINHEIRO
                    Não pode deixar editar nem cancelar, uma vez que essas ações são feitas através 
                    do lançamento de fechamento de caixa.
                    */
                        return (
                           <></>
                        )
                        
                }
                else{
                    /*
                    Significa recebimento em fundo fixo de tipo de recebimento com natureza DIFERENTE de DINHEIRO,
                    ou pagamento de conta bancária de fundo fixo,
                    ou qualquer outro lançamento de qualquer outra conta bancária que não seja fundo fixo.
                    */
                    if(lancamento.implantacaoSaldo || (this.props.isSearchByValue && lancamento.tipoFichaBancaria === ConstantsUtil.tipoFichaBancariaTransferenciaLabel)){
                        /*
                        Se for implatanção de saldo ou (Pesquisa por Valor e Tranferência Bancáira), permite apenas cancelar.
                        */
                        return renderCancelLancamentoBancarioButton(lancamento)
                    }
                    return(
                        [renderEditLancamentoBancarioButton(lancamento), renderCancelLancamentoBancarioButton(lancamento)]
                    ) 
                }
            }
            else if(lancamento.idLivroDiario){
                return renderCancelLancamentoBancarioButton(lancamento)
            }
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                        // onClick={this.deleteLancamentoFechamentoBancario} 
                        onClick={this.deleteLancamentoOuTransferencia} 
                        autoFocus
                    />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.cancelDelete}
                            className="p-button-text" />
                </div>
            );
        }

        const renderSaldoColumn = () => {
            if(
                this.props.fichaBancaria 
                && (!this.props.fichaBancaria.aplicacaoFinanceira && !this.props.DRE)
                && !this.props.isSearchByValue 
                && !this.props.fromOFX
            ){
                return (
                    <Column field="saldo" header="Saldo"  style ={ {width: '80px'} }
                    body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true) }
                />
                )
            }
        }

        const renderHistoricoColumn = () => {
            if(!this.props.isSearchByValue && !this.props.fromOFX){
                return (
                    <Column
                        header="Histórico"
                        field="historico"
                        style ={ {width: '200px'} }
                        filter filterElement={historicoFilterElement}
                        body = { (rowData, rowIndex) => {
                            const uniqueId = `name_${rowIndex.rowIndex}`;
                            return (
                                <div
                                    style ={ {
                                        // width: ConstantsUtil.widthColumNomeDreConsolidado,
                                        // maxHeight: '160px',
                                        overflow: 'hidden',
                                        // textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        // whiteSpace: 'normal',
                                        // whiteSpace: 'pre',
                                        // whiteSpace: 'pre-line',
                                    } }
                                    title={rowData.historico}
                                >
        
                            {/* <Tooltip
                                target={uniqueId}
                                mouseTrack mouseTrackLeft={2} position="top">
                                {rowData.nome}
                            </Tooltip> */}
                            <span id={uniqueId}>{rowData.historico}</span>
        
                                </div>                        
                            )
                        }}
                    />
                )
            }
        }

        const renderContaBancariaColumn = () => {
            if(this.props.isSearchByValue || this.props.DRE){
                return (
                    <Column
                        header="Conta Bancária/Aplicação Financeira"
                        field="contaBancariaOuAplicacaoFinanceiraLabel"
                        style ={ {width: '200px'} }
                        filter filterElement={contaBancariaOuAplicacaoFinanceiraFilterElement}
                    />
                )
            }
        }

        const renderEntradaColumn = () => {
            if(!this.props.DRE || (this.props.DRE && (this.props.DRE_RECEBIMENTO || this.props.diferencaDRE) ) ){
                return(
                    <Column field="entrada" header="Entrada"  style ={ {width: '80px'} }
                        filter filterElement={entradaFilterElement}
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.entrada, true) }
                    />
                )
            }
        }

        const renderSaidaColumn = () => {
            if(!this.props.DRE || (this.props.DRE && (!this.props.DRE_RECEBIMENTO || this.props.diferencaDRE) ) ){
                return(
                    <Column field="saida" header="Saída"  style ={ {width: '80px'} }
                        filter filterElement={saidaFilterElement}
                        body = {rowData => GeneralServices.showFormattedIfNonZero(rowData.saida, true) }
                    />
                )
            }
        }

        const renderContaBancariaHeader = () => {
            if(!this.props.DRE && !this.props.isSearchByValue && !this.props.fromOFX) {
                return (
                <div className='col-md-6'>
                    <h5 
                        className='medium-font-size'
                        style={{ height: '20px' }}
                    >
                        {this.getTableHeaders().contaBancaria}
                    </h5>
                </div>
                )
            }
        }

        const renderPeriodoHeader = () => {
            if(!this.props.isSearchByValue && !this.props.fromOFX){
                return (
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>                    
                )
            }
        }

        const renderSpace = () => {
            if(this.props.DRE){
                return (
                    <div className='col-md-6'>
                    </div>
                )
            }
        }

        const renderExport = () => {
            if(!this.props.fromOFX){
                return (
                    <>
                    {renderSpace()}
                    <div className='col-md-2'>
                        <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success small-font"
                            tooltip={ConstantsUtil.exportXlsxLabel}
                            tooltipOptions={{position: 'top'}}
                            style={{maxHeight: '25px'}}
                            onClick={this.exportExcel}
                            disabled={!this.props.fichaBancaria || !this.props.fichaBancaria.fichaBancariaList}
                        />
                    </div> 
                    </>
                )
            }
        }

        const renderTotalButtons = () => {
            return (
                <>
                    <div className="card mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div>
                                <Button
                                    className="p-button-success"
                                    label={'Valor Total Entrada: ' + this.getTotalValue().entrada}
                                />
                            </div>
                            <div>
                                <Button
                                    className="p-button-danger"
                                    label={'Valor Total Saída: ' + this.getTotalValue().saida}
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        };

        const renderHeader = () => {
            if(!this.props.isSearchByValue){
                return(
                    <>
                    <div className = "card-header">
                    <div className='col-md-12'>
                        <div className='row'>

                            {renderContaBancariaHeader()}

                            {renderPeriodoHeader()}

                            {renderExport()}

                        </div>
                        
                    </div>
                    </div>                
                    </>
                )
            }
        }

        const dataFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedData,
            this.getList(),
            "selectedData",
            (name, value) => this.setState({[name]: value}),
            'data',
            '80px',
            "Data"
        )         

        const descricaoOFXFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDescricaoOFX,
            this.getList(),
            "selectedDescricaoOFX",
            (name, value) => this.setState({[name]: value}),
            'descricaoOFX',
            '160px',
            "Descrição"
        )     

        const descricaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDescricao,
            this.getList(),
            "selectedDescricao",
            (name, value) => this.setState({[name]: value}),
            'descricao',
            '160px',
            "Recebimento/Pagamento"
        )         

        const entradaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedEntrada,
            this.getList(),
            "selectedEntrada",
            (name, value) => this.setState({[name]: value}),
            'entrada',
            '80px',
            "Entrada"
        )         

        const saidaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedSaida,
            this.getList(),
            "selectedSaida",
            (name, value) => this.setState({[name]: value}),
            'saida',
            '80px',
            "Saída"
        )         

        const historicoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedHistorico,
            this.getList(),
            "selectedHistorico",
            (name, value) => this.setState({[name]: value}),
            'historico',
            // 'historico',
            '200px',
            "Histórico"
        )         

        const contaBancariaOuAplicacaoFinanceiraFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedContaBancariaOuAplicacaoFinanceiraLabel,
            this.getList(),
            "selectedContaBancariaOuAplicacaoFinanceiraLabel",
            (name, value) => this.setState({[name]: value}),
            'contaBancariaOuAplicacaoFinanceiraLabel',
            '200px',
            "Conta Bancária/Aplicação Financeira"
        )         

        return (
            <>

            {renderTotalButtons()}

            {renderHeader()}

            <DataTable
                ref={this.dt}
                value={this.getList()}
                className="p-datatable-sm small-font"
                rowClassName={this.rowClass}
                rowHover
                showGridlines
                selection={this.state.selectedContasBancaria}
                onSelectionChange={this.handleSelectionChange}
                onValueChange={this.onTableListChange}
                scrollable
                scrollHeight={ this.props.insideDialog ? '350px' : "500px"}
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Lançamento de Fechamento Bancário encontrado."
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.fichaBancaria && this.props.fichaBancaria.fichaBancariaList ? this.props.fichaBancaria.fichaBancariaList.length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

            <Column field="data" header="Data" style ={ {width: '80px'} }
                filter filterElement={dataFilterElement}
            />

            {renderDescricaoOFXColumn()}

            <Column header={this.props.fromOFX ? "Tipo de Recebimento/Pagamento" : "Descrição"} field="descricao" style ={ {width: '160px'} }
                filter filterElement={descricaoFilterElement}
                body = { (rowData, rowIndex) => {
                    const uniqueId = `name_${rowIndex.rowIndex}`;
                    // console.log("uniqueId: ", uniqueId)
                    return (
                        <div
                            style ={ {
                                // width: ConstantsUtil.widthColumNomeDreConsolidado,
                                // maxHeight: '160px',
                                overflow: 'hidden',
                                // textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                // whiteSpace: 'normal',
                                // whiteSpace: 'pre',
                                // whiteSpace: 'pre-line',
                            } }
                            title={rowData.descricao}
                        >

                    {/* <Tooltip
                        target={uniqueId}
                        mouseTrack mouseTrackLeft={2} position="top">
                        {rowData.nome}
                    </Tooltip> */}
                    <span id={uniqueId}>{rowData.descricao}</span>

                        </div>                        
                    )
                }}
            />

            {renderEntradaColumn()}

            {renderSaidaColumn()}

            {renderSaldoColumn()}

            {renderHistoricoColumn()}

            {renderContaBancariaColumn()}

            {renderActionColumn()}
            
            </DataTable>

            <EditLancamentoBancarioDialog  
                hideDialog={this.hideDialog}
                visible={this.state.editLancamentoBancarioDialog}
                header={this.state.editLancamentoBancarioDialogHeader}
                state={this.state.lancamentoToEdit}
                tipoDeRecebimentoList={this.props.tipoDeRecebimentoList}
                tipoDePagamentoList={this.props.fichaBancaria ? this.props.fichaBancaria.tipoDePagamentoList : []}
                idSelectedContaBancaria={this.props.fichaBancaria ? this.props.fichaBancaria.idContaBancaria : null}
                search={this.props.search}
                beginLoadingFichaBancariaTable={this.beginLoadingFichaBancariaTable}
                endLoadingFichaBancariaTable={this.endLoadingFichaBancariaTable}
                fromOFX={this.props.fromOFX}
                editLancamentoFromOFX={this.props.editLancamentoFromOFX}
                // disabled={true}
            />

            <Dialog header="Excluir Lançamento"
                visible={this.state.displayDeleteConfirmation}
                modal = {true} //congela restante da tela
                style={{ width: '350px' }}
                footer={renderDeleteConfirmationFooter()}
                onHide={this.cancelDelete}>
                <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                    <div style={{marginBottom: '10px'}}> Deseja confirmar? </div>
                </div>
            </Dialog>

            </>
        )
    }

}

export default FichaBancariaTable

