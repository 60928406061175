import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'

import currecyFormater from 'currency-formatter'
import * as popUp from '../../components/toastr'
import ProductService from '../../app/service/productService'
import HandleErrorService from '../../app/service/handleErrorService'
import ProductDialog from '../../components/product/productDialog'
import UpdateStockDialog from '../../components/product/updateStockDialog'
import TableFilters from '../../components/tableFilters'
import GeneralServices from '../../app/service/generalServices'
import ConstantsUtil from '../../context/constantsUtil'
import CustomHighlighter from '../../components/customHighlighter'
import FichaDeEstoqueTable from './fichaDeEstoqueTable'

class ProductCrudTable extends React.Component {

    state = {
        codigo: null,
        descricao: '',
        ncm: null,
        cfop: null,
        tipo: '',
        unidadeComercializada: '',
        quantidade: null,
        selectedProducts: null,
        productDialog: false,
        updateStockDialog: false,
        displayConfirmation: false,
        editId: null,
        updateStockId: null,
        codigoField: 'codigo',
        selectedCodes: null,
        descricaoField: 'descricao',
        selectedDescriptions: null,
        NCMField: 'ncm',
        selectedNCM: null,
        cfopField: 'cfop',
        selectedCfops: null,
        unitField: 'unidadeComercializada',
        selectedUnits: null,
        expandedRows: [],
        current: null,

        loading: false,

        columns: [
            { field: 'descricao', header: 'Descrição' },
            { field: 'codigo', header: 'Código' },
        ],

    }
    constructor(){
        super()
        this.toast = React.createRef()
        this.dt = React.createRef()
        this.productService = new ProductService();
        this.tableFilters = new TableFilters();
    }

    valueBodyTemplate = (rowData) => {
        return currecyFormater.format(rowData.value, {locale: 'pt-BR'})
    }
    
    editProduct = (product) => {
        this.setState({codigo: product.codigo})
        this.setState({descricao: product.descricao})
        this.setState({ncm: product.ncm})
        this.setState({cfop: product.cfop})
        this.setState({tipo: product.tipoProduto})
        this.setState({unidadeComercializada: product.unidadeComercializada})
        this.setState({productDialog: true})
        this.setState({editId: product.id})
        
    }

    updateStock = (product) => {
        this.setState({quantidade: product.quantidade})
        this.setState({updateStockId: product.id})
        this.setState({updateStockDialog: true})
    }

    hideDialog = (name) => {
        this.setState({[name]: false})
    }

    exportPdf = () => {
        var exportColumns = this.state.columns.map(col => ({ title: col.header, dataKey: col.field }))
        var list = this.props.list
        // console.log("list: ", list)
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, list);
                doc.save('cadastro de produtos.pdf');
            })
        })
    }

    exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = GeneralServices.jsonToSheet(this.props.list);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'cadastro de produtos');
        });
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    delete = () => {
        if(this.state.selectedProducts){
            this.setState({displayConfirmation: true})
        } else {
            popUp.warningPopUp("Nenhum produto foi selecionado para exclusão")
        }
    }
    
    updateProduct = (product) => {
        this.setState({loading: true})
        this.productService.update(this.state.editId, product)
        .then(response => {
            popUp.successPopUp("Produto editado com sucesso")
            this.props.search()
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
        this.setState({productDialog: false})
    }
    
    updateProductStock = (stockInfo) => {
        this.setState({loading: true})
        this.productService.updateStock(this.state.updateStockId, stockInfo)
        .then(response => {
            popUp.successPopUp("Estoque alterado com sucesso!")
            this.props.search()
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    this.setState({updateStockDialog: false})
}

    confirmDelete = () => {
        this.setState({displayConfirmation: false})
        this.setState({selectedProducts: null})
        if(this.state.selectedProducts){
            var listOfId = []
            Array.from(this.state.selectedProducts).forEach(selectedProduct => {
                listOfId.push(selectedProduct.id)
            })
        }
        this.props.deleteMultiple(listOfId)
    }

    onFilterChange = async (event, filterField) => {
        if(this.state.expandedRows && this.state.expandedRows.length!==0){
            await this.setState({expandedRows: []})
        }
        const name = event.target.name
        if(this.dt.current) {
            this.dt.current.filter(event.value, filterField, 'in');
        } else{
            this.state.current.filter(event.value, filterField, 'in');
        }
        this.setState({[name]: event.value})
    }

    handleRowToggle = (e) => {
        this.setState({expandedRows: e.data})
        if(!this.state.current) {
            this.setState({current: this.dt.current})
        }
        if(!this.dt.current) {
            this.dt.current = this.state.current
        }
    }

    handleSelectionChange = (e) => {
        this.setState({selectedProducts: e.value})

        if(this.props.handleSelectionChange){
            this.props.handleSelectionChange(e.value)
        }
    }

    render (){

        const renderToolbar = () => {
            if(this.props.simpleView){
                return (<></>)
            }
            return (
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            )
        }

        const rightToolbarTemplate = () => {
            return (
                <React.Fragment>
                    <Button 
                        className="p-button-danger small-font"
                        style={{maxHeight: '25px'}}
                        label="Deletar"
                        icon="pi pi-trash"
                            onClick={this.delete}
                            // disabled = {this.props.disableDeleteButton || !this.state.selectedProducts
                            //             || this.state.selectedProducts.length === 0}
                            disabled
                            />
                </React.Fragment>
            )
        }

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {/* <FileUpload mode="basic" accept="*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
                    <Button
                        className="p-button-success small-font"
                        style={{maxHeight: '25px'}}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                        label="Exportar"
                        icon="pi pi-file-excel"
                        onClick={this.exportExcel}
                    />
                    <Button
                        className="p-button-danger small-font"
                        style={{marginLeft: '10px', maxHeight: '25px'}}
                        tooltip={ConstantsUtil.exportPdfLabel}
                        tooltipOptions={{position: 'top'}}                        
                        label="Exportar"
                        icon="pi pi-file-pdf"
                        onClick={this.exportPdf}
                    />
                </React.Fragment>
            )
        }

        const actionBody = (rowData) => {
            return (
                <React.Fragment>
                    <Button
                        tooltip = "Atualizar estoque"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-book"
                        className="p-button-rounded p-button-success p-mr-2"
                        style={ {maxHeight: '30px', maxWidth: '30px'} }
                        onClick={() => this.updateStock(rowData)} 
                    />
                    <Button
                        tooltip = "Editar"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-primary p-mr-2"
                        style={ {marginLeft: '3px', maxHeight: '30px', maxWidth: '30px'} }
                        onClick={() => this.editProduct(rowData)} 
                    />
                </React.Fragment>
            );
        }

        const renderDeleteConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.confirmDelete} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})}
                            className="p-button-text" />
                </div>
            );
        }

        //Código filter
        const codeFilterElement = this.tableFilters.renderCodigoFilter(this.state.selectedCodes, this.productService.getCodeList, this.props.list,
            "selectedCodes", this.onFilterChange, this.state.codigoField)
        
        //Descrição Filter
        const descriptionFilterElement = this.tableFilters.renderDescriptionFilter(this.state.selectedDescriptions,
            this.productService.getDescriptionList, this.props.list,
            "selectedDescriptions", this.onFilterChange, this.state.descricaoField, '150px', 'Nome')

        //NCM Filter
        const NCMFilterElement = this.tableFilters.renderNCMFilter(this.state.selectedNCM, this.productService.getNCMList,
            this.props.list, "selectedNCM", this.onFilterChange, this.state.NCMField)

        // //CFOP Filter
        // const cfopFilterElement = this.tableFilters.renderCfopFilter(this.state.selectedCfops, this.productService.getCfopList,
        //     this.props.list, "selectedCfops", this.onFilterChange, this.state.cfopField)

        //Unidade Filter      
        const unitFilterElement = this.tableFilters.renderUnitFilter(this.state.selectedUnits, this.productService.getUnitList, this.props.list,
            "selectedUnits", this.onFilterChange, this.state.unitField)        
        
            const rowExpansion = product => {
                return(
                    <div className="orders-subtable">
                    <h5>Ficha de estoque de {product.descricao}</h5>
                        <FichaDeEstoqueTable
                            fichaDeEstoque={product.stockSheet}
                        />
                    </div>
                )
            }

            const renderSelectionColumn = () => {
                if(this.props.simpleView){
                    return(
                        <Column selectionMode="single" headerStyle={{ width: '40px'}} />
                    )
                }
                return (
                    <Column selectionMode="multiple" headerStyle={{ width: '40px'}} />
                )
            }

            const renderExpanderColumn = () => {
                if(!this.props.simpleView){
                    return (
                        <Column expander style={{ width: '50px' }} />
                    )
                }
            }

            const renderNomeFornecedorColumn = () => {
                if(this.props.isBuyProducts){
                    return (
                        <Column
                            header="Fornecedor"
                            field="nomeFornecedor"
                            sortable
                            style ={ {width: '100px'} }
                        />
                    )
                }
            }

            const renderCompleteTableList = () => {
                if(!this.props.simpleView){
                    return (
                    [
                    
                    <Column field="quantidade" header="Qtd Estoque" sortable={!this.props.simpleView} style ={ {width: '100px'} } />,

                    <Column field="timestampDataAtualizacaoEstoque" header="Última atualização manual de estoque"
                        body={rowData => rowData.dataAtualizacaoEstoque} 
                        sortable={!this.props.simpleView} style ={ {width: '180px'} }
                    />,

                    <Column field={this.state.NCMField} header="NCM" body={rowData => GeneralServices.adjustNCM(rowData.ncm)}
                        sortable={!this.props.simpleView}
                        style ={ {width: '100px'} }
                            filter={!this.props.simpleView} filterElement={NCMFilterElement} 
                    />,

                    // <Column field={this.state.cfopField} header="CFOP" sortable={!this.props.simpleView} style ={ {width: '80px'} }
                    //         filter={!this.props.simpleView} filterElement={cfopFilterElement} 
                    // />,
                    
                    <Column field={this.state.unitField} header="Unidade" sortable={!this.props.simpleView} style ={ {width: '70px'} }
                            filter={!this.props.simpleView} filterElement={unitFilterElement} 
                    />,
                    
                    <Column header="Ações" body={actionBody} style ={ {width: '80px'} } />,


                    <Column field="timestampDataCadastroNoSistema" header="Data de cadastro no sistema"
                            body={rowData => rowData.dataCadastroNoSistema} 
                            sortable={!this.props.simpleView} style ={ {width: '200px'} }
                    />,                       
                    ]
                    )
                }
            }

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">

                {renderToolbar()}

                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    selection={this.state.selectedProducts}
                    onSelectionChange={this.handleSelectionChange}
                    scrollable
                    style={{ maxWidth: '100vw' }}
                    scrollHeight="500px"
                    emptyMessage="Nenhum produto encontrado"
                    loading={this.props.loading || this.state.loading}
                    expandableRows={true}
                    expandedRows = {this.state.expandedRows}
                    onRowToggle={this.handleRowToggle}
                    rowExpansionTemplate={rowExpansion}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} produtos"
                    >

                    {renderSelectionColumn()}

                    {/* {renderExpanderColumn()} */}

                    <Column field={this.state.codigoField} header="Código" sortable={!this.props.simpleView} style ={ {width: '80px'} }
                            filter={!this.props.simpleView} filterElement={codeFilterElement}
                            body={rowData => 
                                <CustomHighlighter
                                    searchWords={[this.props.searchText]}
                                    textToHighlight={rowData.codigo}
                                />
                            }
                    />
                    <Column field={this.state.descricaoField} header="Nome Produto" sortable={!this.props.simpleView} style ={ {width: '200px'} }
                            filter={!this.props.simpleView} filterElement={descriptionFilterElement}
                            body={rowData => 
                                <CustomHighlighter
                                    searchWords={[this.props.searchText]}
                                    textToHighlight={rowData.descricao}
                                />
                            }                            
                    />

                    {renderNomeFornecedorColumn()}

                    <Column
                        header="Tipo"
                        field="tipoProduto.descricao"
                        sortable={!this.props.simpleView}
                        style ={ {width: '100px'} }
                        body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.tipoProduto ? rowData.tipoProduto.descricao : '', rowIndex)}
                    />

                    {renderCompleteTableList()}

                </DataTable>
            </div>

            <ProductDialog
                saveProdutoVenda={this.updateProduct}
                hideDialog={() => this.hideDialog('productDialog')}
                visible={this.state.productDialog}
                header="Editar Produto"
                register={false}
                state={this.state}
            />
            
            <UpdateStockDialog 
                header="Atualizar Estoque"
                save={this.updateProductStock}
                productId={this.state.updateStockId}
                hideDialog={() => this.hideDialog('updateStockDial')}
                visible={this.state.updateStockDialog}
            />

            <Dialog header="Deletar Produto"
                        visible={this.state.displayConfirmation}
                        modal = {true} //congela restante da tela
                        style={{ maxWidth: '350px' }}
                        footer={renderDeleteConfirmationFooter()}
                        onHide={() => this.setState({displayConfirmation: false})}>
                    <div className="confirmation-content row" style={{marginLeft: '10px'}}>
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                        <div style={{marginBottom: '10px'}}>
                            { this.state.selectedProducts ? "Confirmar exclusão de "
                             + this.state.selectedProducts.length + " produto(s)?" 
                            : ""
                            }
                        </div>
                    </div>
            </Dialog>
        </div>
        )
    }


}  

export default ProductCrudTable