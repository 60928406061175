import React from 'react'
import EmpresasTableSearchComponent from './empresasTableSearchComponent'
import { Dialog } from 'primereact/dialog'

class EmpresasTableSearchDialog extends React.Component {

    render() {
        return(
            <Dialog
                visible={this.props.visible} style={{ width: '95vw' }}
                header={"Empresas"}
                modal
                className="p-fluid"
                // footer={editTipoRecebimentoDialogFooter}
                onHide={this.props.hideDialog}
            >
                <EmpresasTableSearchComponent
                    context={this.props.context}
                    disableEditColumn
                />
            </Dialog>
        )
    }

}
export default EmpresasTableSearchDialog
