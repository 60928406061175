import ConstantsUtil from "../../context/constantsUtil";

class NotaFiscalDTO {

    constructor
    (
        dataHora,
        fornecedor,
        numero,
        valorTotal,
        valorAcrescimoDesconto,
        tipo,
        modelo,
        transactions,
        provisao,
        complementoDeHistorico
    )
    {
        this.dataHoraEntrada = dataHora; //data de entrada na empresa, informado pelo usuário durante a valuidação da NF.
        this.fornecedor = fornecedor
        this.nomeFornecedorOuCliente = fornecedor.nome;
        this.numero = numero;
        this.valorTotal = valorTotal;
        this.valorAcrescimoDesconto = valorAcrescimoDesconto;
        this.tipo = tipo;
        this.modelo = modelo;
        this.transactions = transactions;
        this.tipoCriacao = 'MANUAL';
        this.provisao = provisao;
        this.complementoDeHistorico = complementoDeHistorico;
        this.fonte = ConstantsUtil.fonteNFUsuario
        this.sefaz = false


    }


}

export default NotaFiscalDTO