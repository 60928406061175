import React from 'react'
import { Menubar } from 'primereact/menubar';
import { AuthContext } from '../../main/authProvider';
import PDVCaixaService from '../../app/service/contasAReceber/pdvCaixaService';
import HandleErrorService from '../../app/service/handleErrorService';
import * as popUp from '../toastr';
import UserMenu from './userMenu';
import MenuItem from './menuItem';
import NFService from '../../app/service/estoque/NFService';
import ConstantsUtil from '../../context/constantsUtil';

class MenuModulesbar extends React.Component {

    constructor(){
        super()
        this.pdvCaixaService = new PDVCaixaService()
        this.nfService = new NFService()
    }

    state = {
        urlCadastrarTipoDeRecebimento: '',
    }

    componentDidMount(){
        this.checaAlertas()
    }

    checaAlertas = () => {
        this.checaAlertaFalhaImportacaoNFs()
    }

    checaAlertaFalhaImportacaoNFs = () => {
        if(this.context.isAuth
            && window.location.pathname !== ConstantsUtil.historicoImportacaoNFsPathname
            // && (this.context.perfil.isAdmin)
            ){
            this.nfService.existeFalhaImportacaoNF()
            .then(response =>{
                if(response.data){
                    popUp.noticeFalhaImportacaoNFsPopUp()
                }
            })
            .catch(error => {
                HandleErrorService.handleError(error)
            })
        }
    }
    
    render () {

        const goToLancamentoFechamentoCaixa = () => {
            this.pdvCaixaService.check()
            .then(respose => {
                if(respose.data){
                    window.location='/lancarFechamentoDeCaixa'
                } else{
                    popUp.noticePdvCaixaPopUp()
                }
            })
            .catch(error => {
                HandleErrorService.handleError(error)
            })
        }

        const goToCadastroTipoDeRecebimento = () => {
            this.pdvCaixaService.check()
            .then(respose => {
                if(respose.data){
                    window.location='/cadastrarTiposDeRecebimento'
                } else{
                    popUp.noticePdvCaixaPopUp()
                }
            })
            .catch(error => {
                HandleErrorService.handleError(error)
            })
        }

        const homeItem = {label: 'Home', icon: 'pi pi-fw pi-home',
            command: () => {
                window.location=ConstantsUtil.homePathname
            }
        }

        const menuItem = (item) => { 
            return (
                <MenuItem item={item}/>
            )
        }

        //Cadastros
        const produtosCadastrados = {
            label: 'Produtos',
            icon:"pi pi-search",
            url:"/searchProducts",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const pdvCaixa = { 
            label: 'Ponto de Venda (Caixa)',
            icon: 'pi pi-inbox',
            url: '/cadastrarPDVCaixa',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const tiposDeRecebimento = { 
            label: 'Tipos de Recebimento',
            command: goToCadastroTipoDeRecebimento,
            url: '/cadastrarTiposDeRecebimento',
            icon: 'pi pi-credit-card',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const fornecedores = { 
            label: 'Fornecedores',
            icon: 'pi pi-shopping-cart',
            url: '/fornecedores',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const gruposDePagamento = { 
            label: 'Grupos de Pagamento',
            icon: 'pi pi-sitemap',
            url: '/gruposDePagamento',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const tiposDePagamento = { 
            label: 'Tipos de Pagamento',
            icon: 'pi pi-wallet',
            url: '/tiposDePagamento',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const contasBancarias = {
            label: 'Contas Bancárias',
            icon: 'pi pi-dollar',
            url: '/cadastrarContaBancaria',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const aplicacaoFinanceira = {
            label: 'Aplicações Financeiras',
            icon: 'pi pi-percentage',
            url: '/aplicacaoFinanceira',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const planoDeContas = {
            label: 'Plano de Contas',
            icon: 'pi pi-file-excel',
            url: "/planoDeContas",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
            
        }



        //Controle de Estoque
        const importarArquivos = { 
            label: 'Importar', 
            icon:"pi pi-upload",
            url:"/importacao",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const historicoImportacaoArquivos = { 
            label: 'Histórico', 
            icon:"pi pi-undo",
            url: ConstantsUtil.historicoImportacaoNFsPathname,
            // disabled: !this.context.perfil.isAdmin,
            template: menuItem,
        }

        const syncVendas = { 
            label: 'Sincronizar NFs de Venda', 
            icon:"pi pi-link",
            url: ConstantsUtil.syncImportacaoNFsVendaPathname,
            disabled: !this.context.perfil.isAdmin,
            template: menuItem,
        }

        const importacaoArquivos = { 
            label: 'Importação de NFs', 
            items: [
                importarArquivos,
                historicoImportacaoArquivos,
                syncVendas
            ],
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const lancamentoInventario = {
            label: 'Lançamento de Inventário',
            icon:"pi pi-pencil",
            url:'/inventoryLaunch',
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const validacaoNFEntrada = {
            label: 'Validação das NFs de Entrada',
            icon:"pi pi-check",
            url:"/validacaoNFEntrada",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const parametrizacaoProdutos = {
            label: 'Parametrização de Produtos',
            icon:"pi pi-link",
            url:"/parameterize",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const conferenciaNF = {
            label: 'Conferência de NFs',
            icon:"pi pi-eye",
            url:"/conferenciaNF",
            // disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const lancamentoManualNF = {
            label: 'Lançamento Manual de NF',
            icon:"pi pi-pencil",
            url:"/lancamentoManualNF",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }



        //Lançamentos
        const lancarFechamentoDeCaixa = {   
            label: 'Lançar', icon: 'pi pi-pencil',
            command: goToLancamentoFechamentoCaixa,
            url: "/lancarFechamentoDeCaixa",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const reprocessarFechamentoDeCaixa = {
            label: 'Reprocessar', icon: 'pi pi-refresh',
            // command: () => {window.location="/reprocessarLancamentosFechamentoDeCaixa"},
            url:"/reprocessarLancamentosFechamentoDeCaixa",
            disabled: !this.context.perfil.isAdmin,
            template: menuItem,
       }

       const fechamentoDeCaixa = {
            label: 'Fechamento de Caixa',
            items: [
                lancarFechamentoDeCaixa,
                reprocessarFechamentoDeCaixa,
            ],
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const implantacao = {
            label: 'Implantação',
            icon: 'pi pi-pencil',
            url:"/implantacaoSaldo",
            disabled: !this.context.perfil.isAdmin,
            template: menuItem,
        }

        const conciliacaoBancaria = {
            label: 'Conciliação Bancária',
            items: [
                { label: 'Lançar', icon: 'pi pi-pencil',
                    command: () => { window.location = "/lancarFechamentoBancario" },
                    url: "/lancarFechamentoBancario",
                    disabled: this.context.perfil.isDiretor,
                    template: menuItem,
                }
            ],
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const lancarProvisoesAPagar = {
            label: 'Lançar',
            icon: 'pi pi-pencil',
            url:"/lancamentoProvisoesAPagar",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const conferirProvisoesAPagar = {
            label: 'Conferir',
            icon: 'pi pi-eye',
            url:"/checkProvisoesAPagar",
            disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const provisoesAPagar = {
            label: 'Provisões a pagar',
            items:[
                lancarProvisoesAPagar,
                conferirProvisoesAPagar
            ],
            // icon: 'pi pi-pencil',
            // url:"/lancamentoProvisoesAPagar",
            // disabled: this.context.perfil.isDiretor,
            template: menuItem,
        }

        const reprocessLivroDiario = {
            label: 'Reprocessar Livro Diário',
            icon: 'pi pi-refresh',
            url: "/reprocessarLivroDiario",
            disabled: true,
            template: menuItem,
        }



        //Conas a receber
        const fichaBancaria = { 
            label: 'Ficha Bancária',
            icon: 'pi pi-dollar',
            // command: () => {
            //     window.location="/fichaBancaria"
            // },
            url: "/fichaBancaria"
        }



        //Contas a Pagar
        // const livroRazao = { 
        //     label: 'Livro Razão',
        //     // icon: 'pi pi-book',
        // }


        //Relatórios
        const fechamentoCaixa = { 
            label: 'Fechamento de Caixa',
            icon: 'pi pi-book',
            // command: () => {
            //     window.location="/fichaDeRecebimento"
            // },
            url: "/relatorioFechamentoCaixa"
        }

        const fichaDeRecebimentoIndividual = { 
            label: 'Individual',
            icon: 'pi pi-book',
            // command: () => {
            //     window.location="/fichaDeRecebimento"
            // },
            url: "/fichaDeRecebimento"
        }

        const fichaDeRecebimentoConsolidada = {
            label: 'Consolidada',
            icon: 'pi pi-book',
            url: "/fichaDeRecebimentoConsolidada"
        }

        const fichaDeRecebimento = {
            label: 'Ficha de Recebimento',
            items: [
                fichaDeRecebimentoIndividual,
                fichaDeRecebimentoConsolidada,
            ]
        }

        const fichaDePagamento = {
            label: 'Ficha de Pagamento',
            icon: 'pi pi-book',
            url: "/fichaDePagamento"
        }

        const livroRazao = {
            label: 'Livro Razão',
            icon: 'pi pi-book',
            url: "/livroRazao"
        }

        const DREMensal = {
            label: 'Mensal',
            icon: 'pi pi-book',
            url: "/fluxoDeCaixa"
        }

        const DREConsolidado = {
            label: 'Consolidado',
            icon: 'pi pi-book',
            url: "/fluxoDeCaixaConsolidado"
        }

        const DRE = {
            label: 'Fluxo de Caixa Realizado',
            items: [
                DREMensal,
                DREConsolidado,
            ]
        }

        const DRECompetenciaMensal = {
            label: 'Mensal',
            icon: 'pi pi-book',
            url: "/DRECompetenciaMensal"
        }

        const DRECompetenciaConsolidado = {
            label: 'Consolidado',
            icon: 'pi pi-book',
            url: "/DRECompetenciaConsolidado",
            disabled: true
        }

        const DRECompetencia = {
            label: 'DRE Por Competência',
            items: [
                DRECompetenciaMensal,
                DRECompetenciaConsolidado,
            ]
        }


        const fluxoDeCaixaPrevisto = {
            label: 'Fluxo de Caixa Previsto',
            icon: 'pi pi-book',
            url: "/fluxoDeCaixaPrevisto"
            // items: [
            //     DREMensal,
            //     DREConsolidado,
            // ]
        }

        const fichaDeEstoque = {
            label: 'Ficha de Estoque',
            icon: 'pi pi-box',
            url: "/fichaDeEstoque",
        }

        const espaco = {
            // label: '-----Estoque----',
            label: '',
            // items: [
            //     DREMensal,
            //     DREConsolidado,
            // ]
        }


        //Painel
        const noLoggedItems = [
            homeItem
        ]

        const loggedItems = [

            homeItem,
            
            {
                label: 'Cadastros',
                icon: 'pi pi-plus',
                items: [
                    produtosCadastrados,
                    pdvCaixa,
                    contasBancarias,
                    aplicacaoFinanceira,
                    tiposDeRecebimento,
                    tiposDePagamento,
                    fornecedores,
                    gruposDePagamento,
                    planoDeContas
                ]
            },
            {
                label: 'Controle de Estoque', icon: 'pi pi-box',
                items: [
                    importacaoArquivos,
                    validacaoNFEntrada,
                    lancamentoInventario,
                    parametrizacaoProdutos,
                    conferenciaNF,
                    lancamentoManualNF,
                ]
            },
            {
                label: 'Lançamentos', icon: 'pi pi-chart-line',
                items: [
                    implantacao,
                    fechamentoDeCaixa,                        
                    conciliacaoBancaria,                        
                    reprocessLivroDiario,                        
                    provisoesAPagar,
                ]
            },
            {
                label: 'Relatórios', icon: 'pi pi-book',
                items:
                [
                    fechamentoCaixa,
                    fichaDeRecebimento,
                    fichaDePagamento,
                    fichaBancaria,
                    livroRazao,
                    DRE,
                    fluxoDeCaixaPrevisto,
                    DRECompetencia,
                    fichaDeEstoque,
                ]
            },
        ];

        const chooseItems = () => {
            return this.context.isAuth ? loggedItems : noLoggedItems
        }
        
        const end = <UserMenu 
            context = {this.context}
        />

        return (
            <div>
                <div className="card"
                
                >
                    <Menubar
                        model={chooseItems()}
                        end={end} 
                        // style={{zIndex:2}}
                        style={{ zIndex: 2, height: '2.8rem', fontSize: '0.8rem' }} // obs: zIndex setado para dropdown sobrepor labels do mui
                    /> 
                </div>
            </div>
        )
    }
}

MenuModulesbar.contextType = AuthContext

export default MenuModulesbar