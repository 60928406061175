import React from 'react'
import GeneralServices from '../../app/service/generalServices'
import CustomCalendar from '../calendar/customCalendar'
import { Button } from 'primereact/button'
import TooltipButton from '../tooltipButton'
import * as popUp from '../toastr'
import QuantidadeParcelasComponent from '../contasAPagar/quantidadeParcelasComponent'
import { InputMask } from 'primereact/inputmask'
import SelectMenu from '../selectMenu'
import ProductService from '../../app/service/productService'
import FormGroup from '../form-group'
import ConstantsUtil from '../../context/constantsUtil'
import InputChooseFornecedor from '../contasAPagar/inputChooseFornecedor'
import { InputNumber } from 'primereact/inputnumber'
import BuyProductService from '../../app/service/buyProductService'
import HandleErrorService from '../../app/service/handleErrorService'
import NotaFiscalDTO from '../../dominio/nf/NotaFiscalDTO'
import ItensNFComponent from './itensNFComponent'

class DadosNFComponent extends React.Component {

    constructor(){
        super()
        this.productService = new ProductService()
        this.buyProductService = new BuyProductService()
        this.quantidadeParcelasComponentRef = React.createRef()
    }

    state = {
        dataEntrada: '',
        dataEntradaView: '',
        inputDateErrorClass: '',
        errorDateMessage: '',

        hour: '',
        inputHourErrorClass: '',
        errorHourMessage: '',

        numeroDocumento: '',

        complementoHistorico: '',

        valorTotalNF: null,
        inputValorTotalNFErrorClass: '',
        
        selectAcrescimoDesconto: '',
        
        valorAcrescimoDesconto: null,
        inputValorAcrescimoDescontoErrorClass: '',

        qtdParcelas: '',
        inputParcelasErrorClass: '',
        errorParcelasMessage: 'Informe a quantidade de Parcelas',

        pagamentoList: [],

        fornecedor: {},
        inputFornecedorErrorClass: '',

        qtdItens: '',
        inputQtdItensErrorClass: '',

        transactions: [],

        produtosCompraList: [],

        didUpdated: false,
        forceProductListUpdate: false,
    }

    componentDidMount(){
        this.setStates()
    }
    
    setStates = () => {
        // console.log("this.props.NFToValidate.transactions ", this.props.NFToValidate.transactions)
        this.resetView()
        this.resetData()
        
        if(this.props.insideValidateNFDialog){
            this.setDataEntrada()
        }

        this.setCurrentHour()
        
        this.setTransactions()
        
        this.trataDadosProvisao()
    }

    resetView = () => {
        this.setState({inputDateErrorClass: ""})
        this.setState({errorDateMessage: ""})
        
        this.setState({inputHourErrorClass: ''})
        this.setState({errorHourMessage: ''})

        this.setState({inputValorTotalNFErrorClass: ''})

        this.setState({inputValorAcrescimoDescontoErrorClass: ''})

        this.setState({inputParcelasErrorClass: ''})

        this.state.pagamentoList.forEach(pagamentoItem => {
            pagamentoItem.inputDateErrorClass = ''
            pagamentoItem.inputValorErrorClass = ''
        })

        this.setState({inputFornecedorErrorClass: ''})
        this.setState({inputQtdItensErrorClass: ''})

        this.state.transactions.forEach(transaction => {
            transaction.inputSelectProdutoErrorClass = ""
            transaction.inputTipoProdutoErrorClass = ""
            transaction.inputValorErrorClass = ""
            transaction.inputQuantidadeProdutoErrorClass = ""
        })
    }

    resetData = () => {
        this.setState({dataEntrada: ''})
        this.setState({dataEntradaView: ''})

        this.setState({hour: ''})

        this.setState({selectAcrescimoDesconto: ''})
        this.setState({valorAcrescimoDesconto: null})

        this.setState({qtdParcelas: ''})
        this.setState({pagamentoList: []})

        this.setState({transactions: []})
    }

    setDataEntrada = () => {
        // console.log("this.props.NFToValidate: ", this.props.NFToValidate)
        if(this.props.NFToValidate && this.props.NFToValidate.dataEntradaSemHorario){
            let dataEntradaSemHorario = this.props.NFToValidate.dataEntradaSemHorario
            this.setState({dataEntrada: dataEntradaSemHorario})
            this.setState({dataEntradaView: GeneralServices.convertBrStringToJsDate(dataEntradaSemHorario)})
        }
    }

    setCurrentHour = () => {
        /*
        Pré-preenche hora de entrada com horário atual na tela de validação das NFs
        */
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0'); // Obtém as horas e as converte para string, garantindo que tenham sempre dois dígitos
        const minutes = now.getMinutes().toString().padStart(2, '0');
        let currentStringHour = `${hours}:${minutes}`
        this.setState({hour: currentStringHour})
    }

    setTransactions = () => {
        if(this.props.NFToValidate && this.props.NFToValidate.transactions) {
            let transactionList = []
            this.props.NFToValidate.transactions.forEach(transaction => {
                // console.log("setTransactions: ", transaction)
                transaction.inputErrorClass = ""
                transaction.errorMessage = "Selecione o Tipo do Produto"
                if(!transaction.tipoProduto){
                    transaction.tipoProduto = transaction.productInfo.tipoProduto
                }
                transactionList.push(transaction)
            })
            this.setState({transactions: transactionList})
        }
    }
    
    trataDadosProvisao = () => {
        // console.log("NFToValidate: ", this.props.NFToValidate)
        let provisao = this.props.NFToValidate && this.props.NFToValidate.provisao
        if(provisao){
            provisao.parcelaProvisaoList.forEach(parcela => {
                parcela.dataView = GeneralServices.convertBrStringToJsDate(parcela.data)
            })
        }
    }

    handleDateChange = async (event) => {

        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })

        // if(!this.props.insideValidateNFDialog){
        //     this.setDateInTransactions()
        // }

        // console.log("this.state.dataEntrada: ", this.state.dataEntrada)
        // console.log("this.state.dataEntradaView: ", this.state.dataEntradaView)
    }

    handleHourChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })

        if(!this.props.insideValidateNFDialog){
            this.setDateInTransactions()
        }

        // console.log("hour: ", this.state.hour)
    }

    setDateInTransactions = async () => {
        const date = this.state.dataEntrada ? this.state.dataEntrada : ''
        const hour = this.state.hour ? this.state.hour : ''
        const compositeDate = `${date} - ${hour}`

        let transactions = this.state.transactions

        transactions.forEach(transaction => {
            transaction.dhEmi = compositeDate
            transaction.tipo = ConstantsUtil.entradaLabel
        })

        await this.setState({transactions})

        // console.log("after setDateInTransactions: ", this.state.transactions)
    }

    handleSelectAcrescimoDescontoChange = (event) => {
        this.handleChange(event)
        this.setState({valorAcrescimoDesconto: null})
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleNumeroChange = async (event) => {
        let value = event.target.value
        const name = event.target.name

        if(value && value < 0){
            value*=-1
        }

        this.setState({[name]: value})
        
    }

    handleValorChange = (event) => {
        // console.log("event: ", event)
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        this.setState({ [name]: value })
    }    

    handleSelectionFornecedorChange = async (fornecedor) => {
        await this.setState({fornecedor})

        if(!this.state.qtdItens) {
            this.handleQuantidadeItensChange({target: {value: 1}})
        }

        let transactions = this.state.transactions
        transactions.forEach(transaction => {
            transaction.productInfo = {}
        })
        await this.setState({transactions})
        // console.log("this.state.transactions: ", this.state.transactions)
        this.searchProdutosCompra()
    }

    searchProdutosCompra = () => {
        this.buyProductService.searchBuyProductByFornecedor(this.state.fornecedor.id)
        .then(response => {
            var produtosCompraList = response.data
            this.setState({produtosCompraList})
            this.setState({forceProductListUpdate: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }    

    handleQuantidadeItensChange = async (event) => {
        let value = event.target.value
        if(value && value < 0){
            value*=-1
        }

        const qtdItens = value;
        
        await this.setState({ qtdItens: value })

        var transactions = this.state.transactions
        
        if(qtdItens && qtdItens !== 0) {

            var initialIndex = 0
            var shouldExecuteLoop = true

            if(transactions && transactions.length > 0) {
                if(qtdItens > transactions.length) {
                    initialIndex = transactions.length
                }
                else {
                    transactions = transactions.slice(0, transactions)
                    shouldExecuteLoop = false
                }

            }
            else {
                transactions = []
            }
            if(shouldExecuteLoop){
                for(var i = initialIndex; i < this.state.qtdItens; i++){
                    transactions.push(
                        {
                            dhEmi: i+1,
                            productInfo: {},
                            inputSelectProdutoErrorClass: '',
                            tipoProduto: '',
                            inputTipoProdutoErrorClass: '',
                            quantidade: '',
                            inputQuantidadeProdutoErrorClass: '',
                            valor: null,
                            inputValorProdutoErrorClass: '',
                        }
                    )
                }
            }

        }
        else{
            transactions = []
        }
        
        await this.setState({transactions})
        // console.log('this.state.transactions', this.state.transactions)

    }

    handleSelectionProductChange = (selectedProduct, currentTransaction, index) => {
        // console.log("selectedProduct: ", selectedProduct)
        currentTransaction.productInfo = selectedProduct
        currentTransaction.tipoProduto = selectedProduct.tipoProduto ? selectedProduct.tipoProduto : ""
        let transactions = this.state.transactions
        transactions[index] = currentTransaction
        this.setState({transactions})
    }

    handleTipoProdutoChange = (index, selectedTipoProduto) => {
        
        let transactionList = this.state.transactions
        let transaction = transactionList[index]
        transaction.tipoProduto = selectedTipoProduto
        
        this.state.transactions.forEach(transaction => {
            if(!transaction.tipoProduto){
                transaction.tipoProduto = selectedTipoProduto
            }
        })
        this.setState({transactions: transactionList})
    }


    handleQuantidadeItemLoopChange = async (event, index) => {

        let value = event.target.value
        if(value && value < 0){
            value*=-1
        }
        
        var transactions = this.state.transactions

        var obj = transactions[index]
        obj.quantidade = value

        transactions[index] = obj

        await this.setState({transactions})

        // console.log("this.state.pagamentoList", this.state.pagamentoList)
    }    

    handleValorItemLoopChange = async (event, index) => {

        var value = event.target.value
        // value = GeneralServices.convertJSDateToBrDate(value)
        
        var transactions = this.state.transactions

        var obj = transactions[index]
        obj.valor = value

        transactions[index] = obj

        await this.setState({transactions})

        // console.log("this.state.pagamentoList", this.state.pagamentoList)
    }    

    checkData = () => {

        // console.log("checkData dadosNFComponent transactions: ", this.state.transactions)
        
        this.resetView()

        var check = true

        if(!this.state.dataEntrada){
            this.setState({inputDateErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorDateMessage: ConstantsUtil.informeDataErrorMenssage})
            check = false
        }
        
        if(!GeneralServices.checkValidHour(this.state.hour)){
            this.setState({inputHourErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorHourMessage: "Informe um horário válido"})
            check=false
        }

        if(!this.state.valorTotalNF && !this.props.insideValidateNFDialog){
            this.setState({inputValorTotalNFErrorClass: ConstantsUtil.primereactInputErrorClass})
            check = false
        }

        if(this.state.selectAcrescimoDesconto && !this.state.valorAcrescimoDesconto) {
            this.setState({inputValorAcrescimoDescontoErrorClass: ConstantsUtil.primereactInputErrorClass})
            check = false
        }

        if(GeneralServices.isEmptyObject(this.state.fornecedor) && !this.props.insideValidateNFDialog){
            this.setState({inputFornecedorErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check=false
        }

        if( (!this.state.qtdItens || this.state.qtdItens <= 0) && !this.props.insideValidateNFDialog){
            this.setState({inputQtdItensErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        let valorTotalProdutos = 0
        let checkSomatorio = true
        this.state.transactions.forEach(transaction => {
            if(!transaction.productInfo || JSON.stringify(transaction.productInfo) === '{}') {
                transaction.inputSelectProdutoErrorClass = ConstantsUtil.bootstrapInputErrorClass
                check = false
            }

            if(!transaction.tipoProduto){
                transaction.inputTipoProdutoErrorClass = ConstantsUtil.bootstrapInputErrorClass
                check = false
            }
            
            if(!transaction.quantidade){
                transaction.inputQuantidadeProdutoErrorClass = ConstantsUtil.bootstrapInputErrorClass
                check = false
            }
            
            if(!transaction.valor && !this.props.insideValidateNFDialog){
                transaction.inputValorErrorClass = ConstantsUtil.primereactInputErrorClass
                check = false
            }
            else {
                valorTotalProdutos += transaction.valor
            }

        })

        if( 
            !this.props.insideValidateNFDialog //Não é tela de validação de NFs. Então é tela de lançamento manual de NF.
        ){
            if(
                GeneralServices.formatTo2CasasDecimais(valorTotalProdutos)
                !== GeneralServices.formatTo2CasasDecimais(this.getValorTotal() + this.getRealValorAcrescimoDesconto())
            )
            {
                popUp.warningPopUp("Somatório do valor dos Produtos diferente do Valor Total da NF.")
                checkSomatorio = false
            }
        }

        let checkQuantidadeParcelasComponent = this.quantidadeParcelasComponentRef.current.checkData()

        let resultCheck = check && checkSomatorio && checkQuantidadeParcelasComponent.checkGeral

        let apenasCheckSomatorioFalhou = !resultCheck && (check && checkQuantidadeParcelasComponent.apenasCheckSomatorioFalhou)

        if(!resultCheck && !apenasCheckSomatorioFalhou){
            popUp.warningPopUp("Preencha todas as informações.")
        }
        
        return resultCheck
        // return false
    }

    montaNF = () => {
        this.setDateInTransactions()
        let notaFiscalDTO = new NotaFiscalDTO
        (
            `${this.state.dataEntrada} - ${this.state.hour}`,
            this.state.fornecedor,
            this.state.numeroDocumento,
            this.state.valorTotalNF,
            this.getRealValorAcrescimoDesconto(),
            ConstantsUtil.entradaLabel,
            55,
            this.state.transactions,
            this.quantidadeParcelasComponentRef.current.getResultObject(),
            this.state.complementoHistorico
        )

        return notaFiscalDTO;
    }

    getColMdClasses = () => {
        if(this.props.insideValidateNFDialog){
            return {
                dataEntrada: 'col-md-6',
                horarioEntrada: 'col-md-6',
            }
        }
        else{
            return {
                dataEntrada: 'col-md-2',
                horarioEntrada: 'col-md-2',
            }
        }
    }

    getValorTotal = () => {
        if(this.props.insideValidateNFDialog){
            return this.props.NFToValidate ? this.props.NFToValidate.valorTotal : null
        }
        return this.state.valorTotalNF
    }

    getRealValorAcrescimoDesconto = () => {
        if(this.state.selectAcrescimoDesconto) {
            if(this.state.selectAcrescimoDesconto === ConstantsUtil.nfDescontoValue) {
                return this.state.valorAcrescimoDesconto*-1
            }
            return this.state.valorAcrescimoDesconto
        }
        return 0
    }

    getValorDescontoBonificacao = () => {
        let valorDescontoBonificacao = 0.0
        this.state.transactions.forEach(transaction => {
            if(transaction.tipoProduto && (transaction.tipoProduto.chave === ConstantsUtil.tipoProdutoBonificacaoChave) ){
                valorDescontoBonificacao += transaction.valor
            }
        })
        return GeneralServices.formatTo2CasasDecimais(valorDescontoBonificacao)
    }

    isNFDevolucao = () => {
        return (this.props.NFToValidate && this.props.NFToValidate.tipo === ConstantsUtil.tipoNFDevolucaoEntradaLabel)
    }

    render() {

        const renderNumeroAndComplementoHistoricoAndValorTotalNF = () => {
            if(!this.props.insideValidateNFDialog) {
                return (
                    <>
                    <div className="col-md-12">
                    <div className="row">

                    <div className="col-md-2">
                        <label htmlFor="date">N° do Documento</label>
                        <input
                            type="number"
                            className={"form-control " }
                            value = {this.state.numeroDocumento}
                            name="numeroDocumento"
                            onChange={this.handleNumeroChange}
                            id="nomeTipoDeRecebimento"
                            // disabled={this.props.provisao && this.props.provisao.notaFiscal}
                        />
                    </div>

                    <div className="col-md-3">
                    <label htmlFor="icon">Complemento de Histórico (Opcional)</label>
                        <input
                            type="text"
                            className={"form-control "}
                            value = {this.state.complementoHistorico}
                            name="complementoHistorico"
                            onChange={this.handleChange}
                        />
                    </div>


                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-2">
                            <label>Valor Total</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <InputNumber 
                                ref={this.inputValorRef}
                                className={this.state.inputValorTotalNFErrorClass}
                                name="valorTotalNF"
                                value={this.state.valorTotalNF}
                                onValueChange={this.handleValorChange}
                                // onKeyDown={this.handleKeyPress}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}
                                // disabled={this.props.provisao && this.props.provisao.notaFiscal}
                            />
                        </div>
                        {renderAcrescimoDescontoComponent()}                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <small id="valorError" className="p-error">{this.state.inputValorTotalNFErrorClass ? 'Informe o valor' : ''}</small>
                        </div>
                    </div>              
                    </div>
                    <br />              
                    </>
                )
            }
        }

        const renderAcrescimoDescontoComponent = () => {
            if(!this.isNFDevolucao())
            return (
                <>
                    <div className='col-md-2'>
                    <SelectMenu
                        id="selectAcrescimoDesconto"
                        className={"form-control " }
                        // ref={this.tipoDeLancamentoInputRef}
                        name="selectAcrescimoDesconto"
                        list={ConstantsUtil.acrescimoDescontoSelectList}
                        value={this.state.selectAcrescimoDesconto}
                        onChange={this.handleSelectAcrescimoDescontoChange}
                        // disabled={this.props.fromOFX}
                    /> 
                    </div>
                    {renderValorAcrescimoDesconto()}                
            </>
            )
        }

        const renderValorTotalNFInfo = () => {
            if(this.props.insideValidateNFDialog) {
                return (
                    <>
                        <div className='col-md-12'>
                        <div className='row'>
                        <div className='col-md-3'>
                            <Button
                                label={`Valor Total da NF: ${GeneralServices.showFormattedIfNotNull( Math.abs(this.props.NFToValidate.valorTotal), true)}`}
                                style={{maxHeight: '1.5rem', width: '15rem'}}
                            />
                            <TooltipButton
                                tooltip = "Copiar Valor Total"
                                icon="pi pi-copy"
                                className="p-button-rounded p-button-primary p-mr-2 right-button"
                                style={ {maxHeight: '25px', maxWidth: '25px'} }
                                onClick={() => {
                                    navigator.clipboard.writeText(this.props.NFToValidate.valorTotal.toString().replace(".", ","))
                                    popUp.infoPopUp(`Valor ${GeneralServices.showFormattedIfNotNull(this.props.NFToValidate.valorTotal, true)} copiado!`)
                                }}
                            />
                        </div>
                        {renderAcrescimoDescontoComponent()}
                        {renderValorDescontoBonificacao()}
                        {renderValorDescontoDevolucao()}
                        </div>
                        </div>
                    </>
                )
            }
        }

        const renderValorAcrescimoDesconto = () => {
            if(this.state.selectAcrescimoDesconto) {
                return (
                    <>
                    <div className='col-md-2'>
                    <InputNumber 
                        className={this.state.inputValorAcrescimoDescontoErrorClass}
                        name="valorAcrescimoDesconto"
                        value={this.state.valorAcrescimoDesconto}
                        onValueChange={this.handleNumeroChange}
                        // onKeyDown={this.handleKeyPress}
                        mode="decimal" locale="pt-BR" minFractionDigits={2} maxFractionDigits={2}
                        placeholder='Valor'
                    />
                    <div className="row">
                        <div className="col-md-12">
                        <small id="valorError" className="p-error">{this.state.inputValorAcrescimoDescontoErrorClass ? `${ConstantsUtil.informeValorErrorMenssage} do Acréscimo/Desconto`: ""}</small>
                        </div>
                    </div>
                    </div>

                    </>
                )
            }
        }

        const renderValorDescontoBonificacao = () => {
            if(!this.isNFDevolucao())
            return (
                <div className='col-md-2'>
                <InputNumber 
                    value={this.getValorDescontoBonificacao()}
                    mode="decimal" locale="pt-BR" minFractionDigits={2} maxFractionDigits={2}
                    placeholder='Valor'
                    disabled
                />
                </div>                
            )
        }

        const renderValorDescontoDevolucao = () => {
            if(!this.isNFDevolucao())
            return (
                <div className='col-md-2'>
                <InputNumber 
                    name="valorDescontoDevolucao"
                    value={this.props.NFToValidate.valorDescontoPorDevolucao}
                    mode="decimal" locale="pt-BR" minFractionDigits={2} maxFractionDigits={2}
                    placeholder='Valor'
                    disabled
                />
                </div>                
            )
        }

        const renderPagamentoLabel = () => {
            if(this.props.insideValidateNFDialog){
                return (
                    <div className='row'>
                    <br />
                    <div className='col-md-2'>
                        <h5>Pagamento</h5>
                    </div>
                    <div className='col-md-3'>
                    </div>
                    {renderDemaisLabels()}
                    </div>
                )
            }
        }

        const renderDemaisLabels = () => {
            if(this.state.selectAcrescimoDesconto){
                return (
                    <>
                        <div className='col-md-2'>
                            <label>Valor do Acréscimo/Desconto</label>
                        </div>
                        {renderLabelsBonificacaoDevolucao()}  
                    </>
                )
            }
            else{
                return (
                    <>
                        {renderLabelsBonificacaoDevolucao()}
                    </>                
                )
            }
        }

        const renderLabelsBonificacaoDevolucao = () => {
            if(!this.isNFDevolucao())
            return (
                <>
                    <div className='col-md-2'>
                        <label>Valor da Bonificação</label>
                        <TooltipButton
                            tooltip={
                                "Somatório do valor dos produtos marcados como \"Bonificação\" abaixo."
                            }
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'top'
                            }}
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            style ={{maxHeight: '25px', maxWidth: '25px', marginLeft: '0.5em', marginBottom: '0.3em'}}
                            className="p-button-rounded p-mr-2"
                        />                        
                    </div>
                    <div className='col-md-2'>
                        <label>Valor da Devolução</label>
                        <TooltipButton
                            tooltip={
                                "Valor da Nota Fiscal de Devolução" +
                                `${this.props.NFToValidate.notaFiscalDevolucao ? ` (Número ${this.props.NFToValidate.notaFiscalDevolucao.numero})` : ", se houver"}.`
                            }
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'top'
                            }}
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            style ={{maxHeight: '25px', maxWidth: '25px', marginLeft: '0.5em', marginBottom: '0.3em'}}
                            className="p-button-rounded p-mr-2"
                        />                          
                    </div>
                </>
            )   
        }        

        const renderQuantidadeParcelasComponent = () => {
            return (
                <QuantidadeParcelasComponent
                    ref={this.quantidadeParcelasComponentRef}
                    provisao={this.props.NFToValidate ? this.props.NFToValidate.provisao : null}
                    NF={this.props.NFToValidate ? this.props.NFToValidate : null}
                    valorTotalProvisao={this.getValorTotal()}
                    valorAcrescimoDesconto={this.getRealValorAcrescimoDesconto()}
                    valorDescontoBonificacao={this.getValorDescontoBonificacao()}
                    valorDescontoDevolucao={this.props.NFToValidate ? this.props.NFToValidate.valorDescontoPorDevolucao : 0.0}
                    insideDialog={this.props.insideValidateNFDialog}
                />
            )
        }

        const renderPagamento = () => {
            if(this.props.insideValidateNFDialog){
                return renderQuantidadeParcelasComponent()
            }
            return (
                <div className='col-md-12'>
                    {renderQuantidadeParcelasComponent()}
                </div>
            )

        }

        const renderInputChooseFornecedor = () => {
            if(!this.props.insideValidateNFDialog){
                return (
                    <>
                    <div className = "col-md-3">
                        <InputChooseFornecedor
                            noHTMLFormat
                            selectedFornecedorLabel={this.state.fornecedor.nome}
                            handleSelectionFornecedorChange={this.handleSelectionFornecedorChange}
                            inputFornecedorErrorClass={this.state.inputFornecedorErrorClass}
                        />
                    </div>
                    <br />
                    </>
                )
            }            
        }

        const renderQuantidadeItens = () => {
            if(GeneralServices.isEmptyObject(this.state.fornecedor)){
                return;
            }
            let colMd = 3
            if(this.props.insideValidateNFDialog){
                colMd = 5
            }            
            return (
                // <div className='row'>
                <div className = {`col-md-${colMd}`}>
                <FormGroup label = "Quantidade de Itens">
                    <input 
                        type="number"
                        id="inputQtdItens"
                        className={"form-control " + this.state.inputQtdItensErrorClass}
                        name="qtdItens"
                        value = {this.state.qtdItens}
                        onChange={this.handleQuantidadeItensChange}
                        placeholder="Digite a quantidade de Itens"
                            />
                    <div className="invalid-feedback">Informe a quantidade de Itens</div>
                </FormGroup>
            </div>
            )
        }

        return (

            <>
                <>
                <div className='col-md-12'> 
                <div className='row'>

                <div className = {this.getColMdClasses().dataEntrada}>
                <CustomCalendar
                    label="Data de Entrada"
                    ref={this.dateInputRef}
                    id="dataLancamentoFechamentoCaixa"
                    htmlFor="icon"
                    name="dataEntrada"
                    value={this.state.dataEntradaView}
                    noHTMLFormat
                    onChange={this.handleDateChange}
                    inputDateErrorClass={this.state.inputDateErrorClass}
                    errorDateMessage={this.state.errorDateMessage}
                />
                </div>            

                <div className = {this.getColMdClasses().horarioEntrada}>
                    <label htmlFor="hour">Horário de Entrada</label>
                        <InputMask
                            id="hour"
                            name="hour"
                            className={"form-control " + this.state.inputHourErrorClass }
                            mask="99:99"
                            value={this.state.hour}
                            placeholder="hh:mm"
                            onChange={this.handleHourChange}
                            // onKeyPress={this.handleKeypress}
                            // disabled={!this.state.habilitaBusca}
                        />
                        <small id="hourErrorNotice" className="p-error">{this.state.errorHourMessage}</small>
                </div>            
                </div>
                </div>

                <br/>

                {renderNumeroAndComplementoHistoricoAndValorTotalNF()}

                {renderPagamentoLabel()}

                {renderValorTotalNFInfo()}

                {renderPagamento()}

                <br/>

                {renderInputChooseFornecedor()}

                {renderQuantidadeItens()}

                {/* {renderItensLabel()} */}
                {/* {renderItens()} */}
                <ItensNFComponent
                    fornecedor={this.state.fornecedor}
                    produtosCompraList={this.state.produtosCompraList}
                    searchProdutosCompra={this.searchProdutosCompra}
                    transactions={this.state.transactions}
                    insideValidateNFDialog={this.props.insideValidateNFDialog}
                    forceProductListUpdate={this.state.forceProductListUpdate}
                    doneForceUpadte={() => this.setState({forceProductListUpdate: false})}
                    handleSelectionProductChange={this.handleSelectionProductChange}
                    handleTipoProdutoChange={this.handleTipoProdutoChange}
                    handleQuantidadeItemLoopChange={this.handleQuantidadeItemLoopChange}
                    handleValorItemLoopChange={this.handleValorItemLoopChange}
                />
                </>
            </>
      
        )
    }


}

export default DadosNFComponent