import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../../components/card'
import { AuthContext } from '../../../main/authProvider'
import GeneralServices from '../../../app/service/generalServices'
import { Button } from 'primereact/button'

import * as popUp from '../../../components/toastr'
import ConstantsUtil from '../../../context/constantsUtil'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import HandleErrorService from '../../../app/service/handleErrorService'
// import InputChooseTipoDePagamento from '../../../components/contasAPagar/inputChooseTipoDePagamento'
import FichaDePagamentoService from '../../../app/service/contasAPagar/fichaDePagamentoService'
import FichaDePagamentoTable from '../../../components/contasAPagar/fichaDePagamento/fichaPagamentoTable'


class FichaDePagamento extends React.Component {

    constructor(){
        super()
        this.fichaDePagamentoService = new FichaDePagamentoService()
    }


    state = {

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,

        initialDate: '',
        initialDateView: '',
        
        finalDate: '',
        finalDateView: '',

        // selectedTipoDePagamento: '',
        // selectedTipoDePagamentoLabel: '',
        // inputTipoDePagamentoErrorClass: '',

        loadingTable: false,
        isSearching: false,
        isFinishedSearch: false,

        habilitaBusca: true,

        fichaDePagamento: {},

    }

    handleFilterDateChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    // handleSelectionTipoDePagamentoChange = async (tipoDePagamento, label) => {
    //     await this.setState({selectedTipoDePagamento: tipoDePagamento})
    //     await this.setState({selectedTipoDePagamentoLabel: label})
    // }

    handleDateChange = async (initialDate, finalDate) => {

        await this.setState({initialDate})
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    voltar = () => {
        this.setState({searchProvisaoResult: ''})
        this.toggleButton()
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    resetView = () => {

        this.setState({inputFilterTypeErrorClass: ''})

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        // this.setState({inputTipoDePagamentoErrorClass: ''})
        
    }

    checkData = () => {
        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }

        // if(!this.state.selectedTipoDePagamento){
        //     this.setState({inputTipoDePagamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
        //     check=false
        // }

        return check;
    }    

    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            this.search()
        }
    }

    search = () => {
        this.setState({loadingTable: true})
        this.setState({isFinishedSearch: false})
        this.setState({isSearching: true})

        this.fichaDePagamentoService.generate(this.state.initialDate, this.state.finalDate)
        .then(response => {
            // console.log("response: ", response.data)
            this.setState({fichaDePagamento: response.data})
            this.setState({habilitaBusca: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTable: false})
            this.setState({isFinishedSearch: true})
            this.setState({isSearching: false})
        })
    }




    render() {

        const renderButtons = () => {

            if(this.state.isSearching){
                return (
                    <Button 
                        label="Buscando ..."
                        icon="pi pi-search"
                        disabled
                    />
                )
            }

            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.voltar}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        const renderTable = () => {
            if(!this.state.habilitaBusca){
                return (
                    <FichaDePagamentoTable
                        fichaDePagamento={this.state.fichaDePagamento}
                        search={this.search}
                        loading={this.state.loadingTable}
    
                    />
                )
            }
        }


        return (
            <div className="bs-docs-section" >

                <Card title = "Ficha de Pagamento">
                    <div className='col-md-12'>
                    <div className='row'>
                        <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            label="Escolher período"
                            handleFilterChange={this.handleFilterDateChange}
                            handleDateChange={this.handleDateChange}
                            missingFilterType={this.state.missingFilterType}
                            missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                        />
                        {/* <InputChooseTipoDePagamento
                            selectedTipoDePagamentoLabel={this.state.selectedTipoDePagamentoLabel}
                            handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                            inputTipoDePagamentoErrorClass={this.state.inputTipoDePagamentoErrorClass}
                            disabled={!this.state.habilitaBusca}
                            fichaDePagamento
                        /> */}
                    </div>

                    <br />
                    
                    {renderButtons()}

                    <br />
                    <br />

                    {renderTable()}

                    </div>
                </Card>


            </div>
        )
    }


}


FichaDePagamento.contextType = AuthContext

export default withRouter(FichaDePagamento)