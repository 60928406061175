import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactLoading from "react-loading"
import { AuthContext } from '../../../main/authProvider'
import NFService from '../../../app/service/estoque/NFService'
import HandleErrorService from '../../../app/service/handleErrorService'
import GeneralServices from '../../../app/service/generalServices'
import * as popUp from '../../../components/toastr'
import ConstantsUtil from '../../../context/constantsUtil'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import Card from '../../../components/card'
import { Button } from 'primereact/button'

class SyncNFsVenda extends React.Component {

    constructor(){
        super()
        this.nfService = new NFService()
        this.generalServices = new GeneralServices()
    }

    state = {
        
        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',
        
        habilitaBusca: true,
        loading: false,

    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        // console.log("handleDateChange initial: ", this.state.initialDate)
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})
        // console.log("handleDateChange initialView: ", this.state.initialDateView)

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    resetView = () => {

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputEmpresasErrorClass: ''})
        this.setState({errorEmpresasErrorMessage: ''})

    }
    
    checkData = () => {

        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        return check
    }
    

    callSync = () => {

        this.resetView()

        if(this.checkData()){
            this.syncNFsVenda()
        }
        else{
        }
    }

    syncNFsVenda = () => {
        this.setState({habilitaBusca: false})
        this.nfService.syncNFsVenda(this.state.initialDate, this.state.finalDate)
        .then(async response => {
            popUp.successPopUp("Sincronização realizada com sucesso!")
            // await this.generalServices.sleep(1000)
            // window.location = ConstantsUtil.homePathname

        })
        .catch(async error => {
            console.log(error)
            HandleErrorService.handleError(error)
            // await this.generalServices.sleep(1000)
            // window.location = ConstantsUtil.homePathname
        })
        .finally(() => this.setState({habilitaBusca: true}))
    }

    getDataInicial = () => {
        let dataInicial = new Date()
        dataInicial.setDate(dataInicial.getDate() -7)
        // dataInicial.setHours(0, 0, 0, 0);
        // console.log("dataInicial: ", dataInicial)
        return dataInicial;
    }

    render(){

        const renderCalendarFilters = () => {
            return (
                <>
                <div className = "col-md-12">
                    <div className="row">
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        // defaultValue={ConstantsUtil.calendarPersonalizadoFilterLabel}
                        handleFilterChange={this.handleDateFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                        preSetFilterType={ConstantsUtil.calendarPersonalizadoFilterLabel}
                        preSetDataInicial={this.getDataInicial()}
                        preSetDataFinal={new Date()}
                        enableChangeFilterType
                    />

                    <div
                        style={{
                            marginTop: '0.3rem', marginLeft: '0.5rem', marginRight: '0.5rem'
                        }}
                        className = "col-md-1.5"
                    >
                    </div>

                    </div>
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Sincronizar"
                        className='small-button'
                        icon="pi pi-link"
                        onClick = {this.callSync}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Sincronizando..."
                        icon="pi pi-undo"
                        className="small-button"
                        disabled
                        // onClick = {this.toggleButton}
                        // onClick = {() => this.recebimentoRef.current.exportExcel()}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return(
            <div className="bs-docs-section " >
                <Card title = "Sincronização de NFs de Venda">
                    <div className = "col-md-12">

                    <div className="row">

                        {renderCalendarFilters()}

                    </div>  

                    <div
                        style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
                    >
                        {renderButtons()}
                    </div>
                    
                    </div> {/* col-md-12 */}

                </Card>
                <div className="d-flex "/>
            </div>

        )
    }


}

SyncNFsVenda.contextType = AuthContext

export default withRouter(SyncNFsVenda)