import React from 'react'
import Card from '../../../components/card'
import { withRouter } from 'react-router-dom'

import * as popUp from '../../../components/toastr'

import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { addLocale } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { AuthContext } from '../../../main/authProvider'
import HandleErrorService from '../../../app/service/handleErrorService'
import GeneralServices from '../../../app/service/generalServices'
import LancamentoFechamentoCaixaService from '../../../app/service/contasAReceber/lancamentoFechamentoCaixaService'
import PDVLancamentoService from '../../../app/service/contasAReceber/pdvLancamentoService'
import SelectMenu from '../../../components/selectMenu'
import PDVCaixaService from '../../../app/service/contasAReceber/pdvCaixaService'
import CustomCalendar from '../../../components/calendar/customCalendar'
import PDVCaixaInput from '../../../components/pdvCaixa/pdvCaixaInput'
import ConstantsUtil from '../../../context/constantsUtil'
import CustomHighlighter from '../../../components/customHighlighter'
import TooltipButton from '../../../components/tooltipButton'

class LancarFechamentoDeCaixa extends React.Component {


    constructor(){
        super();
        this.lancamentoFechamentoCaixaService = new LancamentoFechamentoCaixaService();
        this.pdvCaixaService = new PDVCaixaService()
        this.pdvLancamentoService = new PDVLancamentoService();
        this.generalServices = new GeneralServices();
    }

    state = {

        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        lancamentosFromServer: [], //não deve ser alterado pelo front
        lancamentos: null,
        isDoingLancamentos: false,
        isSavingLancamentos: false,
        savedCompleted: false,
        loading: false,
        totalLancado: 0,

        pdvLancamentoFromServer: null,
        pdvLancamento: null, //ponto de venda

        pdvCaixaList: [],

        selectedPdvCaixaId: '',
        inputPdvCaixaErrorClass: '',

        selectedTurno: '',
        inputTurnoErrorClass: '',

        searchText: '',

        // reprocessing: false,

    }

    componentDidMount(){
        this.searchPdvCaixa()
    }

    searchPdvCaixa = () => {
        this.pdvCaixaService.search()
        .then(response => {
            this.setState({pdvCaixaList: this.lancamentoFechamentoCaixaService.formatArray(response.data) })
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }
    
    handlePdvLancamentoChange = async (event) => {
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        await this.setState({ [name]: value })
    }

    handleDateChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
        
    }

    handleTableChange = async (event, rowData) => {
        var array = this.state.lancamentos
        var lancamento = array[array.indexOf(rowData)]
        var valor = event.value

        if(isNaN(valor)){ //acontece quando usuário apaga um valor que havia inicialmente
            valor = 0
        }
        valor = parseFloat(valor)

        if(valor === 0){
            valor = null
        }

        //Por referência, alterar o obj lancamento já vai alterar dentro de array
        lancamento.valor = valor
        lancamento.dataLancamento = this.state.dataLancamento
        var valorOriginal = this.state.lancamentosFromServer.find(element => element.idTipoDeRecebimento === lancamento.idTipoDeRecebimento).valor
        lancamento.lancamentoJaRealizado = valor !== valorOriginal
        
        await this.setState({lancamentos: array})
        this.calculateTotalLancado()
    }

    filterLancamentos = () => {
        if(this.state.searchText){
            return this.state.lancamentos.filter(lancamento => GeneralServices.includeString
                (
                    lancamento.nomeTipoDeRecebimento, this.state.searchText
                )
            )
        }

        return this.state.lancamentos
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    searchLancamentoTipoDeRecebimento = async () => {

        this.setState({loading: true})

        this.lancamentoFechamentoCaixaService.getLancamentos(this.state.dataLancamento, this.state.selectedPdvCaixaId, this.state.selectedTurno)
        .then(async response => {
            await this.setState({lancamentos: response.data})
            await this.setState({lancamentosFromServer: JSON.parse(JSON.stringify(response.data))}) //copiar sem referência
            this.calculateTotalLancado()
            this.setState({isSavingLancamentos: false})

            // this.setState({loading: true})
            // await this.generalServices.sleep(500)
            // this.setState({loading: false})

        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingLancamentos: false})
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    searchPDVLancamento = async () => {
        this.pdvLancamentoService.getPDV(this.state.dataLancamento, this.state.selectedPdvCaixaId, this.state.selectedTurno)
        .then(async response => {
            var pdvLancamentoFromServer =  JSON.parse(JSON.stringify(response.data))
            if(pdvLancamentoFromServer.lancamentoJaRealizado){
                this.setState({pdvLancamentoFromServer: JSON.parse(JSON.stringify(pdvLancamentoFromServer))})
                const valor = pdvLancamentoFromServer.valor !== 0 ? pdvLancamentoFromServer.valor : null
                this.setState({pdvLancamento: valor})
            }
            else {
                this.setState({pdvLancamentoFromServer: {lancamentoJaRealizado: false}})
            }
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingLancamentos: false})
        })
    }

    handleChange =  async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
    }

    search = async () => {
        await this.searchPDVLancamento()
        this.searchLancamentoTipoDeRecebimento()
    }

    resetView = () => {
        this.setState({inputDateErrorClass: ''})
        this.setState({errorDateMessage: ''})

        this.setState({inputPdvCaixaErrorClass: ''})
        this.setState({inputTurnoErrorClass: ''})


        this.setState({savedCompleted: false})
        this.setState({pdvLancamento: null})
    }

    checkData = () => {
        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: "p-invalid"})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }
        if(!this.state.selectedPdvCaixaId){
            this.setState({inputPdvCaixaErrorClass: "is-invalid"})
            check = false
        }

        if(!this.state.selectedTurno){
            this.setState({inputTurnoErrorClass: "is-invalid"})
            check = false
        }

        return check
    }

    callLancamento = async () => {
        this.resetView()
        if(this.checkData()){
            await this.search()
            this.setState({isDoingLancamentos: true})
        }
    }


    cancelLancamentoLine = (lancamento) => {
        var auxArray = this.state.lancamentos
        const index = auxArray.indexOf(lancamento)

        var originalLancamento = this.state.lancamentosFromServer.find(element => element.idTipoDeRecebimento === lancamento.idTipoDeRecebimento)
        auxArray[index] = JSON.parse(JSON.stringify(originalLancamento))
        
        this.setState({lancamentos: auxArray})

        //voltar o total lançado ao valor inicial também
        this.calculateTotalLancado()

    }

    cancelLacamentos = () => {
        this.setState({isDoingLancamentos: false})
        this.setState({lancamentos: null})
        this.setState({pdvLancamento: ""})
        this.setState({searchText: ''})
    }

    saveLancamentos = () => {
        this.setState({isSavingLancamentos: true})
        this.lancamentoFechamentoCaixaService.save({
            lancamentosList: this.state.lancamentos,
            //pdvCaixaId e turno são os mesmos para todos os lançamentos,
            //uma vez que o usuário define caixa e turno que está lançando.
            pdvCaixaId: this.state.selectedPdvCaixaId,
            turno: this.state.selectedTurno
        }).then(async response => {
            popUp.successPopUp("Lançamentos salvos com sucesso.")
            this.setState({isDoingLancamentos: false})
            this.setState({lancamentos: null})
            await this.search()
            this.setState({savedCompleted: true})
            // this.setState({dataLancamento: ''})
            // this.setState({dataLancamentoView: ''})
            this.setState({selectedPdvCaixaId: ''})
            this.setState({selectedTurno: ''})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingLancamentos: false})
        })
    }

    savePdvLancamento = () => {
        if(this.state.pdvLancamentoFromServer.lancamentoJaRealizado || this.state.pdvLancamento){
            this.pdvLancamentoService.save({
                dataLancamento: this.state.dataLancamento,
                pdvCaixaId: this.state.selectedPdvCaixaId,
                turno: this.state.selectedTurno,
                valor: this.state.pdvLancamento,
                lancamentoJaRealizado: true
            }).then(async response => {
                this.setState({pdvLancamento: ""})
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({isSavingLancamentos: false})
            })
        }
    }

    save = () => {
        this.savePdvLancamento()
        this.saveLancamentos()
    }

    calculateTotalLancado = () => {
        if(!this.state.lancamentos)
        return
        var sum = 0
        this.state.lancamentos.forEach(element => {

        if( !isNaN(element.valor) && element.valor){
            /*
                Não deve somar se for NaN, se for 0 ou se não tiver valor (null).
                OBS: Fica NaN qnd o usuário apaga um valor já lançado, por exemplo.
            */
            sum += element.valor
        }

        })
        this.setState({totalLancado: sum})
    }

    calculateDiferenca = () => {
        var pdvLancamento = this.state.pdvLancamento!==null ? this.state.pdvLancamento : 0
        var dif = this.state.totalLancado - pdvLancamento
        return dif
    }

    onLostFocus = () => {
        // this.setState({searchText: ''})
    }

    limparPesquisa = () => {
        this.setState({searchText: ''})
    }

    onKeyDown = async (event) => {
        /*
            Essa função é chamada no mesmo instante que a handleValorChange.
            Precisa esperar um tempo de forma que o valor seja alterado.
        */
        await this.generalServices.sleep(1)
        
        if (event.key === "Enter") {
            this.onLostFocus();
        }
        
    }

    render() {

        addLocale('pt-BR', {
            firstDayOfWeek: 0,
            dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
            dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
            dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
            today: 'Hoje',
            clear: 'Limpar'
        })

        // const valueEditor = (options) => {
        //     return <InputNumber
        //                 id={options.rowData.idTipoDeRecebimento}
        //                 name={options.rowData.nomeTipoDeRecebimento}
        //                 placeholder='Informe o valor'
        //                 value={options.rowData.valor}
        //                 mode="decimal"
        //                 minFractionDigits={2} maxFracionDigits={2}
        //                 onValueChange={(event) => this.handleTableChange(event, options.rowData)}
        //             />
        // }

        const renderLancamentosTable = () => {
            if(this.state.isDoingLancamentos)
                return (
                    <div >
                    <DataTable  
                        // value={this.state.lancamentos}
                        value={this.filterLancamentos()}
                        // size="small"
                        className="p-datatable-sm small-font"
                        editMode="cell"
                        dataKey="id"
                        loading={this.state.loading}
                        // onRowEditComplete={this.onRowEditComplete1}
                        responsiveLayout="scroll"
                        emptyMessage="Nenhum tipo de recebimento encontrado."
                    >
                
                    <Column
                        field="nomeTipoDeRecebimento" header="Nome"
                        body={rowData => 
                            <CustomHighlighter
                                searchWords={[this.state.searchText]}
                                textToHighlight={rowData.nomeTipoDeRecebimento}
                            />
                        } 
                    />
                    
                    <Column 
                        field="valor" header="Valor"
                        body={rowData => GeneralServices.valueBodyTemplate(rowData.valor)}
                        // editor={(options) => valueEditor(options)}
                        editor={(options) => {
                            return GeneralServices.inputTableNumber(
                                options.rowData.id,
                                options.rowData.nomeTipoDeRecebimento,
                                options.rowData.valor,
                                options.rowData,
                                this.handleTableChange,
                                "Valor",
                                false,
                                this.onLostFocus,
                                this.onKeyDown
                            )
                        }}
                    />

                    <Column
                        header="Cancelar"
                        body={cancelLancamnetoTemplate}
                    />
                    
                </DataTable>
                </div>
                )
        }

        const cancelLancamnetoTemplate = (lancamento) => {
            if(lancamento.lancamentoJaRealizado){
                return (
                    <React.Fragment>
                        <TooltipButton 
                            tooltip='Voltar ao valor inicial'
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-mr-2"
                            onClick={ () => this.cancelLancamentoLine(lancamento) }
                        />
                    </React.Fragment>
                )
                }
        }

        const renderButtons = () => {
            if(!this.state.isDoingLancamentos || this.state.savedCompleted){
                return (
                    <>
                    
                    <Button 
                    label="Fazer Lançamentos"
                    icon="pi pi-pencil"
                    onClick = {this.callLancamento}
                    />

                    <br />

                    <br />

                    </>
                )
            } else {
                return(
                    <div>
                    <Button 
                        label="Salvar Lançamentos"
                        icon="pi pi-save"
                        onClick = {this.save}
                        disabled = {this.state.isSavingLancamentos}
                    />

                    <Button 
                    label="Voltar"
                    icon="pi pi-undo"
                    className="p-button-danger right-button"
                    onClick = {this.cancelLacamentos}
                    disabled = {this.state.isSavingLancamentos}
                    />
                    </div>
                )
                
            }
        }

        const renderResumoTotal = () => {
            if(this.state.isDoingLancamentos)
            return(
                <>
                <div className = "card-header">
                <div className = "row">
                    {/* <div className="col-md-1">
                    </div> */}
                    <div className="col-md-3">
                            <Button 
                                label={'Total Lançado: ' + GeneralServices.valueBodyTemplate(this.state.totalLancado) }
                                className=" small-font p-button-success"
                                // style={ {maxHeight: '25px' } }
                                />
                    </div>
                    <div className="col-md-4">
                        <div className='row'>
                        {/* <div className="p-inputgroup"> */}
                        <div className="input-group mb-3">
                        <span className="p-inputgroup-addon medium-font-size">PDV:</span>
                        {/* <input
                            id="pdvLancamento"
                            type="text"
                            value={this.state.pdvLancamento}
                            name="pdvLancamento"
                            onChange={this.handlePdvLancamentoChange}
                            placeholder="Informe o PDV"
                        /> */}
                        <InputNumber 
                            name="pdvLancamento"
                            inputId="currency-us"
                            // className="medium-font-size"
                            value={this.state.pdvLancamento}
                            onValueChange={this.handlePdvLancamentoChange}
                            mode="currency" currency="BRL" locale="pt-BR" 
                        />
                        <Button
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            className="p-button-rounded p-mr-2 medium-font"
                            style={ {display: this.state.selectedTipoDeRecebimentoValue === '' ? 'none' : '',
                                marginLeft: '3px', maxHeight: '25px', maxWidth: '25px'}}
                            // style={ {maxHeight: '25px', maxWidth: '25px'} }
                            display={false}
                            tooltip={"Ponto De Venda." + "\n" + "O valor total informado pelo frente de loja."}
                            tooltipOptions={{position: 'top'}}
                        />
                        </div>
                    </div>
                    </div>
                    {/* <div className="col-md-1">
                        
                    </div> */}
                    {/* <div className="col-md-1">
                    </div> */}
                    <div className="col-md-3">
                        <Button 
                            label={'Diferença: ' + GeneralServices.valueBodyTemplate(this.calculateDiferenca()) }
                            className="p-button-primary small-font" 
                            // style={ {maxHeight: '25px' } }
                        />
                    </div>
                    </div>
                    </div>


                <br />
                </>
            )
        }

        const renderPesquisaValorButton = () => {
            return (
                <div className='row'>
                <div className='col-md-8'>
                <input style={{color: '#000'}}
                    // autoFocus
                    className={"form-control " }
                    type="text"
                    id="searchText"
                    name="searchText"
                    value={this.state.searchText}
                    placeholder="Pesquise pelo nome"
                    onChange = {this.handleChange}
                    // onKeyPress={this.handleKeyPress}
                    disabled={!this.state.isDoingLancamentos}
                />
                </div>
                <div className='col-md-2'>
                    <TooltipButton
                        tooltip="Limpar Pesquisa"
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-mr-2"
                        onClick={this.limparPesquisa}
                        disabled={!this.state.isDoingLancamentos}
                    />
                </div>
                <div className='col-md-2'>
                </div>
                </div>
            )
        }

    return (
        <div className="bs-docs-section" >
            <Card title = "Lançar Fechamento de Caixa"
                rightComponent={renderPesquisaValorButton()}
            >
                <div className="col-md-12">
                    
                    <div className = "row">
                        
                        <div className="col-md-2">

                        <div className="row">
                            
                        {/* <div className="col-md-4"> */}
                            {/* <span className="p-float-label"> */}
                            {/* <label htmlFor="icon">Data do Lançamento</label> */}
                            <CustomCalendar
                                id="dataLancamentoFechamentoCaixa"
                                htmlFor="icon"
                                label="Data do Lançamento"
                                inputDateErrorClass={this.state.inputDateErrorClass}
                                value={this.state.dataLancamentoView}
                                name="dataLancamento"
                                onChange={this.handleDateChange}
                                disabled={this.state.isDoingLancamentos}
                                errorDateMessage={this.state.errorDateMessage}
                                noHTMLFormat
                                // style={{minWidth:'490px'}}
                                // showIcon
                                // showButtonBar
                                // locale="pt-BR"
                                // dateFormat="dd/mm/yy"
                                // yearRange={GeneralServices.yearRange()}
                                // monthNavigator yearNavigator
                                // monthNavigatorTemplate={monthNavigatorTemplate}
                                // yearNavigatorTemplate={yearNavigatorTemplate}
                            />
                            </div>
                            {/* <div className="row">
                            <small id="dateErrorNotice" className="p-error">{this.state.errorDateMessage}</small>

                            </div> */}
                            {/* </span> */}
                        {/* </div> */}

                        <br />

                        <div className="row">
                        <PDVCaixaInput
                            selectedPdvCaixaId={this.state.selectedPdvCaixaId}
                            handleChange={this.handleChange}
                            inputPdvCaixaErrorClass={this.state.inputPdvCaixaErrorClass}
                            disabled={this.state.isDoingLancamentos}
                        />
                        </div>

                        <br />

                        <div className="row">
                        <label htmlFor="icon">Turno</label>
                        <SelectMenu
                            className={"form-control " + this.state.inputTurnoErrorClass}
                            name="selectedTurno"
                            list={this.lancamentoFechamentoCaixaService.turnoArray} 
                            value={this.state.selectedTurno}
                            onChange={this.handleChange}
                            disabled={this.state.isDoingLancamentos}
                        /> 
                        <div className="invalid-feedback">{ConstantsUtil.errorTurnoMessage}</div>  
                        </div>

                        <br />

                        </div>
                        
                        <div className="col-md-1"> 
                        {/* div vazia. Objetivo apenas de ocupar as 3 primeiras colunas */}
                        </div>

                        <div className="col-md-9">
                            {renderLancamentosTable()}
                        </div>
                    </div>

                    <br />

                    <div className = "row">
                        <div className="col-md-3"> 
                        {/* div vazia. Objetivo apenas de ocupar as 3 primeiras colunas */}
                        </div>
                        <div className="col-md-9">
                        {renderResumoTotal()}
                        </div>
                        
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-6"> 
                        {renderButtons()}
                    </div>
                </div>
                
            </Card>
            <div className="d-flex "/>
        </div>              
    )
    }


}

LancarFechamentoDeCaixa.contextType = AuthContext

export default withRouter(LancarFechamentoDeCaixa)