import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'
import ProductDialog from '../../components/product/productDialog'

import * as popUp from '../../components/toastr'

import { Button } from 'primereact/button'
import { AuthContext } from '../../main/authProvider'
import HandleErrorService from '../../app/service/handleErrorService'
import ProductService from '../../app/service/productService'
import ProductCrudTable from './productCrudTable'
import InputChooseTipoProduto from '../../components/product/tipoProduto/inputChooseTipoProduto'

class SearchProduct extends React.Component {

    constructor(){
        super();
        this.productService = new ProductService();
    }

    state = {
        codigo: null,
        descricao: '',
        ncm: null,
        cfop: null,
        tipo: '',
        unidadeComercializada: '',
        productList: [],
        filteredProductList: [],
        displayConfirmation: false,
        editId: null,
        loading: false,
        disableDeleteButton: false,
        productDialog: false,
        productDialogState: {},
        forceUpdateProductDialog: false,
    }

    componentDidMount(){
        this.search()
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    filter = () => {
        this.setState({loading: true})
        var array = []
        this.state.productList.forEach(product => {
            var codigo = (!this.state.codigo || product.codigo === this.state.codigo)
            var ncm = (!this.state.ncm || product.ncm === parseInt(this.state.ncm, 10))
            var cfop = (!this.state.cfop || product.cfop === parseInt(this.state.cfop, 10))
            var tipo = (!this.state.tipo || !product.tipo || product.tipo.toLowerCase().includes(this.state.tipo.toLowerCase()))
            var unidade = (!this.state.unidadeComercializada || product.unidadeComercializada.toLowerCase().includes(this.state.unidadeComercializada.toLowerCase()))
            var descricao = (!this.state.descricao || product.descricao.toLowerCase().includes(this.state.descricao.toLocaleLowerCase()))

            if(codigo && ncm && cfop && tipo && unidade && descricao) {
                array.push(product)
            }
        })
        this.setState({loading: false})
        this.setState({filteredProductList: array})
    }

    search = (showInfoPopUp) => {
        const productFilter = {
            codigo: this.state.codigo,
            descricao: this.state.descricao,
            ncm: this.state.ncm,
            cfop: this.state.cfop,
            tipo: this.state.tipo,
            unidadeComercializada: this.state.unidadeComercializada,
            dadosEstoque: true
        }
        this.setState({loading: true})
        this.productService.search(productFilter)
        .then(response => {
            var productList = response.data
            this.setState({productList})
            this.setState({filteredProductList: productList})
            this.filter()
            this.setState({disableDeleteButton: false})
            if(!response.data.length && showInfoPopUp){
                popUp.infoPopUp("Nenhum produto encontrado com os dados informados")
            }
            this.setState({loading: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
            this.setState({disableDeleteButton: false})
        })
        
    }

    handleTipoProdutoChange = (event) => {
        this.setState({tipo: event.target.value})
    }    

    manualRegister = () => {
        this.setState({productDialog: true})
        this.setState({forceUpdateProductDialog: true})
    }

    doneForceUpdateProductDialog = () => {
        this.setState({forceUpdateProductDialog: false})
    }

    saveProduct = product => {
        this.setState({productDialogState: product})
        this.productService.save(product)
        .then(response => {
            popUp.successPopUp("Produto cadastrado com sucesso")
            this.hideDialog()
            this.search()
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    hideDialog = () => {
        this.setState({productDialogState: {} })
        this.setState({productDialog: false})
    }

    deleteMultipleProducts = async (listOfId) => {
        if(listOfId){
            this.setState({loading: true})
            this.setState({disableDeleteButton: true})
            var object = {
                listOfProductsId: listOfId
            }
            await this.productService.deleteMultipleProducts(object)
            .then( response => {
                popUp.successPopUp("Produto(s) deletado(s) com sucesso")
                this.search()
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
                this.setState({disableDeleteButton: false})
            })
        }
    }

    resetView = () => {
        this.setState({displayConfirmation: false})
    }

    render() {
        return (
            <div className="bs-docs-section" >
                <Card title = "Busca de Produtos Cadastrados">
                    <div className = "col-md-12">
                        {/* <div className = "row">
                        <div className = "col-md-5">
                            <InputChooseTipoProduto
                                label={`Tipo do Produto`}
                                noHTMLFormat
                                selectedTipoProdutoLabel={this.state.tipo}
                                handleSelectionChange={this.handleTipoProdutoChange}
                                disabled={this.props.disabled} 
                            />                           
                        </div>
                        </div> */}

                        {/* <br />

                        <Button 
                            label="Buscar"
                            icon="pi pi-search"
                            onClick = {e => {this.search(true)} }
                        /> */}
                        <Button 
                            className='right-button'
                            label="Cadastrar Novo Produto" 
                            icon="pi pi-save"
                            onClick={this.manualRegister}
                        />
                    </div>
                    <div className="bs-docs-section" >
                        <ProductCrudTable
                            list = {this.state.filteredProductList}
                            deleteButton = {this.askForDeleteEntry}
                            deleteMultiple = {this.deleteMultipleProducts}
                            search = {this.search}
                            loading = {this.state.loading}
                            disableDeleteButton = {this.state.disableDeleteButton}
                        />
                    </div>
                </Card>
                    
                <ProductDialog
                    visible={this.state.productDialog}
                    header="Cadastrar Produto Próprio"
                    saveProdutoVenda={this.saveProduct}
                    hideDialog={this.hideDialog}
                    register={true}
                    state={this.state.productDialogState}
                    forceUpdate={this.state.forceUpdateProductDialog}
                    doneForceUpdate={this.doneForceUpdateProductDialog}
                />

                <div className="d-flex "/>
            </div>              
        )
    }


}

SearchProduct.contextType = AuthContext

export default withRouter(SearchProduct)