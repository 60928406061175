import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../../components/card'
import { AuthContext } from '../../../main/authProvider'
import GeneralServices from '../../../app/service/generalServices'
import HandleErrorService from '../../../app/service/handleErrorService'
import { Button } from 'primereact/button'

import * as popUp from '../../../components/toastr'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../../context/constantsUtil'
import InputChooseProduct from '../../../components/product/inputChooseProduct'
import FichaDeEstoqueTable from '../../product/fichaDeEstoqueTable'
import FichaDeEstoqueService from '../../../app/service/relatorios/fichaDeEstoqueService'

class FichaDeEstoque extends React.Component {

    constructor(){
        super()
        this.generalServices = new GeneralServices()
        this.fichaDeEstoqueService = new FichaDeEstoqueService()


    }

    state = {

        selectedProduct: '',
        inputProductErrorClass:'',
        errorProductMessage:'Selecione um Produto',
        // loadingContaBancariaTable: false,

        selectedFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        // inputFilterErrorClass: '',
        // errorFilterErrorMessgae: '',

        initialDate: '',
        initialDateView: '',
        inputInitialDateErrorClass: "",
        errorInitialDateMessage: "",
        
        finalDate: '',
        finalDateView: '',
        inputFinalDateErrorClass: "",
        errorFinalDateMessage: "",

        fichaDeEstoqueOutputListDTO: {},
        loadingTable: false,
        isSearchingFichaDeEstoque: false,
        isFinishedSearch: false,

        habilitaBusca: true,
    }

    componentDidMount(){
    }

    handleSelectionProductChange = async (selectedProduct) => {
        await this.setState({selectedProduct})
    }

    handleDateFilterChange = (selectedFilterType) => {
        this.setState({selectedFilterType})
    }


    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    checkData = () => {
        
        var check = true;

        if(!this.state.selectedProduct){
            this.setState({inputProductErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }

        return check;
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    desabilitaBusca = () => {
        this.setState({habilitaBusca: false})
    }

    resetView = () => {
        this.setState({inputProductErrorClass: ""})

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputInitialDateErrorClass: ""})
        this.setState({errorInitialDateMessage: ""})
        
        this.setState({inputFinalDateErrorClass: ""})
        this.setState({errorFinalDateMessage: ""})
    }

    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            this.search()
        }
    }

    search = (toggleButton) => {
        if(toggleButton){
            this.toggleButton()
        }
        this.setState({loadingTable: true})
        this.setState({isFinishedSearch: false})
        this.setState({isSearchingFichaDeEstoque: true})
        
        this.fichaDeEstoqueService.getFichaDeEstoqueByDateInterval(
            {
                dataInicial: this.state.initialDate,
                dataFinal: this.state.finalDate,
                product: this.state.selectedProduct,
            }
            )
        .then(async response => {
            await this.setState({fichaDeEstoqueOutputListDTO: response.data})
            this.setState({isFinishedSearch: true})
            this.setState({isSearchingFichaDeEstoque: false})
            this.desabilitaBusca()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isFinishedSearch: true})
            this.setState({isSearchingFichaDeEstoque: false})

        })
    }

    render() {

        const renderFichaDeEstoqueTable = () => {
            if( (this.state.isSearchingFichaDeEstoque || this.state.isFinishedSearch) && !this.state.habilitaBusca ){
                return (
                    <FichaDeEstoqueTable
                        fichaDeEstoqueOutputListDTO={this.state.fichaDeEstoqueOutputListDTO}
                        search={this.search}
                    />
                )
            }

            if(this.state.isSearchingFichaDeEstoque){
                return (
                    <FichaDeEstoqueTable
                        loading={true}
                    />
                )
            }
        }

        const renderButtons = () => {

            if(this.state.isSearchingFichaDeEstoque){
                return (
                    <Button 
                        label="Buscando ..."
                        icon="pi pi-search"
                        disabled
                    />
                )
            }

            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar Lançamentos"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return (
            <div className="bs-docs-section" >
            <Card title = "Ficha de Estoque">
                <div className = "col-md-12">
                    
                    <div className="row">

                    <div className = "col-md-4">
                        <InputChooseProduct
                            label="Produto"
                            readOnly
                            noHTMLFormat
                            selectedProductLabel={this.state.selectedProduct.descricao}
                            handleSelectionChange={this.handleSelectionProductChange}
                            inputProductErrorClass={this.state.inputProductErrorClass}
                            errorProductMessage={this.state.errorProductMessage}
                            disabled={!this.state.habilitaBusca}
                        />
                    </div>
            
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        label="Escolher período"
                        handleFilterChange={this.handleDateFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                    />

                        

                    </div>

                    <br />

                    {renderButtons()}

                    <br />

                    <br />

                    {renderFichaDeEstoqueTable()}

                </div>
            </Card>
            <div className="d-flex "/>
        </div>     
        )
    }

}


FichaDeEstoque.contextType = AuthContext

export default withRouter(FichaDeEstoque)