import CentralApiService from "../../centralApiServices";


class CentralService extends CentralApiService {

    constructor() {
        super('/api/central')
    }

    searchWorkers(){
        return this.get('/workers')
    }

    getDREMensalUnificado(dataInicial, dataFinal, selectedEmpresas){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.post(`/DRE/getDREMensalUnificado${params}`, selectedEmpresas)
    }

    getDREConsolidadoUnificado(dataInicial, dataFinal, selectedEmpresas){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.post(`/DRE/getDREConsolidadoUnificado${params}`, selectedEmpresas)
    }

    getFichaBancariaUnificada(data){
        return this.post(`/DRE/getFichaBancariaUnificada`, data)
    }

    getDRECompetenciaMensalUnificado(dataInicial, dataFinal, selectedEmpresas){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.post(`/DRECompetencia/getDRECompetenciaMensalUnificado${params}`, selectedEmpresas)
    }

    searchLancamentosProvisao(searchProvisaoInputDTO, empresasList){
        const objFinal = {
            searchProvisaoInputDTO,
            empresasList
        }
        return this.post("/DRECompetencia/searchLancamentosProvisaoUnificado", objFinal)
    }

    searchProvisaoProdutosUnificado(initialDate, finalDate, tipoProdutoList, empresasList){
        const objFinal = {
            initialDate,
            finalDate,
            tipoProdutoList,
            empresasList
        }
        return this.post("/DRECompetencia/provisaoProdutosUnificado", objFinal)
    }


}

export default CentralService