import React from 'react'
import { DataTable } from 'primereact/datatable'
import GeneralServices from '../../../app/service/generalServices'
import ConstantsUtil from '../../../context/constantsUtil'
import { Column } from 'jspdf-autotable'
import TableFilters from '../../tableFilters'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
// import DialogFooter from '../../dialogFooter'
import ProvisaoTable from '../provisoes/provisaoTable'
import AuthService from '../../../app/service/authService'
import DREFichaBancariaDialog from '../../relatorios/DRE/DREFichaBancariaDialog'


class FichaDePagamentoTable extends React.Component {

    constructor(){
        super()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters()
        this.generalServices = new GeneralServices()
    }

    state = {
        valorTotalProvisoes: 0,
        valorTotalSaidasBancarias: 0,
        valorTotalSaldo: 0,
        valoresTotaisJaCalculados: false,


        displayProvisaoDialog: false,
        searchProvisaoResult: null,
        loadingProvisaoTable: false,

        displayFichaBancariaDialog: false,
        forceUpdateFichaBancaraiDialog: false,
        elementToViewSaidaBancaria: null,


    }


    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        // const tipoDePagamento = this.props.fichaDePagamento.tipoDePagamento && this.props.fichaDePagamento.tipoDePagamento.nome ? this.props.fichaDePagamento.tipoDePagamento.nome : ""
        // const tipoDePagamentoLabel = "Tipo de Pagamento: " + tipoDePagamento
        
        // const dateFilterType = this.props.fichaDePagamento.dateFilterType ? this.props.fichaDePagamento.dateFilterType : ''
        // const dateFilterTypeLabel = "Filtrado por: " + dateFilterType
        const dateFilterTypeLabel = "Filtrado por: Data de Pagamento" 

        const periodo = this.props.fichaDePagamento.initialDate ? this.props.fichaDePagamento.initialDate + " - " + this.props.fichaDePagamento.finalDate : ""
        const periodoLabel = "Período: " + periodo

        // return {tipoDePagamento: tipoDePagamentoLabel, dateFilterType: dateFilterTypeLabel, periodo: periodoLabel}
        return {dateFilterType: dateFilterTypeLabel, periodo: periodoLabel}
    }

    getLancamentos = () => {
        if(this.props.fichaDePagamento && this.props.fichaDePagamento.fichaDePagamentoList){
            let list = this.props.fichaDePagamento.fichaDePagamentoList
            if(!this.state.valoresTotaisJaCalculados){
                this.calculaValorTotal(list)
            }
            return list
        }
        return []
    }

    calculaValorTotal = async list => {
        let valorTotalProvisoes = 0
        let valorTotalSaidasBancarias = 0
        let valorTotalSaldo = 0

        list.forEach(item => {
            valorTotalProvisoes += item.valorParcelaProvisao
            valorTotalSaidasBancarias += item.valorSaidaBancaria
            valorTotalSaldo += item.saldo
        })

        await this.setState({valoresTotaisJaCalculados: true})
        this.setState({ valorTotalProvisoes, valorTotalSaidasBancarias, valorTotalSaldo })
    }

    doFilterTipoDePagamento = async (rowData) => {
        if(!this.state.selectedTipoDePagamento){
            /*
            selectedTipoDePagamento NÃO setado, ou seja, usuáro clicou para filtrar pelo Tipo de Pagamento.
            */
            let valueList = [rowData.tipoDePagamento.nome]
            this.setState({selectedTipoDePagamento: valueList})
            this.dt.current.filter(valueList, ConstantsUtil.tipoDePagamentoNomeProperty, 'in');
        }
        else{
            /*
            selectedTipoDePagamento setado, ou seja, usuáro clicou para desfazer o filtro pelo Tipo de Pagamento.
            */
            await this.setState({selectedTipoDePagamento: null})
            this.dt.current.filter(this.state.selectedTipoDePagamento, ConstantsUtil.tipoDePagamentoNomeProperty, 'in');
        }
    }

    openProvisoesDialog = async rowData => {
        await this.setState({displayProvisaoDialog: true})
        this.setState({loadingProvisaoTable: true})
        
        // console.log("openProvisoesDialog: ", rowData)

        const obj = {
            filterTypeDate: ConstantsUtil.dataPagamentoValue,
            initialDate: rowData.dataPagamentoString,
            finalDate: rowData.dataPagamentoString,
            tipoDePagamento: rowData.tipoDePagamento
        }

        let onSuccess = async (responseData) => {
            this.setState({searchProvisaoResult: responseData.dados})
            this.setState({valorTotal: responseData.valorTotal})
        }

        let onFinally = () => {
            this.setState({loadingProvisaoTable: false})
        }

        this.generalServices.searchProvisoes(
            obj,
            onSuccess,
            onFinally
        )
    }

    hideProvisoesDialog = () => {
        this.setState({displayProvisaoDialog: false})
    }

    openDREFichaBancariaDialog = async rowData => {
        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateFichaBancaraiDialog: true})
        await this.setState({elementToViewSaidaBancaria: {
            initialDate: rowData.dataPagamentoString,
            finalDate: rowData.dataPagamentoString,
            tipo: ConstantsUtil.tipoDePagamentoFiltroLabel,
            tipoDePagamentoDTO: rowData.tipoDePagamento,
            nome: rowData.tipoDePagamento.nome,
        }})
        // console.log("openDREFichaBancariaDialog this.state.elementToViewSaidaBancaria: ", this.state.elementToViewSaidaBancaria)
    }

    hideDREFichaBancariaDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        this.setState({elementToViewSaidaBancaria: null})
    }

    research = () => {
        if(this.state.displayProvisaoDialog){
            this.hideProvisoesDialog()
        }
        else if(this.state.displayFichaBancariaDialog){
            this.hideDREFichaBancariaDialog()
        }
        this.props.search()
    }

    render(){

        // const dialogFooter = (
        //     <DialogFooter hideDialog={this.hideProvisoesDialog} disabled/>
        // )

        const renderProvisaoDialog = () => {
            return (
                <>
                    <Dialog
                        header="Provisões de Pagamento"
                        visible={this.state.displayProvisaoDialog}
                        modal
                        onHide={this.hideProvisoesDialog}
                        // footer={dialogFooter}
                    >
                        <ProvisaoTable
                            searchProvisaoResult={this.state.searchProvisaoResult}
                            loading={this.state.loadingProvisaoTable}
                            perfil = {AuthService.getPerfil()}
                            search={this.research}
                            // disabled
                            // valorTotal={this.state.valorTotal}
                        />
                    </Dialog>
                </>
            )
        }

        const renderDREFichaBancariaDialog = () => {
            // console.log("renderDREFichaBancariaDialog: ", this.state.elementToViewSaidaBancaria)
            return (
                <>
                    <DREFichaBancariaDialog
                        visible={this.state.displayFichaBancariaDialog}
                        initialDate={this.state.elementToViewSaidaBancaria ? this.state.elementToViewSaidaBancaria.initialDate : ''}
                        finalDate={this.state.elementToViewSaidaBancaria ? this.state.elementToViewSaidaBancaria.finalDate : ''}
                        element={this.state.elementToViewSaidaBancaria}
                        // unificarEmpresas={this.props.unificarEmpresas}
                        // selectedEmpresas={this.props.selectedEmpresas}
                        forceUpdate={this.state.forceUpdateFichaBancaraiDialog}
                        doneForceUpdate={() => this.setState({forceUpdateFichaBancaraiDialog: false})}
                        search={this.research}
                        // saveAsExcelFile={this.props.saveAsExcelFile}
                        // loadingTable={false}
                        hideDialog={this.hideDREFichaBancariaDialog}
                        // disabled
                    />                
                </>
            )
        }

        //dataLancamento Filter
        const dataLancamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataLancamento,
            this.getLancamentos(),
            "selectedDataLancamento",
            (name, value) => this.setState({[name]: value}),
            'dataLancamentoString',
            '100%',
            "Selecione"
        )  

        //dataPagamento Filter
        const dataPagamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataPagamento,
            this.getLancamentos(),
            "selectedDataPagamento",
            (name, value) => this.setState({[name]: value}),
            'dataPagamentoString',
            '100%',
            "Selecione"
        )  

        //tipoDePagamento Filter
        const tipoDePagamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoDePagamento,
            this.getLancamentos(),
            "selectedTipoDePagamento",
            (name, value) => this.setState({[name]: value}),
            'tipoDePagamento.nome',
            '100%',
            "Selecione"
        )  

        //valorProvisao Filter
        const valorProvisaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorProvisao,
            this.getLancamentos(),
            "selectedValorProvisao",
            (name, value) => this.setState({[name]: value}),
            'valorParcelaProvisao',
            '100%',
            "Selecione"
        )  

        //valorSaidaBancaria Filter
        const valorSaidaBancariaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorSaidaBancaria,
            this.getLancamentos(),
            "selectedValorSaidaBancaria",
            (name, value) => this.setState({[name]: value}),
            'valorSaidaBancaria',
            '100%',
            "Selecione"
        )  

        //saldo Filter
        const saldoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorSaldo,
            this.getLancamentos(),
            "selectedValorSaldo",
            (name, value) => this.setState({[name]: value}),
            'saldo',
            '100%',
            "Selecione"
        )

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().dateFilterType}</h5>
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                </div>
                
            </div>
            </div>

            <div className="">
            <DataTable
                ref={this.dt}
                className="p-datatable-sm small-font hidden-tfoot"
                value={this.getLancamentos()}
                onValueChange={this.calculaValorTotal}
                // loading={this.props.loading || this.state.loading}
                loading={this.props.loading}
                // rowClassName={this.rowClass}
                rowHover
                scrollable
                scrollHeight={ this.props.insideDialog ? "25rem" : "32rem"}
                emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                dataKey="id"
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.getLancamentos() ? this.getLancamentos().length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

                <Column
                    header="Data de Lançamento da Provisão"
                    field="dataLancamentoString"
                    sortable sortField='dataLancamento'
                    style ={ {width: '150px'} }
                    filter filterElement={dataLancamentoFilterElement}
                />

                <Column
                    header="Data de Pagamento da Parcela"
                    field="dataPagamentoString"
                    sortable sortField='dataPagamento'
                    style ={ {width: '150px'} }
                    filter filterElement={dataPagamentoFilterElement}
                />

                <Column
                    header="Tipo de Pagamento"
                    field="tipoDePagamento.nome"
                    sortable sortField='tipoDePagamento.nome'
                    style ={ {width: '200px'} }
                    filter filterElement={tipoDePagamentoFilterElement}
                    // body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.tipoDePagamento.nome, rowIndex) }
                    body={ (rowData, rowIndex) => 
                        GeneralServices.renderTableValueAsLink(
                            rowData,
                            rowIndex,
                            rowData.tipoDePagamento.nome,
                            true,
                            false,
                            () => this.doFilterTipoDePagamento(rowData)
                        )
                    }
                />

                <Column
                    header={
                        <div>
                            {"Valor Total das Provisões"}
                            <br />
                            <Button label={GeneralServices.showFormattedIfNotNull(this.state.valorTotalProvisoes, true)}/>
                        </div>
                    }
                    field="valorParcelaProvisao"
                    // sortable
                    style ={ {width: '100px'} }
                    filter filterElement={valorProvisaoFilterElement}
                    body = { (rowData, rowIndex) => {
                        return GeneralServices.renderTableValueAsLink(
                            rowData,
                            rowIndex,
                            GeneralServices.showFormattedIfNotNull(rowData.valorParcelaProvisao, true),
                            true,
                            false,
                            () => this.openProvisoesDialog(rowData)
                        )
                        
                    } }
                />

                <Column
                    header={
                        <>
                            {"Valor Total das Saídas Bancárias"}
                            <br />
                            <Button label={GeneralServices.showFormattedIfNotNull(this.state.valorTotalSaidasBancarias, true)}/>
                        </>
                    }
                    field="valorSaidaBancaria"
                    // sortable
                    style ={ {width: '120px'} }
                    filter filterElement={valorSaidaBancariaFilterElement}
                    // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valorSaidaBancaria, true) }
                    body = { (rowData, rowIndex) => {
                        return GeneralServices.renderTableValueAsLink(
                            rowData,
                            rowIndex,
                            GeneralServices.showFormattedIfNotNull(rowData.valorSaidaBancaria, true),
                            true,
                            false,
                            () => this.openDREFichaBancariaDialog(rowData)
                            
                        )
                        
                    } }                    
                />

                <Column
                    header={
                        <>
                            {"Saldo"}
                            <br />
                            <Button
                                label={GeneralServices.showFormattedIfNotNull(this.state.valorTotalSaldo, true)}
                                className={!this.state.valorTotalSaldo || this.state.valorTotalSaldo === 0 ? 'p-button-success' : 'p-button-danger'}
                            />
                        </>
                    }
                    field="saldo"
                    // sortable
                    style ={ {width: '100px'} }
                    filter filterElement={saldoFilterElement}
                    body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true) }
                />

            </DataTable>

            </div>

                {renderProvisaoDialog()}

                {renderDREFichaBancariaDialog()}

            </>

        )

    }

}

export default FichaDePagamentoTable