import React from 'react'
import FormGroup from './form-group'
import ConstantsUtil from '../context/constantsUtil'

class PasswordInput extends React.Component {

    constructor(){
        super()
    }

    state = {
        eyeIconOpened: false,
    }

    handleEyeIconChange = () => {
        this.setState({eyeIconOpened: !this.state.eyeIconOpened})
    }

    render() {

        const renderEyePassowrdComponent = () => {
            if(!this.props.inputPasswordErrorClass){
                return (
                    <span className="p-viewer">
                        <i
                            className={this.state.eyeIconOpened ? "pi pi-eye-slash" : "pi pi-eye"}
                            onClick={this.handleEyeIconChange}
                        />
                    </span>
                )
            }
        }

        return(
            <FormGroup label = {this.props.label ? this.props.label : "Senha:"} >
            <div className="pwd">
                <input 
                    type={this.state.eyeIconOpened ? "text" : "password"}
                    className={"form-control " + this.props.inputPasswordErrorClass}
                    value = {this.props.value}
                    name={this.props.name}
                    onChange = {this.props.handleChange}
                    onKeyPress={this.props.handleKeypress}
                    placeholder={ this.props.placeholder ? this.props.placeholder : "Digite a senha"}
                />
                {renderEyePassowrdComponent()}
            <div className="invalid-feedback">{this.props.errorPasswordMessage}</div>
            </div>
        </FormGroup>            

        )
    }

}

export default PasswordInput

