import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../../dialogFooter'
import CentralService from '../../../app/service/central/centralService'
import { DataTable } from 'primereact/datatable'
import ConstantsUtil from '../../../context/constantsUtil'
import GeneralServices from '../../../app/service/generalServices'
import { Column } from 'primereact/column'
import classNames from 'classnames'
import FluxoCaixaPrevistoService from '../../../app/service/relatorios/fluxoCaixaPrevistoService'
import HandleErrorService from '../../../app/service/handleErrorService'
import { Button } from 'primereact/button'

class DetalhamentoDialog extends React.Component {

    constructor(){
        super()
        this.centralService = new CentralService();
        this.fluxoCaixaPrevistoService = new FluxoCaixaPrevistoService();
    }

    state = {
        
        didUpdated: false,
        loading: false,
        
        detalhamentoList: {},
    }

    componentDidUpdate(){
        if( this.props.element && this.props.element.tipo !== ConstantsUtil.saldoLabel && this.props.visible && !this.state.didUpdated ){
            this.searchDetalhamentoFluxoCaixaPrevisto()
            this.setState({didUpdated: true})
        }
    }

    searchDetalhamentoFluxoCaixaPrevisto = () => {
        // console.log("element: ", this.props.element)
        this.setState({loading: true})
        this.setState({detalhamentoList: ''})
        // console.log("element: ", element)
        this.fluxoCaixaPrevistoService.getDetalhamento(this.props.element)
        .then(response => {
            this.setState({detalhamentoList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }


    hideDialog = () => {
        this.props.hideDialog()
        this.setState({didUpdated: false})
    }

    getList = () => {
        if(this.props.element){
            if(this.props.element.tipo === ConstantsUtil.saldoLabel){
                return this.props.fluxoCaixaPrevisto && this.props.fluxoCaixaPrevisto.saldoInicial ? this.props.fluxoCaixaPrevisto.saldoInicial.saldoContaBancariaList : []
            }
            else{
                //LANCAMENTO
                return this.state.detalhamentoList
            }

        }
    }

    
    rowClass(rowData) {
        return {
            [ConstantsUtil.totaisTableLabel]: rowData.totalItem
        }
    }

    getCompleteHeader = () => {
        return `${this.props.header} (${this.props.element.dataString})`
    }

    detalhamentoToExportTemplate = () => {
        var finalList = []
        this.getList().forEach(item => {
            finalList.push({
                Data: item.dataLancamento,
                Nome: item.nome,
                Valor: item.valor
            })
        })
        return finalList
    }

    render(){

        
        const footer = (
            <DialogFooter hideDialog = {this.hideDialog} disabled/>
        )

        const renderViewLancamentosLink = (rowData) => {
            
            const valor = rowData.valor
            const isTotal = rowData.totalItem
            
            const classes = classNames({
                'superavitValue': valor >= 0 && !isTotal,
                'deficitValue': valor<0 && !isTotal,
            });
    
                return(
                    <div className={classes}>
                        {GeneralServices.showFormattedIfNotNull(valor, true)}
                    </div>
                )
        }


        return(
            <Dialog
                visible={this.props.visible}
                style={{ width: '95vw',}}
                header={this.getCompleteHeader()}
                modal
                className="p-fluid"
                footer={footer}
                onHide={this.hideDialog}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    label="Exportar"
                    icon="pi pi-file-excel"
                    className="p-button-success"
                    onClick={() => GeneralServices.exportExcel(this.detalhamentoToExportTemplate(), "Lançamentos", this.getCompleteHeader())}
                    style={{maxWidth: '10rem', }}
                />
                </div>

                <DataTable
                    ref={this.dt}
                    value={this.getList()}
                    className="p-datatable-sm small-font"
                    rowClassName={this.rowClass}
                    rowHover
                    showGridlines
                    scrollable
                    scrollHeight="500px"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.fichaDeEstoque ? this.props.fichaDeEstoque.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
                >

                    <Column
                        header="Data de Lançamento"
                        field="dataLancamento"
                        // body={rowData => rowData.dataString}
                        sortable style ={ {width: '140px'} }
                        // filter filterElement={codeFilterElement}
                    />

                    <Column
                        header="Nome"
                        field="nome"
                        // body={rowData => rowData.dataString}
                        sortable style ={ {width: '140px'} }
                        // filter filterElement={codeFilterElement}
                    />

                    <Column
                        header="Valor"
                        field="valor"
                        // body={rowData => GeneralServices.showFormattedIfNotNull(rowData.valor, true)}
                        // body={rowData => renderViewLancamentosLink(rowData)}
                        body={renderViewLancamentosLink}
                        sortable style ={ {width: '140px'} }
                        // filter filterElement={codeFilterElement}
                    />

                </DataTable>                
            </Dialog>
        )
    }

}

export default DetalhamentoDialog