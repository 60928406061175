import React from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import GeneralServices from '../../app/service/generalServices'
import TableFilters from '../../components/tableFilters'
import ConstantsUtil from '../../context/constantsUtil'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'

class TransactionByProductTable extends React.Component {

    state = {

        displayMotivoDialog: false,

    }

    constructor(){
        super()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters();

    }

    componentDidMount(){
        GeneralServices.filtraOcultaExcluidas(
            (selectedSituacao) => this.setState({selectedSituacao}),
            'nf.situacaoLabel',
            this.dt
        )
    }

    calculateQuantidadeAndValorTotal = (currentTableValue) => {
        const quantidade = currentTableValue ? currentTableValue.length : 0
        
        let valorTotal = 0
        currentTableValue.forEach(item => valorTotal+= item.transaction.valor)

        this.props.setInfo({quantidade,valorTotal})
    }

    openMotivoDialog = async (rowData) => {
        await this.setState({displayMotivoDialog: true})
        await this.setState({nfExlcuidaToShowMotivo: rowData.nf})
    }    

    hideMotivoDialog = () => {
        this.setState({displayMotivoDialog: false})
        this.setState({nfExlcuidaToShowMotivo: ''})
    }    


    render() {

        const renderMotivoDialog = () => {
            return (
                <Dialog
                    header={"Motivo da Exclusão da NF"}
                    visible={this.state.displayMotivoDialog}
                    modal
                    style={{ width: '350px' }}
                    onHide={this.hideMotivoDialog}
                >

                    <div className="confirmation-content" >
                        <div className='row centraliza-conteudo-div'>
                        <div style={{marginBottom: '10px'}}> {this.state.nfExlcuidaToShowMotivo ? this.state.nfExlcuidaToShowMotivo.motivoExclusao : ""} </div>
                        </div>
                        
                    </div>
        
                </Dialog>                 
            )
        }        

        const renderDataEntradaColumn = () => {
            if(this.props.tipoNF === ConstantsUtil.tipoNFEntradaLabel){
                return (
                    <Column
                        header="Data Entrada"
                        field="nf.dataEntradaSemHorario"
                        // body={rowData => rowData.nf.dataHoraEntrada ? rowData.nf.dataHoraEntrada.substring(0, 21) : ''}
                        body = { (rowData, rowIndex) => rowData.nf.dataHoraEntrada ? GeneralServices.renderBodyColumnInOneLine(rowData.nf.dhEmi.substring(0, 21), rowIndex) : ''}
                        style ={ {width: '80px'} }
                        sortable
                        sortField='dataHoraEntradaJsDate'                        
                        filter filterElement={dataEntradaNFFilterElement}

                    />
                )
            }
        }


        //Código Filter
        const codigoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedCodigos,
            this.props.list,
            "selectedCodigos",
            (name, value) => this.setState({[name]: value}),
            'transaction.productInfo.codigo',
            '55px',
            "Código"
        )        

        //Descrição Filter
        const descriptionFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDescriptions,
            this.props.list,
            "selectedDescriptions",
            (name, value) => this.setState({[name]: value}),
            'transaction.productInfo.descricao',
            '150px',
            "Produto"
        )

        //TipoProduto Filter
        const tipoProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTiposProduto,
            this.props.list,
            "selectedTiposProduto",
            (name, value) => this.setState({[name]: value}),
            'transaction.productInfo.tipoProduto.descricao',
            '100%',
            "Tipo de Produto"
        )

        //dataEmissaoNF Filter
        const dataEmissaoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataEmissaoNF,
            this.props.list,
            "selectedDataEmissaoNF",
            (name, value) => this.setState({[name]: value}),
            'nf.dEmiSemHorario',
            '80px',
            "Data Emissão"
        )

        //dataEntradaNF Filter
        const dataEntradaNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataEntradaNF,
            this.props.list,
            "selectedDataEntradaNF",
            (name, value) => this.setState({[name]: value}),
            'nf.dataEntradaSemHorario',
            '80px',
            "Data Entrada"
        )

        //dataImportacaoNF Filter
        const dataImportacaoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataImportacaoNF,
            this.props.list,
            "selectedDataImportacaoNF",
            (name, value) => this.setState({[name]: value}),
            'nf.dataImportacaoSemHorario',
            '80px',
            "Data Importação"
        )

        //quantidade Filter
        const quantidadeFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedQuantidade,
            this.props.list,
            "selectedQuantidade",
            (name, value) => this.setState({[name]: value}),
            'transaction.quantidade',
            '40px',
            "Qtd"
        )

        //FornecedorOuCliente Filter
        const fornecedorOuClienteFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedFornecedorOuCliente,
            this.props.list,
            "selectedFornecedorOuCliente",
            (name, value) => this.setState({[name]: value}),
            'nf.nomeFornecedorOuCliente',
            '140px',
            "Nome"
        )

        //numeroNFFilterElement Filter
        const numeroNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNumeroNF,
            this.props.list,
            "selectedNumeroNF",
            (name, value) => this.setState({[name]: value}),
            'nf.numero',
            '50px',
            "Número"
        )

        //tipoNFFilterElement Filter
        const tipoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoNF,
            this.props.list,
            "selectedTipoNF",
            (name, value) => this.setState({[name]: value}),
            'nf.tipo',
            '70px',
            "Tipo"
        )        

        //modeloNFFilterElement Filter
        const modeloNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedModeloNF,
            this.props.list,
            "selectedModeloNF",
            (name, value) => this.setState({[name]: value}),
            'nf.modelo',
            '55px',
            "Modelo"
        )

        //chaveNFFilterElement Filter
        const chaveNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedChaveNF,
            this.props.list,
            "selectedChaveNF",
            (name, value) => this.setState({[name]: value}),
            'nf.chave',
            '50px',
            "Chave"
        )

        //valorUnitProdutoFilterElement Filter
        const valorUnitProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorUnitProduto,
            this.props.list,
            "selectedValorUnitProduto",
            (name, value) => this.setState({[name]: value}),
            'transaction.valorUnitario',
            '65px',
            "Vr Unit Produto"
        )

        //valorTotalProdutoFilterElement Filter
        const valorTotalProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorTotalProduto,
            this.props.list,
            "selectedValorTotalProduto",
            (name, value) => this.setState({[name]: value}),
            'transaction.valor',
            '65px',
            "Vr Total Produto"
        )

        //valorTotalNFFilterElement Filter
        const valorTotalNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedvalorTotalNF,
            this.props.list,
            "selectedvalorTotalNF",
            (name, value) => this.setState({[name]: value}),
            'nf.valorTotal',
            '65px',
            "Valor Total"
        )

        //situacaoFilterElement Filter
        const situacaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedSituacao,
            this.props.list,
            "selectedSituacao",
            (name, value) => this.setState({[name]: value}),
            'nf.situacaoLabel',
            '100%',
            "Situação"
        )        


        const renderStyledTipo = (tipo) => {

            const classes = classNames({
                // 'superavitValue': tipo >= 0,
                'deficitValue': tipo === ConstantsUtil.tipoNFDevolucaoEntradaLabel
            });
    
            return (
                <div className={classes}>
                    {tipo}
                </div>
            );
        }        

        return (
            <div className="datatable-crud-demo">
            <Toast ref={this.toast} />

            <div className="card">

                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    onValueChange={this.calculateQuantidadeAndValorTotal}
                    // showGridlines
                    scrollable
                    scrollHeight="500px"
                    loading={this.props.loading}
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Lançamentos"
                >

                    <Column
                        header="Data de Emissão"
                        field="nf.dEmiSemHorario"
                        // body={rowData => rowData.nf.dhEmi.substring(0, 21)}
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nf.dhEmi.substring(0, 21), rowIndex)}
                        style ={ {width: '80px'} }
                        sortable
                        sortField='dhEmiJsDate'
                        filter filterElement={dataEmissaoNFFilterElement}
                    />

                    {renderDataEntradaColumn()}                    

                    <Column field="transaction.productInfo.codigo" header="Código" style ={ {width: '55px'} }
                        filter filterElement={codigoFilterElement}
                    />

                    <Column
                        field="transaction.productInfo.descricao"
                        header="Produto" style ={ {width: '140px'} }
                        filter filterElement={descriptionFilterElement}
                        body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.transaction.productInfo.descricao, rowIndex) }
                    />

                    <Column
                        field="transaction.productInfo.tipoProduto.descricao"
                        header="Tipo de Produto" style ={ {width: '60px'} }
                        sortable
                        filter filterElement={tipoProdutoFilterElement}
                        body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.transaction.productInfo.tipoProduto.descricao, rowIndex) }
                    />

                    {/* <Column
                        field="transaction.tipoProduto"
                        header="Tipo do Produto" style ={ {width: '140px'} }
                        // filter filterElement={descriptionFilterElement}
                    /> */}


                    {/* <Column
                        header="Data Importação"
                        field="nf.dataImportacaoSemHorario"
                        body={rowData => rowData.nf.dataHoraImportacao ? rowData.nf.dataHoraImportacao.substring(0, 21) : ''}
                        style ={ {width: '80px'} }
                        sortable
                        sortField='dataHoraImportacaoJsDate'                        
                        filter filterElement={dataImportacaoNFFilterElement}
                    /> */}

                    <Column
                        header="Qtd"
                        field="transaction.quantidade"
                        style ={ {width: '40px'} }
                        sortable
                        filter filterElement={quantidadeFilterElement}
                        
                    />

                    <Column
                        header="Vr Unit Produto"
                        field = "transaction.valorUnitario"
                        style ={ {width: '65px'} }
                        sortable
                        body={rowData => GeneralServices.valueBodyTemplate(rowData.transaction.valorUnitario)}
                        filter filterElement={valorUnitProdutoFilterElement}
                    />

                    <Column
                        header="Vr Total Produto"
                        field = "transaction.valor"
                        style ={ {width: '65px'} }
                        sortable
                        body={rowData => GeneralServices.valueBodyTemplate(rowData.transaction.valor)}
                        filter filterElement={valorTotalProdutoFilterElement}
                    />

                    <Column
                        header={this.props.tipoNF === ConstantsUtil.tipoNFEntradaLabel ? "Fornecedor" : "Cliente"}
                        field="nf.nomeFornecedorOuCliente"
                        style ={ {width: '140px'} }
                        sortable
                        filter filterElement={fornecedorOuClienteFilterElement}
                        body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nf.nomeFornecedorOuCliente, rowIndex) }
                    />

                    <Column field="nf.numero" header="Nº da NF" style ={ {width: '50px'} } 
                        sortable
                        filter filterElement={numeroNFFilterElement}
                    />
                    
                    <Column
                        header="Vr Total NF"
                        field = "nf.valorTotal"
                        style ={ {width: '65px'} }
                        body={rowData => GeneralServices.valueBodyTemplate(rowData.nf.valorTotal)}
                        sortable
                        filter filterElement={valorTotalNFFilterElement}
                    />

                    <Column
                        header="Situação da NF"
                        field="nf.situacaoLabel"
                        sortable
                        style ={ {width: '50px'} }
                        filter filterElement={situacaoFilterElement}
                        // body = { (rowData, rowIndex) => GeneralServices.renderStyledSituacaoNF(rowData.nf.situacaoLabel, rowIndex)}
                        body = { (rowData, rowIndex) => GeneralServices.renderStyledSituacaoNF(
                            rowData.nf.situacaoLabel,
                            rowIndex,
                            () => {this.openMotivoDialog(rowData)}
                        )}                    
                    />                    

                    <Column field="nf.tipo" header="Tipo da NF" style ={ {width: '70px'} }
                        sortable
                        filter filterElement={tipoNFFilterElement}
                        body = {rowData => renderStyledTipo(rowData.nf.tipo)}
                    />                    

                    {/* <Column
                        header="Modelo" style ={ {width: '55px'} } 
                        field="nf.modelo"
                        filter filterElement={modeloNFFilterElement}
                    /> */}

                    <Column
                        header="Chave da NF"
                        field="nf.chave"
                        style ={ {width: '50px'} }
                        body={rowData => GeneralServices.copyChNFeBody(rowData.nf.chave)}
                        filter filterElement={chaveNFFilterElement}
                    />     

                </DataTable>
                {renderMotivoDialog()}
            </div>

        </div>
        )
    }


}  

export default TransactionByProductTable