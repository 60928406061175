import React from 'react'
import EmpresasTable from './empresasTable'

class EmpresasTableSearchComponent extends React.Component {

    constructor(){
        super()
        this.tableRef = React.createRef()
    }

    state = {
        searchText: '',
    }

    search = () => {
        this.tableRef.current.search()
    }

    handleChange =  (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({[name]: value})
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            let empresasList = this.tableRef.current.filter()
            if(empresasList && empresasList.length == 1) {
                /*
                Se só sobrou 1 empresa no filtro e usuário clicou na tecla "Enter",
                então deve realizar a ação de entrar na empresa.
                */
                this.tableRef.current.loginToEmpresa(empresasList[0])
            }
          }
    }

    render() {
        return (
            <>
                <div className="row">
                <div className = "col-md-12">

                    <label htmlFor="date">Pesquisa</label>
                    <input style={{color: '#000'}} type="text"
                        className={"form-control "}
                        value = {this.state.searchText}
                        name="searchText"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyPress}
                        autoFocus
                        id="searchText"
                        autoFocus
                        placeholder="Pesquise pelo nome"
                    />

                    <br />



                </div>
                </div>


                <div className="row">
                <div className = "col-md-12">
                    <EmpresasTable
                        ref={this.tableRef}
                        loading = {this.props.loading}
                        searchText={this.state.searchText}
                        edit={this.props.edit}
                        context={this.props.context}
                        disableEditColumn={this.props.disableEditColumn}
                    />

                </div>

                </div>

            </>            
        )
    }

}
export default EmpresasTableSearchComponent