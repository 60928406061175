import WorkerApiService from "../../workerApiServices";


class LancamentoFechamentoCaixaService extends WorkerApiService {
    constructor() {
        super('/api/lancamentoFechamentoCaixa')
        this.turnoArray=[
            {label: 'Selecione...', value: ''},
            {label: 'Único', value: 'UNICO'},
            {label: 'Manhã', value: 'MANHA'},
            {label: 'Tarde', value: 'TARDE'},
            {label: 'Noite', value: 'NOITE'}
        ]
    }

    formatArray(backendArray){
        var array = JSON.parse(JSON.stringify(backendArray)) //copia array sem referência
        var formattedArray = [{label: 'Selecione...', value: ''}].concat(array.map( function(element)  { return {label: element.nome, value: element.id} }))
        return  JSON.parse(JSON.stringify(formattedArray))
    }

    getLancamentos(dataLancamento, pdvCaixaId, turno){
        let params = `?`
        params = `${params}&dataLancamento=${dataLancamento}`
        params = `${params}&pdvCaixaId=${pdvCaixaId}`
        params = `${params}&turno=${turno}`
        return this.get(`/search${params}`)
    }

    save(object){
        return this.post("/save", object)
    }

    reprocess(dataLancamento, tipoDeRecebimento){
        let params = `?`
        params = `${params}&dataInicial=${dataLancamento}`
        return this.post(`/reprocess${params}`, tipoDeRecebimento)
    }
}

export default LancamentoFechamentoCaixaService