import React from 'react'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import currecyFormater from 'currency-formatter'
import * as popUp from '../toastr'
import ProductService from '../../app/service/productService'
import TableFilters from '../tableFilters'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import ConfirmationDialog from '../confirmationDialog'
import ConstantsUtil from '../../context/constantsUtil'
import UploadDialog from '../uploadDialog'

class InventoryTable extends React.Component {

    state = {

        filteredList: [],

        codigo: null,
        descricao: '',
        ncm: null,
        tipo: '',
        unidadeComercializada: '',
        quantidade: null,
        selectedProducts: null,
        productDialog: false,
        updateStockDialog: false,
        displayUndoUpdateStockConfirmation: false,
        editId: null,
        updateStockId: null,
        codigoField: 'codigo',
        selectedCodes: null,
        descricaoField: 'descricao',
        selectedDescriptions: null,
        NCMField: 'ncm',
        selectedNCM: null,
        cfopField: 'cfop',
        selectedCfops: null,
        unitField: 'unidadeComercializada',
        selectedUnits: null,
        productToUpdateId: null,
        checkLaunch: false,
        loading: false,

        uploadLancamentoDialogVisible: false,
    }
    constructor(){
        super()
        this.toast = React.createRef()
        this.dt = React.createRef()
        this.productService = new ProductService();
        this.tableFilters = new TableFilters();
        this.generalServices = new GeneralServices();

    }

    valueBodyTemplate = (rowData) => {
        return currecyFormater.format(rowData.value, {locale: 'pt-BR'})
    }

    hideDialog = (name) => {
        this.setState({[name]: false})
    }


    onFilterChange = (event, filterField) => {
        const name = event.target.name
        // const list = this.dt.current.filter(event.value, filterField, 'in');
        this.filter(event.value, filterField, 'in');
        this.setState({[name]: event.value})
    }

    filter = (valueList, filterField) => {
        if(valueList){
            const filteredList = this.props.list.filter(produto => valueList.includes(produto[filterField]) )
            this.setState({filteredList})
        }
    }

    getList = () => {
        if(this.state.checkLaunch){
            return this.props.updatedProductsList
        }
        else if(this.state.filteredList.length !== 0){
            const idList = this.state.filteredList.map(item => item.id)
            return this.props.list.filter(produto => idList.includes(produto.id))
        }

        return this.props.list

    }

    updateStock = (rowData) => {
        this.setState({productToUpdateId: rowData.id})
        this.setState({updateStockDialog: true})
    }

    cancelStock = (rowData) => {
        this.setState({productToUpdateId: rowData.id})
        this.setState({displayUndoUpdateStockConfirmation: true})
    }

    handleTableQunatidadeChange = async (event, rowData) => {
        var quantidade = event.value
        if(quantidade !== null){
            var productUpdated = JSON.parse(JSON.stringify(rowData))
            productUpdated.quantidade = quantidade
            this.save(productUpdated)
        }
    }

    handleTableValorUnitarioChange = async (event, rowData) => {
        var valorUnitario = event.value
        if(valorUnitario !== null){
            var productUpdated = JSON.parse(JSON.stringify(rowData))
            productUpdated.aberturaInvetarioOutputInfoDTO.valorUnitario = valorUnitario
            productUpdated.aberturaInvetarioOutputInfoDTO.hasAberturaInventario = true
            this.save(productUpdated)
        }
    }

    save = (product) => {
        // product.id = this.state.productToUpdateId
        this.props.updateStockFunction(product)
        this.setState({productToUpdateId: null})
        // this.setState({updateStockDialog: false})
    }

    confirmUndoUpdateStock = () => {
        this.props.undoUpdateStockFunction(this.state.productToUpdateId)
        this.setState({productToUpdateId: null})
        this.setState({displayUndoUpdateStockConfirmation: false})

        if(this.isUpdateProductListEmpty()){
            this.setState({checkLaunch: false})
        }
    }

    checkData = () => {

        var check = true
        if(this.props.updatedProductsList.some(product => !product.aberturaInvetarioOutputInfoDTO.valorUnitario)){
            popUp.warningPopUp("Há valores unitários não informados!")
            check = false
        }
        return check
    }

    confirmInvetoryLaunch = () => {
        if(this.checkData()){
            this.props.updatedProductsList.forEach(product => {
                product.valorUnitario = product.aberturaInvetarioOutputInfoDTO.valorUnitario
                product.aberturaInventario = product.aberturaInvetarioOutputInfoDTO.hasAberturaInventario
                product.dataHoraAtualizacaoEstoque = this.props.dataLancamento + " - " + this.props.updateStockHour
            })
            const obj = {
                stockInfoList: this.props.updatedProductsList            
            }
        
            this.setState({loading: true})
            this.productService.updateMultipleProductsStock(obj)
            .then(response => {
                popUp.successPopUp("Lancçamento de Invetário concluído com sucesso!")
                this.props.search()
                this.setState({checkLaunch: false})
                this.props.resetUpdatedProductsList()
                this.setState({loading: false})
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
            })
        }    
    }

    isUpdateProductListEmpty = () => {
        return (!this.props.updatedProductsList || this.props.updatedProductsList.length === 0)
    }
    
    checkLaunch = async () => {
        if(this.isUpdateProductListEmpty()){
            popUp.infoPopUp('Nenhuma alteração foi realizada')
            return
        }
        await this.onFilterChange({
            target: {name: 'selectedCodes'},
            value: null
        }, this.state.codigoField)

        await this.onFilterChange({
            target: {name: 'selectedDescriptions'},
            value: null
        }, this.state.descricaoField)

        await this.onFilterChange({
            target: {name: 'selectedNCM'},
            value: null
        }, this.state.NCMField)

        await this.onFilterChange({
            target: {name: 'selectedCfops'},
            value: null
        }, this.state.cfopField)

        await this.onFilterChange({
            target: {name: 'selectedUnits'},
            value: null
        }, this.state.unitField)

        this.setState({checkLaunch: true})
    }

    onFinishUpload = (progressKey) => {
        this.productService.getUploadLancamentoInventarioResult(progressKey)
        .then(response => {
            this.setState({loading: true})
            var productList = response.data
            productList.forEach(product => {
                this.handleTableQunatidadeChange({value: product.quantidade}, product)

                if(!product.aberturaInvetarioOutputInfoDTO.hasAberturaInventario){
                    this.handleTableValorUnitarioChange({value: product.aberturaInvetarioOutputInfoDTO.valorUnitario}, product)
                }
            })
            this.checkLaunch()
            this.setState({loading: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(async () => 
            {
                await this.generalServices.sleep(300)
                this.setState({uploadLancamentoDialogVisible: false})
            }
        )
    }

    productsToExportTemplate = () => {
        const quantidadeEstoqueLabel = "Quantidade Em Estoque"
        const valorUnitarioLabel = "Valor Unitário"
        var finalList = []
        this.props.list.forEach(product => {
            finalList.push({
                Código: product.codigo,
                Descrição: product.descricao,
                [quantidadeEstoqueLabel]: null,
                [valorUnitarioLabel]: null
            })
        })
        return finalList
    }    

    render (){

        const actionBody = (rowData) => {
            if(this.props.updatedProductsList.some(element => element.id === rowData.id)){
                return (
                    <React.Fragment>
                        <Button
                            tooltip = "Desfazer alteração"
                            tooltipOptions={{position: 'top'}}
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-mr-2 small-font"
                            style={ {marginLeft: '3px', maxHeight: '25px', maxWidth: '25px'} }
                            onClick={() => this.cancelStock(rowData)}
                        />
                    </React.Fragment>
                );
            }
        }


        const rightToolbarTemplate = () => {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }

        const leftToolbarTemplate = () => {
            if(this.state.checkLaunch){
                return (
                    <>
                    <Button label="Confirmar" icon="pi pi-check" className="p-button-success"
                        style={{maxHeight: '30px'}}
                        onClick={this.confirmInvetoryLaunch}
                    />
                    <Button label="Voltar" icon="pi pi-undo" className="p-button-danger"
                        style={{marginLeft: '8px', maxHeight: '30px'}}
                        onClick={() => {
                                this.setState({checkLaunch: false})
                                this.setState({filteredList: []})
                            }
                        }
                    />
                    </>
                )
            }
            else{
                return (
                    <>
                    <Button 
                        label="Conferir Lançamentos"
                        icon="pi pi-eye"
                        className="p-button-primary"
                        onClick={this.checkLaunch}
                        style={{maxHeight: '30px'}}
                    />
                    <Button 
                        label="Importar Inventário"
                        icon="pi pi-upload"
                        className="p-button-success"
                        onClick={() => this.setState({uploadLancamentoDialogVisible: true})}
                        style={{maxHeight: '30px', marginLeft: '10px'}}
                    />
                    </>
                )
            }
        }

        //Código filter
        const codeFilterElement = this.tableFilters.renderCodigoFilter(this.state.selectedCodes, this.productService.getCodeList, this.props.list,
            "selectedCodes", this.onFilterChange, this.state.codigoField)
        
        //Descrição Filter
        const descriptionFilterElement = this.tableFilters.renderDescriptionFilter(this.state.selectedDescriptions,
            this.productService.getDescriptionList, this.props.list,
            "selectedDescriptions", this.onFilterChange, this.state.descricaoField, '150px', 'Descrição')

        //NCM Filter
        const NCMFilterElement = this.tableFilters.renderNCMFilter(this.state.selectedNCM, this.productService.getNCMList,
            this.props.list, "selectedNCM", this.onFilterChange, this.state.NCMField)

        //NCM Filter
        const cfopFilterElement = this.tableFilters.renderCfopFilter(this.state.selectedCfops, this.productService.getCfopList,
            this.props.list, "selectedCfops", this.onFilterChange, this.state.cfopField)

        //Unidade Filter      
        const unitFilterElement = this.tableFilters.renderUnitFilter(this.state.selectedUnits, this.productService.getUnitList, this.props.list,
            "selectedUnits", this.onFilterChange, this.state.unitField)        

        return (
            <div className="datatable-crud-demo">
            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={this.dt}
                    // value={this.state.checkLaunch ? this.props.updatedProductsList : this.props.list}
                    value={this.getList()}
                    className="p-datatable-sm small-font"
                    rowHover
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({selectedProducts: e.value})}
                    scrollable
                    scrollHeight="500px"
                    // rowHover
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage={"Nenhum lançamento realizado"}
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} produtos"
                    >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '10px'}}></Column> */}
                    <Column field={this.state.codigoField} header="Código" sortable
                            style ={ {width: '60px'} }
                            filter={!this.state.checkLaunch} filterElement={codeFilterElement}
                            />
                    <Column field={this.state.descricaoField} header="Descrição" sortable
                        style ={ {width: '120px'} }
                        filter={!this.state.checkLaunch} filterElement={descriptionFilterElement}
                    />

                    <Column 
                        field="quantidade" header="Estoque"
                        sortable
                        style ={ {width: '60px'} }
                        editor={(options) => {
                            return GeneralServices.inputTableNumber(
                                options.rowData.id,
                                options.rowData.descricao,
                                options.rowData.quantidade,
                                options.rowData,
                                this.handleTableQunatidadeChange,
                                "Qunatidade"
                            )
                        }}
                    />

                    <Column 
                        field="aberturaInvetarioOutputInfoDTO.valorUnitario" header="Valor Unitário"
                        sortable
                        style ={ {width: '60px'} }
                        body={(rowData) => GeneralServices.showFormattedIfNotNull(rowData.aberturaInvetarioOutputInfoDTO.valorUnitario, true)}
                        editor={(options) => {
                            return GeneralServices.inputTableNumber(
                                options.rowData.id,
                                options.rowData.descricao,
                                options.rowData.aberturaInvetarioOutputInfoDTO.valorUnitario,
                                options.rowData,
                                this.handleTableValorUnitarioChange,
                                "Valor",
                                options.rowData.aberturaInvetarioOutputInfoDTO.hasAberturaInventarioOriginal
                            )
                        }}
                    />

                    <Column field="aberturaInvetarioOutputInfoDTO.dataHoraAtualizacaoManual" header="Última atualização manual de estoque"
                            sortable
                            style ={ {width: '120px'} }
                    />

                    <Column
                        header="Ações"
                        body={actionBody}
                        style ={ {width: '50px'} }
                    />

                </DataTable>
            </div>
                <ConfirmationDialog
                    header="Desfazer Alteração"
                    confimationMessage="Deseja desfazer a alteração de estoque?"
                    visible={this.state.displayUndoUpdateStockConfirmation}
                    confirm={this.confirmUndoUpdateStock}
                    hide={() => this.setState({displayUndoUpdateStockConfirmation: false})}
                />    

                <UploadDialog
                    visible={this.state.uploadLancamentoDialogVisible}
                    title="Importação de Lançamento de Inventário"
                    list = {this.props.list}
                    templateToExport={this.productsToExportTemplate}
                    nomeArquivoModelo="modelo_lancamento_inventario"
                    uploadDescription={ConstantsUtil.importacaoArquivoLancamentoInventario}
                    extraObject={`${this.props.dataLancamento} - ${this.props.updateStockHour}`}
                    hideDialog={() => this.setState({uploadLancamentoDialogVisible: false})}
                    onFinish={this.onFinishUpload}
                />            
        </div>
        )
    }


}  

export default InventoryTable