import React from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import CheckBoxComponent from './relatorios/checkBoxComponent';
import ConstantsUtil from '../context/constantsUtil';
import FraseService from '../app/service/fraseService';
import HandleErrorService from '../app/service/handleErrorService';
import TooltipButton from './tooltipButton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'jspdf-autotable';


class ConfirmationDialog  extends React.Component {

    constructor(){
        super()
        this.fraseService = new FraseService()
    }

    state = {
        confimationMessage: '',
        gravarFrase: false,
        loadingTable: false,
        frasesOptions: [],
        selectedFrase: '',
        selectedFraseDataTable: null,
        displayChooseFraseDialog: false,

        didUpdated: false,
    }


    componentDidUpdate(){
        this.resetState()
    }
    
    resetState = () => {
        // console.log("this.props.visible: ", this.props.visible)
        // console.log("didUpdated: ", this.state.didUpdated)
        if(this.props.visible && !this.state.didUpdated){
            // console.log("componentDidUpdate")
            this.setState({confimationMessage: ''})
            this.setState({gravarFrase: false})
            this.setState({selectedFraseDataTable: null})
            this.searchFrasesSalvas()
            this.setState({didUpdated: true})
        }
    }

    searchFrasesSalvas = () => {
        // console.log("searchFrasesSalvas")
        this.setState({loadingTable: true})
        this.setState({selectedFraseDataTable: null})
        this.fraseService.searchAllByTipoFrase(ConstantsUtil.tipoFraseMotivoExclusaoNF)
        .then(respose => {
            let frasesOptions = []
            let frasesSalvas = respose.data
            frasesSalvas.forEach(frase => {
                // frasesOptions.push({label: frase, value: frase})
                frasesOptions.push({texto: frase})
            });
            // console.log("frasesOptions: ", frasesOptions)
            this.setState({frasesOptions})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTable: false})
        })
    }

    handleSelectionFraseChange = async (event) => {
        // console.log("event: ", event)
        // console.log("value: ", value)
        if(event.value === null){ //significa que clicou em cima da Conta Bancária que estava selecionada antes (double click)
            this.hideFraseDialog()
        }
        else{
            const value = event.value.texto
            this.setState({selectedFraseDataTable: event.value})
            await this.setState({ selectedFrase: value })
            await this.setState({confimationMessage: value})
        }
    }

    confirm = () => {
        if(this.props.hasConfirmationMessage){
            if(this.props.isMotivoDeleteNF){
                this.props.confirm(this.state.confimationMessage, this.state.gravarFrase)
            }
            else{
                this.props.confirm(this.state.confimationMessage)
            }
        }
        else{
            this.props.confirm()
        }

        this.setState({didUpdated: false})
        
    }

    hide = async () => {
        this.props.hide()
        await this.setState({didUpdated: false})
    }

    hideFraseDialog = () => {
        this.setState({selectedFraseDataTable: null})
        this.setState({displayChooseFraseDialog: false})
    }

    deleteFrase = texto => {
        this.setState({loadingTable: true})
        this.fraseService.deleteByTextoAndTipoFrase(texto, ConstantsUtil.tipoFraseMotivoExclusaoNF)
        .then(response => {
            this.searchFrasesSalvas()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    render(){

        const renderConfirmationFooter = () => {
            return (
                <div>
                    <Button label="Confirmar" icon="pi pi-check"
                            onClick={this.confirm} autoFocus />
                    <Button label="Cancelar" icon="pi pi-times" onClick={this.hide}
                            className="p-button-text" />
                </div>
            );
        }

        const renderFrasesSalvasDialog = () => {
            return(
                <Dialog
                    header={"Selecione uma frase"}
                    visible={this.state.displayChooseFraseDialog}
                    modal
                    style={{ width: '80%' }}
                    onHide={this.hideFraseDialog}
                >

                <div >
                    {renderFrasesSalvasOptions()}
                </div>
    
            </Dialog>
            )
        }

        const actionBodyExcluirFrase = (frase) => {
            return (
                <React.Fragment>
                    <Button
                        title = "Excluir frase"
                        icon="pi pi-trash"
                        className={ "p-button-rounded p-mr-2 p-button-danger " }
                        onClick={() => this.deleteFrase(frase.texto)}
                        style={ {maxHeight: '25px', maxWidth: '25px'} }
                    />
                </React.Fragment>
            );
        }

        const renderFrasesSalvasOptions = () => {
            return (
                <div 
                    className='centraliza-conteudo-div'
                    style={{marginTop: '1rem'}}
                >                
                    <DataTable
                        value={this.state.frasesOptions}
                        className="p-datatable-sm small-font"
                        rowHover
                        showGridlines
                        dataKey="texto"
                        selection={this.state.selectedFraseDataTable}
                        onSelectionChange={this.handleSelectionFraseChange}
                        loading={this.state.loadingTable}
                        emptyMessage="Não há frases salvas"
                        scrollable
                        scrollHeight="500px"
                    >

                        <Column selectionMode="single" headerStyle={{ width: '3rem' }}
                        
                        />

                        <Column
                            header="Texto"
                            field="texto"
                        />

                    <Column
                        header="Excluir frase"
                        body={actionBodyExcluirFrase}
                        // style ={ {width: '140px'} }
                    />

                    </DataTable>
                </div>

            )
        }

        const renderConfirmationMessageInput = () => {
            if(this.props.hasConfirmationMessage){
                return (
                    <>
                    <div className='row'>
                    <div className='col-md-1'>
                    </div>
                    <div
                        className='col-md-2'
                        style={{marginTop: '1rem'}}
                    >
                        <TooltipButton
                            tooltip = "Selecionar frase salva"
                            icon="pi pi-eye"
                            style={ {maxHeight: '25px', maxWidth: '25px'} }
                            onClick={() => this.setState({displayChooseFraseDialog: true})} 
                        />                        
                    </div>
                    </div>
                    <div 
                        className='centraliza-conteudo-div'
                        style={{marginTop: '1rem'}}
                    >
                        <InputTextarea
                            value={this.state.confimationMessage}
                            onChange={(e) => this.setState({confimationMessage: e.target.value})}
                            rows={5} cols={35}
                            autoResize
                            placeholder={this.props.placeholderConfirmationMessage}
                        />
                    </div>
                    </>
                )
            }
        }

        const renderGravarMensagemCheckbox = () => {
            if(this.props.hasConfirmationMessage && this.props.isMotivoDeleteNF){
                return (
                    <div 
                        className='centraliza-conteudo-div'
                        style={{marginTop: '1rem'}}
                    >
                        <CheckBoxComponent
                            label={"Gravar frase para utilizar futuramente?"}
                            checked={this.state.gravarFrase}
                            handleCheckBoxChange={(checked) => this.setState({gravarFrase: checked})}
                        />
                    </div>
                )
            }
        }
        
        return (
            <Dialog
                header={this.props.header}
                visible={this.props.visible}
                modal
                style={{ width: '350px' }}
                footer={renderConfirmationFooter()}
                onHide={this.hide}
            >

                <div className="confirmation-content" >
                    <div className='row centraliza-conteudo-div'>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '10px'}} />
                    <div style={{marginBottom: '10px'}}> {this.props.confimationMessage} </div>
                    </div>
                    {renderFrasesSalvasDialog()}
                    {renderConfirmationMessageInput()}
                    {renderGravarMensagemCheckbox()}
                </div>
    
            </Dialog>            
        )

    }

}



export default ConfirmationDialog