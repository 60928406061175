import React from 'react'

import GeneralServices from '../../../app/service/generalServices'
import HandleErrorService from '../../../app/service/handleErrorService'

import * as popUp from '../../toastr'
import { InputNumber } from 'primereact/inputnumber'
import ConstantsUtil from '../../../context/constantsUtil'
import CustomCalendar from '../../calendar/customCalendar'
import TipoDePagamentoService from '../../../app/service/contasAPagar/tipoDePagamentoService'
import InputChooseTipoDePagamento from '../../contasAPagar/inputChooseTipoDePagamento'
import QuantidadeParcelasComponent from '../../contasAPagar/quantidadeParcelasComponent'
import ProvisaoService from '../../../app/service/contasAPagar/provisaoService'
import TooltipButton from '../../tooltipButton'


class LancamentoProvisaoComponent extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.tipoDePagamentoService = new TipoDePagamentoService();
        this.provisaoService = new ProvisaoService()

        this.dateInputRef = React.createRef()
        this.inputValorRef = React.createRef();
        this.historicoRef = React.createRef();
        this.complementoHistoricoRef = React.createRef();


        this.quantidadeParcelasComponentRef = React.createRef();


    }

    state = {
        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        tipoDePagamentoList: [],
        selectedTipoDePagamento: null,
        selectedTipoDePagamentoLabel: '',
        inputTipoDePagamentoErrorClass: '',
        loadingTipoDePagamentoTable: false,
        forceShowTipoDePagamentoDialog: false,
        forceTipoDePagamentoListInDialog: false,

        numeroDocumento: '',
        inputNumeroDocumentoErrorClass: '',

        complementoHistorico: '',
        inputComplementoHistoricoErrorClass: '',
        errorComplementoHistoricoMessage: '',

        valor: null,
        inputValorErrorClass: '',
        errorValorMessage: '',

        isSavingLancamentos: false,

    }

    componentDidMount(){
        if(this.props.provisao){
            this.preencheDadosProvisao()
        }
    }

    preencheDadosProvisao = () => {
        // console.log("preenche! ", this.props.provisao)
        let provisao = this.props.provisao
        
        this.setState({dataLancamento: provisao.dataLancamento})
        this.setState({dataLancamentoView: provisao.dataLancamentoView})
        this.setState({numeroDocumento: provisao.numeroDocumento})

        let historicoArray = provisao.historico.split(", ")
        if(historicoArray && historicoArray[1]){
            this.setState({complementoHistorico: historicoArray[1]})
        }

        if(this.props.provisao.tipoDePagamento){
            this.handleSelectionTipoDePagamentoChange(provisao.tipoDePagamento, provisao.tipoDePagamento.nome)
        }

        const event = {
            target: {
                name: 'valor'
            },
            value: provisao.valorTotal
        }
        this.handleValorChange(event)
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
    }

    handleDateChange = async (event) => {

        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
        


    }

    handleSelectionTipoDePagamentoChange = async (tipoDePagamento, label) => {
        await this.setState({selectedTipoDePagamento: tipoDePagamento})
        await this.setState({selectedTipoDePagamentoLabel: label})

        this.hideTipoDePagamentoDialog()

        // this.complementoHistoricoRef.current.focus()
    }

    handleValorChange = (event) => {
        // console.log("event: ", event)
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyPress = async e => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleValorChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
        if (e.key === "Enter" && !this.state.isSavingLancamentos) {
            this.callSave();
        }
    }

    resetInputs = () => {

        this.setState({dataLancamento: ''})
        this.setState({dataLancamentoView: ''})

        this.setState({selectedTipoDePagamento: ''})
        this.setState({selectedTipoDePagamentoLabel: ''})

        this.setState({numeroDocumento: ''})

        this.setState({complementoHistorico: ''})

        this.setState({valor: null})

        this.quantidadeParcelasComponentRef.current.reset()

    }

    resetView = () => {
        this.setState({inputDateErrorClass: ""})
        this.setState({errorDateMessage: ""})

        this.setState({inputTipoDePagamentoErrorClass: ''})

        this.setState({inputNumeroDocumentoErrorClass: ''})

        this.setState({inputValorErrorClass: ""})
        this.setState({errorValorMessage: ""})

    }

    checkData = () => {
        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        if(!this.state.selectedTipoDePagamento && ( !this.props.provisao || !this.props.provisao.notaFiscal )){
            this.setState({inputTipoDePagamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false;
        }

        // if(!this.state.numeroDocumento){
        //     this.setState({inputNumeroDocumentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
        //     check = false;
        // }

        if(!this.state.valor){
            this.setState({inputValorErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorValorMessage: "Informe o valor do lançamento"})
            check = false
        }

        let checkQuantidadeParcelasComponent = this.quantidadeParcelasComponentRef.current.checkData().checkGeral

        let finalCheck = check && checkQuantidadeParcelasComponent

        return finalCheck
    }


    callSave = () => {
        this.resetView()
        if(this.checkData()){
            if(this.props.insideEditDialog){
                this.edit()
            }
            else{
                this.save()
            }
        }
    }

    montaResultOject = () => {
        let resultObject = this.quantidadeParcelasComponentRef.current.getResultObject()
        resultObject.dataLancamento = this.state.dataLancamento
        resultObject.tipoDePagamento = this.state.selectedTipoDePagamento
        resultObject.notaFiscal = this.props.provisao ? this.props.provisao.notaFiscal : null
        resultObject.numeroDocumento = this.state.numeroDocumento ? this.state.numeroDocumento : ""
        resultObject.complementoHistorico = this.state.complementoHistorico

        return resultObject
    }

    save = () => {

        this.toogleSaveButton()

        let resultObject = this.montaResultOject()

        // console.log("resultObject: ", resultObject)

        this.provisaoService.save(resultObject)
        .then(response => {
            this.resetInputs()
            popUp.successPopUp("Provisão Lançada com Sucesso!")
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.toogleSaveButton()
        })
    }

    edit = () => {

        if(this.props.onCallEdit){
            this.props.onCallEdit()
        }
        
        let resultObject = this.montaResultOject()
        resultObject.id = this.props.provisao.id
        
        this.provisaoService.edit(resultObject)
        .then(response => {
            popUp.successPopUp("Provisão Alterada com Sucesso!")
            if(this.props.onSuccesEdit){
                this.props.onSuccesEdit()
            }
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            if(this.props.onErrorEdit){
                this.props.onErrorEdit()
            }
        })
    }

    toogleSaveButton(){
        if(this.props.toogleSaveButton){
            this.props.toogleSaveButton()
        }
    }

    hideTipoDePagamentoDialog = () => {
        this.setState({forceShowTipoDePagamentoDialog: false})
    }

    disableSaveButton = () => {
        return this.state.isSavingLancamentos
    }

    render() {
        
        const renderTipoDePagamentoInput = () => {
            if( this.props.isLancamento || (this.props.provisao && this.props.provisao.tipoDePagamento) ){

                return (
                    <>
                        <InputChooseTipoDePagamento
                            selectedTipoDePagamentoLabel={this.state.selectedTipoDePagamentoLabel}
                            forceShowDialog={this.state.forceShowTipoDePagamentoDialog}
                            hideDialog={this.hideTipoDePagamentoDialog}
                            tipoDePagamentoList={this.state.tipoDePagamentoList}
                            forceUpdate={this.state.forceTipoDePagamentoListInDialog}
                            doneForceUpdate={() => this.setState({forceTipoDePagamentoListInDialog: false})}
                            // push={this.props.history.push}
                            handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                            inputTipoDePagamentoErrorClass={this.state.inputTipoDePagamentoErrorClass}
                            provisoesAPagar
                        />
                    </>
                        
                )
            }
        }

        const renderNotaFiscalLabel = () => {
            if(this.props.provisao && this.props.provisao.notaFiscal){
                return (
                    <>
                        <div className="col-md-2">
                        <label htmlFor="date">N° Nota Fiscal</label>
                        <input
                            type="text"
                            className={"form-control " + this.state.inputNumeroDocumentoErrorClass}
                            value = {this.props.provisao.notaFiscal.numero}
                            disabled
                        />
                    </div>                        
                    </>
                )
            }
        }

        const renderComposicaoValorProvisaoInfo = () => {
            if(this.props.provisao && this.props.provisao.notaFiscal){
                // console.log("this.props.provisao.notaFiscal: ", this.props.provisao.notaFiscal)
                let notaFiscal = this.props.provisao.notaFiscal
                let valorNotaFiscalInfo = `Valor da Nota Fiscal: ${notaFiscal.valorTotal} (+)`

                let isAcrescimo = notaFiscal.valorAcrescimoDesconto > 0
                let valorAcrescimoDesconto = `Valor de ${isAcrescimo ? 'Acréscimo' : 'Desconto'}: ${Math.abs(notaFiscal.valorAcrescimoDesconto)} (${isAcrescimo ? '+' : '-'})`
                
                let valorBonificacao = `Valor da Bonificação: ${notaFiscal.valorDescontoPorBonificacao} (-)`
                let valorDevolucao = `Valor da Devolução: ${notaFiscal.valorDescontoPorDevolucao} (-)`

                let valorFinal = `Valor Final: ${GeneralServices.formatTo2CasasDecimais(notaFiscal.valorTotal + notaFiscal.valorAcrescimoDesconto - notaFiscal.valorDescontoPorBonificacao - notaFiscal.valorDescontoPorDevolucao)} (=)`
                
                let messageInfo = `Composição do valor final da Provisão:
                    ${valorNotaFiscalInfo}
                    ${valorAcrescimoDesconto}
                    ${valorBonificacao}
                    ${valorDevolucao}
                    ${valorFinal}
                `

                return (
                    <TooltipButton
                        tooltip={messageInfo}
                        tooltipOptions={{
                            className: 'custom-tooltip',
                            position: 'right'
                        }}
                        icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                        style ={{maxHeight: '25px', maxWidth: '25px', marginLeft: '0.5em', marginBottom: '0.3em'}}
                        className="p-button-rounded p-mr-2"
                    />  
                )
            }
        }

        return (
            <>

                <div className="row">
                
                <CustomCalendar
                    ref={this.dateInputRef}
                    id="dataLancamentoFechamentoCaixa"
                    htmlFor="icon"
                    label="Data do Lançamento"
                    inputDateErrorClass={this.state.inputDateErrorClass}
                    value={this.state.dataLancamentoView}
                    name="dataLancamento"
                    onChange={this.handleDateChange}
                    errorDateMessage={this.state.errorDateMessage}
                    disabled={this.props.provisao && this.props.provisao.notaFiscal}
                />

                {renderTipoDePagamentoInput()}

                {renderNotaFiscalLabel()}

                </div>

                    <br />

                    <div className="row">

                    <div className="col-md-2">
                        <label htmlFor="date">N° do Documento</label>
                        <input
                            type="text"
                            className={"form-control " + this.state.inputNumeroDocumentoErrorClass}
                            value = {this.state.numeroDocumento}
                            name="numeroDocumento"
                            onChange={this.handleChange}
                            id="nomeTipoDeRecebimento"
                            disabled={this.props.provisao && this.props.provisao.notaFiscal}
                        />
                        <div className="invalid-feedback">Preencha</div>
                    </div>

                    <div className="col-md-3">
                    <label htmlFor="icon">Complemento de Histórico (Opcional)</label>
                        <input
                            type="text"
                            className={"form-control "}
                            value = {this.state.complementoHistorico}
                            name="complementoHistorico"
                            onChange={this.handleChange}
                            disabled={this.props.disabled}
                        />
                        <div className="invalid-feedback">Preencha</div>                        
                    </div>


                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="valor">Valor Total</label>
                            {renderComposicaoValorProvisaoInfo()}
                            <InputNumber 
                                ref={this.inputValorRef}
                                className={this.state.inputValorErrorClass}
                                name="valor"
                                inputId="valorFechamentoBancario"
                                value={this.state.valor}
                                onValueChange={this.handleValorChange}
                                onKeyDown={this.handleKeyPress}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}
                                disabled={this.props.provisao && this.props.provisao.notaFiscal}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <small id="valorError" className="p-error">{this.state.errorValorMessage}</small>
                        </div>
                    </div>

                    <br />

                    <QuantidadeParcelasComponent
                        ref={this.quantidadeParcelasComponentRef}
                        valorTotalProvisao={this.state.valor}
                        provisao={this.props.provisao}
                        disabled={this.props.disabled}
                    />


            <div className="d-flex "/>

        </>
        )

    }

}

export default LancamentoProvisaoComponent
