import ConstantsUtil from "../../context/constantsUtil";
import WorkerApiService from "../workerApiServices";


class TiposDeRecebimentoService extends WorkerApiService {

    constructor() {
        super('/api/tipoDeRecebimento')

        this.dinheiroLabel = 'DINHEIRO'
        this.pixLabel = 'PIX'
        this.debitoLabel = 'DEBITO'
        this.creditoLabel = 'CREDITO'
        this.boletoLabel = 'BOLETO'
        this.automaticoLabel = 'AUTOMATICO'
        this.avulsoLabel = 'AVULSO'
        this.outrosLabel = 'OUTROS'
        this.estornoTarifaLabel = 'ESTORNO_TARIFA_BANCARIA'

        this.diaDoLancamentoLabel = 'dia do lançamento'
        this.diaDaSemanaLabel = 'dia da semana'
        this.diaDoMesLabel = 'dia(s) específico(s) do mês'
    
        this.diaDoLancamentoValue = 'DIA_DO_LANCAMENTO'
        this.diaDaSemanaValue = 'DIA_DA_SEMANA'
        this.diaDoMesValue = 'DIA_ESPECIFICO_DO_MES'
        
        this.diaDaSemanaList = [
            // Values escritos iguais ao enum do backend
            {label: 'Escolha o dia da semana', value: ''},
            {label: 'domingo', value: 'DOMINGO'},
            {label: 'segunda', value: 'SEGUNDA'},
            {label: 'terça', value: 'TERCA'},
            {label: 'quarta', value: 'QUARTA'},
            {label: 'quinta', value: 'QUINTA'},
            {label: 'sexta', value: 'SEXTA'},
            {label: 'sábado', value: 'SABADO'},
        ]

        this.tipoCorteList = [
    
            {label: 'Selecione...', value: ''},
            {label: this.diaDoLancamentoLabel, value: this.diaDoLancamentoValue},
            {label: this.diaDaSemanaLabel, value: this.diaDaSemanaValue},
            {label: this.diaDoMesLabel, value: this.diaDoMesValue},
        ]

        this.naturezaList = [
            {label: 'Selecione', value: ''},
            {label: 'Dinheiro', value: 'DINHEIRO'},
            {label: 'Pix', value: 'PIX'},
            {label: 'Débito', value: 'DEBITO'},
            {label: 'Crédito', value: 'CREDITO'},
            {label: 'Boleto', value: 'BOLETO'},
            {label: 'Estorno De Tarifa Bancária', value: 'ESTORNO_TARIFA_BANCARIA'},
            {label: 'Outros', value: 'OUTROS'}
        ]

    }

    sortSelectedDiasDoMesList = (selectedDiasDoMesList) => {
        if(!(selectedDiasDoMesList === null || selectedDiasDoMesList === undefined))
            selectedDiasDoMesList.sort((a,b) => a-b)
        return selectedDiasDoMesList
    }


    diasDoMesList = () => {
        var dayOfMonthlist=[]
        for(var i=1; i<=30; i++)
        dayOfMonthlist.push({label: i, value: i})
        return dayOfMonthlist;
    }

    cadastrarTipoDeRecebimento(tipoDeRecebimento){
        return this.post(`/save`, tipoDeRecebimento)
    }

    update(tipoDeRecebimento){
        return this.put(`/update`, tipoDeRecebimento)
    }

    reativar(tipoDeRecebimento){
        return this.post(`/reativar`, tipoDeRecebimento)
    }

    delete(tipoDeRecebimento){
        return this.post(`/delete`, tipoDeRecebimento)
    }

    search(excluiInativos){
        let params = `?`
        if(excluiInativos){
            params = `${params}&excluiInativos=${excluiInativos}`
        }
        return this.get(`/search${params}`)
    }

    searchByContaBancaria(contaBancariaId){
        let params = `?`
        params = `${params}&contaBancariaId=${contaBancariaId}`
        return this.get(`/searchByContaBancaria${params}`)
    }

    searchRendApAutoAsDreDto(){
        return this.get(`/searchRendApAutoAsDreDto`)
    }

    deleteMultipleTiposDeRecebimento(object){
        return this.post(`/deleteMultipleTiposDeRecebimento`, object)
    }


    adjustDiasDoMesVisualization(diasDoMes){
        var diasDoMesString = ""
        for(var i=0; i < diasDoMes.length; i++){
            if(i !== 0)
            diasDoMesString += ", "
            diasDoMesString += diasDoMes[i]
        }
        return diasDoMesString
    }

    filterByContaBancaria(originalTipoDeRecebimentoList, contaBancaria){
        //Mostra ao usuário apenas os tipos de recebimento associados à Conta Bancária selecionada.

        var filteredList

        if(contaBancaria.fundoFixo){
            filteredList =  originalTipoDeRecebimentoList.filter( tipoDeRecebimento => {
                return (
                    tipoDeRecebimento.nomeTipoDeRecebimento === this.avulsoLabel ||
                    tipoDeRecebimento.natureza === this.outrosLabel
                )
            } )
        }

        else{
            filteredList =  originalTipoDeRecebimentoList.filter( tipoDeRecebimento => {
                return (
                    tipoDeRecebimento.contaBancariaId === contaBancaria.id ||
                    tipoDeRecebimento.natureza === this.automaticoLabel ||
                    tipoDeRecebimento.natureza === this.outrosLabel
                )
            } )
        }

        return filteredList

    }

}

export default TiposDeRecebimentoService