import WorkerApiService from "../../workerApiServices"

class NFService extends WorkerApiService {

    constructor() {
        super('/api/NFs')
    }

    commomSearch(filtro){
        return this.post(`/commomSearch`, filtro)
    }

    searchByNumero(numero){
        let params = `?`
        params = `${params}&numero=${numero}`
        return this.get(`/searchByNumero${params}`)
    }

    getNFsPendentes(){
        return this.get(`/searchNFsPendentes`)
    }

    validateNF(NFToValidate){
        return this.post(`/validateNF`, NFToValidate)
    }

    deleteNF(NFToDelete, gravarFrase){
        let params = `?`
        params = `${params}&gravarFrase=${gravarFrase}`
        return this.post(`/deleteNF${params}`, NFToDelete)
    }

    revalidarNF(NFToReativar){
        return this.post("/revalidarNF", NFToReativar)
    }

    searchImportacaoNFsFalha(){
        return this.get("/searchImportacaoNFsFalha")
    }

    existeFalhaImportacaoNF(){
        return this.get("/existeFalhaImportacaoNF")
    }

    alteraCiencia(obj){
        return this.put("/alteraCiencia", obj)
    }
    
    lancamentoManualNF(notaFiscalDTO){
        return this.post("/lancamentoManualNF", notaFiscalDTO)
    }

    searchDadosDevolucao(notaFiscal){
        let params = `?`
        params = `${params}&idNotaFiscal=${notaFiscal.id}`
        return this.get(`/dadosDevolucao${params}`)
    }
    
    isBonificacaoTotal(notaFiscal){
        let params = `?`
        params = `${params}&idNotaFiscal=${notaFiscal.id}`
        return this.get(`/isBonificacaoTotal${params}`)
    }

    syncNFsVenda(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/syncNFsVenda${params}`)
    }

}

export default NFService