import { MultiSelect } from 'primereact/multiselect';
import React from 'react'
import ProductService from '../app/service/productService';
import GeneralServices from '../app/service/generalServices';
import ConstantsUtil from '../context/constantsUtil';

class TableFilters extends React.Component{
    
    constructor(){
        super();
        this.productService = new ProductService();
        this.generalServices = new GeneralServices()
    }

    getItemsListToTableFilter = (originalList, property) => {
        // console.log("getItemsListToTableFilter: ", originalList, property)
        var propertyList = []
        let list = JSON.parse(JSON.stringify(originalList))
        list = list.filter(item => this.filter(item, "nome"))
        list = list.filter(item => this.filter(item, "tipoLancamento"))
        list.forEach(item => {
            const propertyValue = this.getPropertyValue(item, property)
            // console.log()
            // console.log("item: ", item)
            // console.log("property: ", property)
            // console.log("propertyValue: ", propertyValue)
            // console.log()
            if(!propertyList.some(element => element.value === propertyValue)){
                var object = {label: propertyValue, value: propertyValue}
                propertyList.push(object)
            }
        })
        propertyList.sort( GeneralServices.compareGenericValues );
        let finalList =  propertyList.filter(item => this.filter(item, "label"))
        // if(property === 'nomeFornecedor') {
        //     console.log("finalList: ", finalList)
        // }
        return finalList
    }

    filter = (item, propertyToFilter) => {
        // console.log("item: ", item, propertyToFilter)
        return !ConstantsUtil.labelsToIgnoreInFilter.some(label => label === item[propertyToFilter])
    }

    getPropertyValue(item, nestedProperty){

        /*
            Este método tem o objetivo de conseguir pegar uma propriedade de um dado item (objeto).
            Retorna não apenas se a propriedade estiver a partir da raiz, mas pega também a propriedade aninhada (nested).

            Exemplo: Foi informado valor de nestedProperty como 'transaction.productInfo.descricao'.
            Então vai fazer item[transaction][productInfo][descricao].
        */
        
        const properties = nestedProperty.split('.');

        let currentObj = item;
    
        for (let i = 0; i < properties.length; i++) {
          const property = properties[i];
          currentObj = currentObj[property];
    
          // Verifique se a propriedade atual é definida no objeto
          if (!currentObj) {
            // if(currentObj == 0){
            //     console.log("valor 0")
            // }
            return '';
          }
        }

        return currentObj;
    }

    onFilterChange = (dataTableRef, event, property, setStateFunction) => {
        const name = event.target.name
        // console.log("onFilterChange property: ", property)
        // console.log("onFilterChange value: ", event.value)
        dataTableRef.current.filter(event.value, property, 'in');
        setStateFunction(name, event.value)
    }

    renderCodigoFilter = (selectedCodes, getCodeList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedCodes} options={getCodeList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '70px'}}
                        name={name}
                        filter
                        onChange={(event) => onFilterChange(event, state)} placeholder="Código"
                // optionLabel="name" optionValue="name"
                />
        );
    }

    renderDescriptionFilter = (selectedDescriptions, getDescriptionList, list, name, onFilterChange, state, maxWidth, placeholder) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedDescriptions} options={getDescriptionList(list)}
                        appendTo={document.body}
                        style={{maxWidth: maxWidth}}  
                        name={name}
                        filter
                        onChange={(event) => onFilterChange(event, state)} placeholder={placeholder}
                // optionLabel="name" optionValue="name"
                    />
        );
    }

    onShow = async () => {
        // console.log("onShow!")
        await this.generalServices.sleep(100)
        const filterInput = document.querySelector('.p-multiselect-filter');
        // console.log("filterInput: ", filterInput)
        if (filterInput) {
            filterInput.focus();
        }
    }    

    /*
        Filtro genérico. Funciona para qualquer tipo de dado.
        Futuramente, devo para de utilizar os filtros individuais antigos
    */
    renderTableFilter = (dataTableRef, selectedItems, list, name, setStateFunction, property, maxWidth, placeholder) => {
        // console.log("renderTableFilter")
        if(list) {
            return (
                <MultiSelect
                    className="p-column-filter small-font"
                    value={selectedItems}
                    options={this.getItemsListToTableFilter(list, property)}
                    appendTo={document.body}
                    style={{maxWidth: maxWidth}}
                    name={name}
                    filter
                    onChange={(event) => this.onFilterChange(dataTableRef, event, property, setStateFunction)}
                    placeholder={placeholder}
                    emptyFilterMessage="Nenhum Resultado Encontrado"
                    onShow={this.onShow}
                />
            );
        }
    }

    renderNCMFilter = (selectedNCM, getNCMList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedNCM} options={getNCMList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '85px'}}  
                        name={name}  
                        onChange={(event) => onFilterChange(event, state)} placeholder="NCM"
                // optionLabel="name" optionValue="name"
                    />
        );
    }

    renderCfopFilter = (selectedCfops, getCfopList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedCfops} options={getCfopList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '70px'}}  
                        name={name} 
                        onChange={(event) => onFilterChange(event, state)} placeholder="CFOP"
                // optionLabel="name" optionValue="name"
                    />
        );
    }

    renderNumeroFilter = (selectedNumbers, getNumberList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedNumbers} options={getNumberList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '60px'}}  
                        name={name} 
                        onChange={(event) => onFilterChange(event, state)} placeholder="Número"
                // optionLabel="name" optionValue="name"
                    />
        );
    }

    renderSerieFilter = (selectedSeries, getSerieList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedSeries} options={getSerieList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '40px'}}  
                        name={name} 
                        onChange={(event) => onFilterChange(event, state)} placeholder="Série"
                // optionLabel="name" optionValue="name"
                    />
        );
    }

    renderUnitFilter = (selectedUnits, getUnitList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedUnits} options={getUnitList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '70px'}}  
                        name={name} 
                        onChange={(event) => onFilterChange(event, state)} placeholder="Unidade"
                // optionLabel="name" optionValue="name"
                    />
        );
    }

    //Para tela de parametrização
    renderProviderNameFilter = (selectedProviderNames, getProviderNamesList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedProviderNames} options={getProviderNamesList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '100px'}}  
                        name={name}  
                        onChange={(event) => onFilterChange(event, state)} placeholder="Nome do Fornecedor"
                    />
        );
    }

    renderProviderCNPJFilterElement = (selectedProviderNames, getProviderCNPJList, list, name, onFilterChange, state) => {
        return (
            <MultiSelect className="p-column-filter small-font" value={selectedProviderNames} options={getProviderCNPJList(list)}
                        appendTo={document.body}
                        style={{maxWidth: '100px'}}  
                        name={name}  
                        onChange={(event) => onFilterChange(event, state)} placeholder="CNPJ do Fornecedor"
                    />
        );
    }

}

export default TableFilters