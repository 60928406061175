import React from 'react'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import DadosNFComponent from '../../components/nf/dadosNFComponent';
import { Button } from 'primereact/button';
import Card from '../../components/card';
import NFService from '../../app/service/estoque/NFService';
import * as popUp from '../../components/toastr';
import HandleErrorService from '../../app/service/handleErrorService';
import GeneralServices from '../../app/service/generalServices';

class LancamentoManualNF extends React.Component {

    constructor(){
        super();
        this.nfService = new NFService()
        this.dadosNFComponentRef = React.createRef()
        this.generalServices = new GeneralServices()
    }

    state = {
        isSaving: false,
    }

    checkData = () => {
        return this.dadosNFComponentRef.current.checkData()
    }

    callSave = () => {
        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        this.setState({isSaving: true})
        let notaFiscalDTO = this.dadosNFComponentRef.current.montaNF()
        // console.log("notaFiscalDTO: ", notaFiscalDTO)
        this.nfService.lancamentoManualNF(notaFiscalDTO)
        .then(async response => {
            popUp.successPopUp("Lançamento salvo com sucesso!")
            await this.generalServices.sleep(1000)
            window.location.reload()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({isSaving: false})
        })
    }

    render() {

        return (
                
            <>
                <div className="bs-docs-section" >
                
                <Card title = "Lançamento Manual de Nota Fiscal">
                    <DadosNFComponent
                        ref={this.dadosNFComponentRef}
                    />

                    <br />

                    <div className='col-md-6'>
                        <Button
                            label="Salvar Lançamento"
                            icon="pi pi-save"
                            onClick = {this.callSave}
                            // style={ {maxHeight: '35px' } }
                            disabled = {this.state.isSaving}
                        />
                    </div>

                </Card>

                <div className="d-flex "/>
                
            </div>            
            </>
              
        )
    }


}

LancamentoManualNF.contextType = AuthContext

export default withRouter(LancamentoManualNF)