import React from 'react'
import ConstantsUtil from '../../context/constantsUtil'
import SessionStorageService from './sessionStorageService'

export const userLoggedIn = 'userLoggedIn'
export const provisionalApiContextPath = 'provisionalApiContextPath'
export const apiContextPath = 'apiContextPath'
export const perfilLabel = 'perfil'
export const isAuth = 'isAuth'
export const isAdmin = 'isAdmin'
export const email = 'email'
export const lastCheckDate = 'lastCheckDate'
const Service = SessionStorageService

class AuthService extends React.Component {

    // static isUserLoggedIn(){
    //     const user = Service.getItem(userLoggedIn)
    //     return user && user.id
    // }

    static isAuth(){
        const user = this.getUserLoggedIn()
        if(!user){
            return false
        }
        return user[isAuth];
    }

    static getPerfil(){
        const user = this.getUserLoggedIn()
        if(!user){
            return {}
        }
        return {
            isAdmin: user.perfil === ConstantsUtil.perfilAdministradorLabel,
            isOperador: user.perfil === ConstantsUtil.perfilOperadorLabel,
            isDiretor: user.perfil === ConstantsUtil.perfilDiretorLabel,
        };
    }

    static getUserLoggedIn(){
        return Service.getItem(userLoggedIn)
    }

    // static token(){
    //     return Service.getItem(userToken)
    // }

    static getProvisionalApiContextPath(){
        return Service.getItem(provisionalApiContextPath)
    }

    static addProvisionalApiContextPath(path){
        return Service.addItem(provisionalApiContextPath, path)
    }

    static getCurrentContextPath(){
        const user = this.getUserLoggedIn()
        var currentApiContextPath = user[apiContextPath]
        if(!currentApiContextPath){
            currentApiContextPath = this.getProvisionalApiContextPath()
        }
        return currentApiContextPath
    }

    static getAcessToken(){
        const user = this.getUserLoggedIn()
        return user.accessToken
    }

    static changeCurrentContextPath(path){
        var user = this.getUserLoggedIn()
        user[apiContextPath] = path
        Service.addItem(userLoggedIn, user)
    }

    static login(user) {
        Service.addItem(userLoggedIn, {
            [apiContextPath]: user.apiContextPath,
            [isAuth]: true,
            [email]: user.email,
            [perfilLabel]: user.perfil,
            [ConstantsUtil.accessToken]: user.accessToken,
            [lastCheckDate]: Date.now()
        })
        Service.removeItem(provisionalApiContextPath)
    }

    static logOut(){
        Service.removeItem(userLoggedIn)
    }

    static getCheckDate(){
        return Service.getItem(lastCheckDate)
    }

}

export default AuthService